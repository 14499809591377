import { useQuery } from '@apollo/client';
import SimulationVideoBarBasic from '@base/components/SimulationVideoBarBasic';
import {
  READ_HEATMAP_DATA,
  READ_SIM_AND_VIDEOS,
} from './SimulationLogHeatmaps.query';
import VideoHeatmap from '@core/components/Analytics/components/SimulationHeatmap/VideoWithHeatmap/VideoWithHeatmap';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { GetCurrentVideoFromSimulation } from '@base/utility/SimulationHelpers';
import { Icon, Loading } from '@virtidev/toolbox';
import { flatMap } from 'lodash';
import {
  NoHeatmapDataContent,
  NoHeatmapDataHeader,
  NoHeatmapDataIconWrapper,
  NoHeatmapDataTextContentWrapper,
  NoHeatmapDataWrapper,
  StyledHeatmapArea,
  StyledHeatmapSectionWrapper,
} from '@core/components/Analytics/components/SimulationHeatmap/SimulationHeatmap.styled';

/**
 * @type {FC<{simulationID: string; submissionID: string; }>}
 */
const SimulationLogHeatmaps = ({ simulationID, submissionID }) => {
  const { data: simData, loading: simLoading } = useQuery(READ_SIM_AND_VIDEOS, {
    variables: {
      SimulationID: simulationID,
    },
  });
  const [currentVideoID, setCurrentVideoID] = useState(null);

  const currentVideo = useMemo(() => {
    if (simData?.readOneSimulation) {
      return GetCurrentVideoFromSimulation(
        simData?.readOneSimulation,
        currentVideoID ?? simData?.readOneSimulation?.StartVideoID
      );
    }
    return null;
  }, [simData?.readOneSimulation, currentVideoID]);

  const { data: heatmapQueryData, loading: heatmapLoading } = useQuery(
    READ_HEATMAP_DATA,
    {
      variables: {
        filter: {
          HeatmapData: { ne: null },
          AnalyticsSubmissionID: { eq: submissionID },
          VideoID: {
            eq: currentVideo?.ID,
          },
        },
      },
      skip: !currentVideo,
    }
  );

  const videos360 = useMemo(() => {
    return simData?.readOneSimulation?.Videos.nodes.filter((video) => {
      return video.Content360 === true;
    });
  }, [simData?.readOneSimulation?.Videos]);

  // ensure we set the current video to either based on StartVideoID or the first in the list (ensuring a 360 video)
  useEffect(() => {
    if (!simData?.readOneSimulation) {
      return;
    }
    let startVideo = videos360.find(
      (vid) => vid.ID === simData?.readOneSimulation.StartVideoID
    );
    if (!startVideo) {
      startVideo = videos360[0];
    }
    setCurrentVideoID(startVideo.ID);
  }, [simData?.readOneSimulation, videos360]);

  const handleVideoClick = useCallback((videoID) => {
    setCurrentVideoID(videoID);
  }, []);

  const heatmapData = useMemo(() => {
    const mappedData = flatMap(
      heatmapQueryData?.readAnalyticsEvents.edges.filter(
        (edge) => edge.node && !!edge.node.HeatmapData
      ),
      (eventEdge) => {
        return JSON.parse(eventEdge.node.HeatmapData);
      }
    );
    return mappedData;
  }, [heatmapQueryData]);

  return (
    <StyledHeatmapSectionWrapper>
      {simLoading && <Loading />}
      {!simLoading && currentVideo?.ID && (
        <SimulationVideoBarBasic
          videos360={videos360}
          startVideoID={simData?.readOneSimulation?.StartVideoID}
          selectedVideoID={currentVideo?.ID}
          simulationID={simData?.readOneSimulation?.ID}
          onClick={handleVideoClick}
        />
      )}
      <StyledHeatmapArea>
        {!heatmapLoading &&
          currentVideo &&
          heatmapData &&
          heatmapData.length > 0 && (
            <div>
              <VideoHeatmap
                simulationData={simData?.readOneSimulation}
                currentVideo={currentVideo}
                heatmapData={heatmapData}
                loadingHeatmapData={heatmapLoading}
              />
            </div>
          )}
        {!heatmapLoading &&
          (!currentVideo || !heatmapData || heatmapData.length === 0) && (
            <NoHeatmapDataWrapper>
              <NoHeatmapDataIconWrapper>
                <Icon icon="heatmap" color="turquoise" size="100px" />
              </NoHeatmapDataIconWrapper>
              <NoHeatmapDataTextContentWrapper>
                <NoHeatmapDataHeader>No heatmap data</NoHeatmapDataHeader>
                <NoHeatmapDataContent>
                  This media must first be viewed in VR to analyze heatmap data.
                </NoHeatmapDataContent>
              </NoHeatmapDataTextContentWrapper>
            </NoHeatmapDataWrapper>
          )}
      </StyledHeatmapArea>
    </StyledHeatmapSectionWrapper>
  );
};

export default SimulationLogHeatmaps;
