import React from 'react';

const ClockIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="clock-icon-title"
      role="img"
    >
      <title id="clock-icon-title">Clock Icon</title>
      <circle cx="15.3714" cy="14.999" r="12.7845" fill="white" />
      <rect
        x="0.9375"
        y="9.49054"
        width="1.81174"
        height="11.0168"
        fill="#528FBF"
      />
      <rect
        x="2.75024"
        y="6.44012"
        width="1.81174"
        height="3.05044"
        fill="#528FBF"
      />
      <rect
        x="4.56152"
        y="6.44012"
        width="1.81174"
        height="3.05044"
        fill="#97C5FE"
      />
      <rect
        x="2.75024"
        y="9.49054"
        width="1.81174"
        height="11.0168"
        fill="#97C5FE"
      />
      <rect
        x="25.4385"
        y="9.49054"
        width="1.81174"
        height="11.0168"
        fill="#97C5FE"
      />
      <rect
        width="1.81174"
        height="3.05044"
        transform="matrix(1 0 0 -1 4.56152 23.5579)"
        fill="#97C5FE"
      />
      <rect
        x="23.6279"
        y="6.44006"
        width="1.81174"
        height="3.05044"
        fill="#97C5FE"
      />
      <rect
        width="1.81174"
        height="3.05044"
        transform="matrix(1 0 0 -1 23.6279 23.5579)"
        fill="#97C5FE"
      />
      <rect
        x="4.56152"
        y="4.59454"
        width="1.81174"
        height="1.84564"
        fill="#528FBF"
      />
      <rect
        x="6.37207"
        y="2.74887"
        width="3.11938"
        height="1.84564"
        fill="#528FBF"
      />
      <rect
        x="6.37207"
        y="4.59454"
        width="3.11938"
        height="1.84564"
        fill="#97C5FE"
      />
      <rect
        x="14.4651"
        y="5.23569"
        width="1.81174"
        height="10.3877"
        fill="#97C5FE"
      />
      <rect
        x="8.88623"
        y="13.7777"
        width="6.01286"
        height="1.84564"
        fill="#97C5FE"
      />
      <rect
        width="3.11938"
        height="1.84564"
        transform="matrix(1 0 0 -1 6.37207 25.4034)"
        fill="#97C5FE"
      />
      <rect
        x="9.49219"
        y="2.7832"
        width="11.0152"
        height="1.84564"
        fill="#97C5FE"
      />
      <rect
        width="11.0152"
        height="1.84564"
        transform="matrix(1 0 0 -1 9.49219 27.215)"
        fill="#97C5FE"
      />
      <rect
        x="20.5063"
        y="4.59457"
        width="3.11938"
        height="1.84564"
        fill="#97C5FE"
      />
      <rect
        width="3.11938"
        height="1.84564"
        transform="matrix(1 0 0 -1 20.5063 25.4034)"
        fill="#97C5FE"
      />
      <rect
        x="9.49219"
        y="0.9375"
        width="11.0152"
        height="1.84564"
        fill="#528FBF"
      />
      <rect
        width="1.81174"
        height="3.05044"
        transform="matrix(1 0 0 -1 2.75049 23.5579)"
        fill="#528FBF"
      />
      <rect
        width="1.81174"
        height="1.84564"
        transform="matrix(1 0 0 -1 4.56323 25.4034)"
        fill="#528FBF"
      />
      <rect
        width="3.11938"
        height="1.84564"
        transform="matrix(1 0 0 -1 6.37378 27.2491)"
        fill="#528FBF"
      />
      <rect
        x="29.0601"
        y="20.5074"
        width="1.81174"
        height="11.0168"
        transform="rotate(-180 29.0601 20.5074)"
        fill="#528FBF"
      />
      <rect
        x="27.2473"
        y="23.5579"
        width="1.81174"
        height="3.05044"
        transform="rotate(-180 27.2473 23.5579)"
        fill="#528FBF"
      />
      <rect
        x="25.4368"
        y="25.4035"
        width="1.81174"
        height="1.84564"
        transform="rotate(-180 25.4368 25.4035)"
        fill="#528FBF"
      />
      <rect
        x="23.6255"
        y="27.2491"
        width="3.11938"
        height="1.84564"
        transform="rotate(-180 23.6255 27.2491)"
        fill="#528FBF"
      />
      <rect
        x="20.5054"
        y="29.0605"
        width="11.0152"
        height="1.84564"
        transform="rotate(-180 20.5054 29.0605)"
        fill="#528FBF"
      />
      <rect
        width="1.81174"
        height="3.05044"
        transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 27.2478 6.44012)"
        fill="#528FBF"
      />
      <rect
        width="1.81174"
        height="1.84564"
        transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 25.4351 4.59451)"
        fill="#528FBF"
      />
      <rect
        width="3.11938"
        height="1.84564"
        transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 23.6238 2.74887)"
        fill="#528FBF"
      />
    </svg>
  );
};

export default ClockIcon;
