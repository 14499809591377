import _ from 'lodash';
import { matchPath } from 'react-router-dom';

// we use this check to e.g. to switch which token is being used
// since (as of now) we can rely on no navigation back to the main app from here
export const isPublicVHPlayerPage = (location) => {
  // public virtual human path
  if (!!matchPath(location.pathname, '/my-virtual-humans/embed/:vhId')) {
    return true;
  }

  // embed root section
  if (!!matchPath(location.pathname, '/embed/my-virtual-humans')) {
    return true;
  }

  const query = new URLSearchParams(location.search);
  if (query.get('publicPlayer') === 'true') {
    return true;
  }

  return false;
};

export const getVirtualHumanAPI = () => {
  if (isPublicVHPlayerPage(document.location)) {
    return _.get(
      window.localStorage,
      'publicVirtualHumanAPI',
      'https://robotnik.virti.com'
    );
  }
  return _.get(window.localStorage, 'virtualHumanAPI');
};
