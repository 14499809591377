import { HelpContent } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const HelpLegend = styled(HelpContent)`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1rem;
`;

export const DeletedUserText = styled.div`
  display: inline-block;
  color: #aaa;
`;

export const StyledSimLog = styled.div`
  padding: 0 1.8rem;
  text-align: center;
  margin-left: 350px;
  @media (max-width: 1100px) {
    margin-left: 0;
  }
`;

export const StyledInfoColumn = styled.div`
  border-right: 1px solid var(--primary-border-color);

  text-align: left;
  padding: 2rem 0rem;
  position: fixed;
  width: 350px;
  margin-left: -350px;
  @media (max-width: 1100px) {
    margin-left: 0;
    width: auto;
    position: static;
    border-right: 0px;
    border-bottom: 1px solid var(--primary-border-color);
  }
`;

export const StyledHeatmapButtonWrapper = styled.div`
  margin-top: 1.5rem;
`;
export const StyledInfoDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding-top: 2rem;
  h2 {
    font-size: 0.8rem;
    color: var(--primary-font-color-light);
    margin: 0.8rem 0rem;
  }
  h3 {
    font-size: 0.8rem;
    color: var(--primary-font-color-light);
    height: 2rem;
  }
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2.5rem;
  padding-right: 2rem;

  @media (max-width: 1100px) {
    padding-right: 0;
  }
`;

export const StyledUserName = styled.h1`
  font-size: 1.5rem;
  color: var(--primary-font-color);
`;
export const StyledLogColumn = styled.div`
  display: grid;
  place-items: start center;
  padding-top: 2rem;
`;

export const StyledLogEventAndTime = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  place-items: center;
`;
export const StyledTimeStamp = styled.div`
  width: 5rem;
  color: var(--primary-font-color-light);
`;

export const StyledScore = styled.div`
  ${({ theme, alignAsAnswer }) => css`
    width: 20rem;
    color: #fff;
    font-size: 1.2rem;
    display: grid;
    place-items: center;
    margin: 1rem 5rem 1rem 0rem;
    padding: 1.5rem 0rem 1.5rem 0rem;
    text-align: left;
    background: ${theme.color.primary};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    opacity: 1;
    margin: ${alignAsAnswer ? '1rem 12rem 1rem 0rem' : '1rem 2rem 1rem 10rem'};

    @media (max-width: 1250px) {
      width: 15rem;
      margin: ${alignAsAnswer ? '1rem 6rem 1rem 0rem' : '1rem 2rem 1rem 5rem'};
    }
  `}
`;
