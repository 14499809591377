import { createAsyncThunk } from '@reduxjs/toolkit';
import { objectivesSelector } from '../PatientSlice';
import { sendPostRequest } from '../../services/PDService';
import { LocalStorageGet } from '../../utility/LocalStorageUtil';
import { delay } from '../../utility/Promises';
import { hitObjectiveAnalyticsEvent } from '../SilverstripeAPISlice';

export const sendChat = createAsyncThunk(
  'chat/sendChat',
  async (formData, { getState, dispatch }) => {
    const chatSessionId = getState().chat.chatSessionId;
    formData = {
      ...formData,
      userId: getState().user.ID,
      username: getState().user.Name,
      organisationID: LocalStorageGet('currentOrganisationID', '-1'),
      voice: getState().virtualHuman?.data?.Voice,
      logVersion: 2,
      platform: 'web',
      easyMode: getState().virtualHuman?.data?.EasyMode,
      isPreviewPlayer: getState().chat?.isPreviewPlayer ?? false,
    };

    try {
      const response = await sendPostRequest(
        'text-to-speech',
        formData,
        chatSessionId,
        false,
        getState().virtualHuman.data.ShareToken
      );
      response.data.response.voice = getState().virtualHuman.data.VHVoice;
      let objective;
      if (response.data?.response?.conversationItem) {
        const objectives = objectivesSelector(getState());
        objective = objectives.find(
          (obj) =>
            obj.conversationItemId ===
            response.data.response.conversationItem.id
        );
      }
      if (objective?.id) {
        dispatch(hitObjectiveAnalyticsEvent(objective.id));
      }
      return {
        data: response.data,
        objectiveId: objective?.id,
        scoreMetadataWithCalculated:
          response.data?.response?.scoreMetadataWithCalculated ?? null,
        error: !response?.data
          ? 'Failed request.'
          : response?.data?.error ?? undefined,
        resendFormData: formData,
      };
    } catch (e) {
      console.log(e);
      return { error: e, resendFormData: formData };
    }
  }
);

export const skipGreeting = createAsyncThunk(
  'chat/skipGreeting',
  async (payload, { getState, dispatch }) => {
    return true;
  }
);

export const fetchGreeting = createAsyncThunk(
  'chat/fetchGreeting',
  async (payload, { getState, dispatch }) => {
    const formData = {
      query: '__INITIAL__',
      patientId: payload,
      platform: 'web',
      userId: getState().user.ID,
      username: getState().user.Name,
      voice: getState().virtualHuman?.data?.Voice,
      easyMode: getState().virtualHuman?.data?.EasyMode,
      organisationID: LocalStorageGet('currentOrganisationID', '-1'),
      isPreviewPlayer: getState().chat?.isPreviewPlayer ?? false,
      logVersion: 2,
    };
    try {
      const response = await sendPostRequest(
        'text-to-speech',
        formData,
        null,
        false,
        getState().virtualHuman.data.ShareToken
      );
      response.data.response.voice = getState().virtualHuman.data.VHVoice;
      return response.data;
    } catch (e) {
      console.log(e);
      return { error: e, resendGreeting: true };
    }
  }
);

// generic alternative (currently) to diagnosis. To later hook up to completion actions
export const completeSession = createAsyncThunk(
  'chat/completeSession',
  async (payload, { getState, dispatch }) => {
    const chatSessionId = getState().chat.chatSessionId;
    const timeSpent = new Date().getTime() - getState().chat.startTime;
    const response = await sendPostRequest(
      'complete-session',
      {
        ...payload,
        timeSpent: timeSpent,
        completedObjectives: JSON.stringify(
          getState().chat.completedObjectives
        ),
        completedExamObjectives: JSON.stringify(
          getState().chat.completedExamObjectives
        ),
      },
      chatSessionId,
      false,
      getState().virtualHuman.data.ShareToken
    );
    return {
      conversationScore: response.data.PDConversationScore,
      totalPointsPossible: response.data.PDTotalPointsPossible,
      hitObjectivesData: response.data.hitObjectivesData,
      // objectives: response.data.objectives,
      actionObjectivesGroupedByHeadings:
        response.data.actionObjectivesGroupedByHeadings,
      timeSpent,
    };
  }
);

export const endSession = createAsyncThunk(
  'chat/endSession',
  async (payload, { getState, dispatch }) => {
    const response = await sendPostRequest(
      'end-session',
      null,
      getState().chat.chatSessionId,
      false,
      getState().virtualHuman.data.ShareToken
    );
    return response.data;
  }
);

export const addResult = createAsyncThunk(
  'chat/addResult',
  async (action, { getState, dispatch }) => {
    const chatSessionId = getState().chat.chatSessionId;

    // send off a log to back-end
    const formData = {
      chatSessionId,
      actionIdV2: action.id,
      type: 'action',
    };
    const response = await sendPostRequest(
      'assessments/log',
      formData,
      chatSessionId,
      false,
      getState().virtualHuman.data.ShareToken
    );
    if (response?.data?.objectiveId) {
      dispatch(hitObjectiveAnalyticsEvent(response.data.objectiveId));
    }
    return {
      result: action,
      objectiveId: response?.data?.objectiveId,
      scoreMetadataWithCalculated: response?.data?.scoreMetadataWithCalculated,
    };
  }
);

// create thunk to provide condition data from diagnosis reducer
export const promptDiagnosis = createAsyncThunk(
  'chat/promptDiagnosis',
  async (payload, { getState, dispatch }) => {
    const prompt = getState().diagnosis.condition.diagnosisPrompt;
    return prompt;
  }
);

export const setPendingInvestigation = createAsyncThunk(
  'chat/setPendingInvestigation',
  async (payload, { getState, dispatch }) => {
    await delay(5000);
    await dispatch(addResult(payload));
    return payload;
  }
);

export const fetchBranchingGreeting = createAsyncThunk(
  'chat/fetchBranchingGreeting',
  async (vhId, { getState, dispatch }) => {
    const formData = {
      vhId,
      platform: 'web',
      userId: getState().user.ID,
      username: getState().user.Name,
      voice: getState().virtualHuman?.data?.Voice,
      organisationID: LocalStorageGet('currentOrganisationID', '-1'),
      isPreviewPlayer: getState().chat?.isPreviewPlayer ?? false,
      logVersion: 2,
      easyMode: getState().virtualHuman?.data?.EasyMode,
    };
    const response = await sendPostRequest(
      'branching-conversation-sessions/start',
      formData,
      null,
      false,
      getState().virtualHuman.data.ShareToken
    );
    return response.data;
  }
);

export const sendBranchingOption = createAsyncThunk(
  'chat/sendBranchingOption',
  async (argData, { getState, dispatch, rejectWithValue }) => {
    const { branchingOption, vhId } = argData;
    const chatSessionId = getState().chat.chatSessionId;
    const formData = {
      branchingOptionId: branchingOption.id,
      userId: getState().user.ID,
      username: getState().user.Name,
      organisationID: LocalStorageGet('currentOrganisationID', '-1'),
      voice: getState().virtualHuman?.data?.Voice,
      vhId,
      logVersion: 2,
    };
    let response;
    let failed = false;
    try {
      response = await sendPostRequest(
        `branching-conversation-sessions/chat`,
        formData,
        chatSessionId,
        false,
        getState().virtualHuman.data.ShareToken
      );
    } catch (e) {
      failed = true;
    }
    let objective;
    const data = response?.data?.data;
    if (data?.objectiveIdHit > 0) {
      dispatch(hitObjectiveAnalyticsEvent(data.objectiveIdHit));
    }

    if (!failed && response?.data) {
      return { data: response.data, branchingOptionText: branchingOption.text };
    }
    return rejectWithValue({ resendFormData: argData });
  }
);

export const sendBranchingAIText = createAsyncThunk(
  'chat/sendAIChat',
  async (formData, { getState, rejectWithValue }) => {
    const chatSessionId = getState().chat.chatSessionId;
    const { text, vhId } = formData;
    const sendFormData = {
      userId: getState().user.ID,
      username: getState().user.Name,
      organisationID: LocalStorageGet('currentOrganisationID', '-1'),
      vhId,
      voice: getState().virtualHuman?.data?.Voice,
      text,
      aiNodeId: getState().chat?.prevNode.id,
      logVersion: 2,
    };
    let response;
    let failed = false;
    try {
      response = await sendPostRequest(
        `branching-conversation-sessions/chat-ai`,
        sendFormData,
        chatSessionId,
        false,
        getState().virtualHuman.data.ShareToken
      );
    } catch (e) {
      failed = true;
    }
    if (!failed && response?.data) {
      return { data: response.data, text };
    }
    return rejectWithValue({ resendBranchingAITextData: formData });
  }
);

export const sendBranchingFreeText = createAsyncThunk(
  'chat/sendFreeText',
  async (formData, { getState, rejectWithValue }) => {
    const chatSessionId = getState().chat.chatSessionId;
    const { freeText, vhId } = formData;
    const sendFormData = {
      userId: getState().user.ID,
      username: getState().user.Name,
      organisationID: LocalStorageGet('currentOrganisationID', '-1'),
      vhId,
      voice: getState().virtualHuman?.data?.Voice,
      freeText,
      freeTextNodeId: getState().chat?.prevNode.id,
      logVersion: 2,
    };
    let response;
    let failed = false;
    try {
      response = await sendPostRequest(
        `branching-conversation-sessions/chat-freetext`,
        sendFormData,
        chatSessionId,
        false,
        getState().virtualHuman.data.ShareToken
      );
    } catch (e) {
      failed = true;
    }
    if (!failed && response?.data) {
      return { data: response.data, freeText };
    }
    return rejectWithValue({ resendFreeTextInputData: formData });
  }
);
