import styled from 'styled-components';

export const SessionContainer = styled.div`
  max-width: 250px;
  min-width: 100px;
  flex: 1;
`;

export const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
  padding: 1.25rem 2rem 0 2rem;
`;

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const RightSideFilter = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;