import React, { Component, useState, useEffect } from 'react';
import { LogoutUser } from '../utility/LoginUtility';
import { Redirect } from 'react-router-dom';
import { FindGetParameter } from '../utility/URLHelpers';
import { ApolloConsumer, useMutation } from '@apollo/client';
import { LOGOUT_DEVICE } from '../queries/UserQueries';

const LogoutPage = (props) => {
  const [completedLogout, setCompletedLogout] = useState(false);

  const logoutUserWithCallback = () => {
    LogoutUser(() => {
      setCompletedLogout(true);
    });
  };

  const [logout, { loading }] = useMutation(LOGOUT_DEVICE, {
    onCompleted: logoutUserWithCallback,
    // It's not critical to destroy the session on the server, but we do need to destroy it on the client.
    onError: logoutUserWithCallback,
  });

  useEffect(() => {
    // Logout on mount
    logout();
  }, [logout]);

  if (completedLogout) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { errorMessage: FindGetParameter('msg') },
        }}
      />
    );
  }

  return (
    <ApolloConsumer>
      {(client) => {
        (async () => {
          try {
            // stop pending requests
            client.stop();
            // reset the store in apollo
            await client.clearStore();
          } catch (e) {}
        })();
        return null;
      }}
    </ApolloConsumer>
  );
};

export default LogoutPage;
