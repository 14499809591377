import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import h337 from 'heatmap.js';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import { usePrevious } from '@base/utility/CustomHooks';

const StyledHeatmapWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
`;

const getProcessedData = ({ canvasHeight, canvasWidth, data }) => {
  const processed = data.reduce((carry, dataObj) => {
    carry.push({
      x: Math.round(dataObj.uvx * canvasWidth),
      y: Math.round(canvasHeight - dataObj.uvy * canvasHeight),
      sec: dataObj.sec,
      // adds space around canvas to be able to theoretically offset material without it being cut off:
      // x: Math.round((dataObj.uvx * (canvasWidth / 2)) + (canvasWidth / 4)),
      // y: Math.round((dataObj.uvy * (canvasHeight / 2)) + (canvasHeight / 4)),
      value: dataObj.t,
    });
    return carry;
  }, []);
  return processed;
};

/**
 * @type {FC<{}>}
 */
const Heatmap = ({
  data,
  id,
  canvasWidth,
  canvasHeight,
  currentTime,
  opacity,
}) => {
  const heatmapCanvasRef = useRef(
    /** @type {HTMLCanvasElement | null} */ (null)
  );
  const canvasContainerRef = useRef(null);
  const heatmapInstance = useRef(null);

  // everything should re-render when this updates to a different second
  const currentSecond = useMemo(() => {
    return Math.floor(currentTime / 1000) ?? 0;
  }, [currentTime]);

  const addVisibleData = useCallback(() => {
    if (!heatmapInstance.current || !heatmapCanvasRef.current) {
      return;
    }
    try {
      const processedData = getData(
        getProcessedData({ canvasHeight, canvasWidth, data }),
        currentSecond
      );
      heatmapCanvasRef.current
        .getContext('2d')
        .clearRect(0, 0, canvasWidth, canvasHeight);
      heatmapInstance.current.setData({
        min: 0,
        max: 5,
        // max: processedData.reduce(
        //   (carry, current) => Math.max(carry, current.value),
        //   0
        // ), // refers to max value not max width/height
        data: processedData,
      });
    } catch (err) {
      console.error(err);
    }
  }, [currentSecond, canvasHeight, canvasWidth, data]);

  const setupCanvas = useCallback(() => {
    if (heatmapCanvasRef.current) {
      heatmapCanvasRef.current.remove();
    }
    if (!canvasContainerRef.current) {
      return;
    }
    var config = {
      container: canvasContainerRef.current.parentNode,
      // radius: 10,
      // maxOpacity: .5,
      // minOpacity: 0,
      // blur: .75
    };

    try {
      heatmapInstance.current = h337.create(config);
      addVisibleData();
      heatmapCanvasRef.current =
        canvasContainerRef.current.parentNode.querySelector('canvas');
      if (opacity) {
        heatmapCanvasRef.current.style.opacity = opacity;
      }
    } catch (err) {
      console.error(err);
    }
  }, [addVisibleData, opacity]);

  useLayoutEffect(() => {
    addVisibleData();
  }, [data, addVisibleData]);

  const prevCurrentSecond = usePrevious(currentSecond);
  useEffect(() => {
    if (data && currentSecond !== prevCurrentSecond) {
      setupCanvas();
    }
  }, [currentSecond, data, prevCurrentSecond, setupCanvas]);

  const getData = (data, second) => {
    return data.filter((item) => Math.floor(item.sec) === Math.floor(second));
  };

  const style = {
    height: Math.max(300, canvasHeight) + 'px',
    width: Math.max(300, canvasWidth) + 'px',
  };

  return (
    <StyledHeatmapWrapper style={style}>
      {/** need outer div because heatmap sets it to position relative */}
      <div>
        <div ref={canvasContainerRef} id={id} style={style}></div>
      </div>
    </StyledHeatmapWrapper>
  );
};

Heatmap.defaultProps = {
  opacity: 1,
};

Heatmap.propTypes = {
  id: PropTypes.string.isRequired,
  canvasWidth: PropTypes.number,
  canvasHeight: PropTypes.number,
  currentTime: PropTypes.number.isRequired,
  data: PropTypes.array,
  opacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Heatmap;
