import React, { FC, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from 'react-select';
import { useDebouncedCallback } from 'use-debounce';
import { useMutation, useQuery } from '@apollo/client';
import { useFlashMessage } from '@core/components/FlashMessage';
import useUser from '@core/helpers/useUser';
import LoadingIndicator from '@base/components/LoadingIndicator';
import StyledLabel from '@base/styled-components/StyledLabel';
import {
  ADD_MEMBER_TO_GROUP,
  READ_MEMBERS_SELECT,
} from '@core/components/Users/AddUserToGroup/AddUserToGroup.query';

/**
 * @type {FC<{className?: string; groupID: string; refetchGroupAndUsers: () => void;}>}
 */
const AddUserToGroup = ({ className, groupID, refetchGroupAndUsers }) => {
  const [userSearch, setUserSearch] = useState('');
  const [addingAndRefreshing, setAddingAndRefreshing] = useState(false);
  const [userSearchDB, setUserSearchDB] = useState('');
  const { OrganisationID: userOrganisationID } = useUser();

  const setDBSearch = useDebouncedCallback(() => {
    setUserSearchDB(userSearch);
  }, 500);

  const { addFlashMessage } = useFlashMessage();
  const [addMemberToGroup] = useMutation(ADD_MEMBER_TO_GROUP, {
    onCompleted: async () => {
      await refetchGroupAndUsers();
      addFlashMessage('Added user to group', 'success');
      setAddingAndRefreshing(false);
    },
    onError: () => {
      addFlashMessage('Failed to add user to group', 'error');
      setAddingAndRefreshing(false);
    },
  });

  const { data, loading } = useQuery(READ_MEMBERS_SELECT, {
    fetchPolicy: 'network-only',
    variables: {
      filter: userSearchDB !== '' ? userSearchDB : '****************',
      organisationID: userOrganisationID,
    },
  });

  const options = useMemo(() => {
    return data && data.readMembers
      ? data.readMembers.edges
          .filter((userEdge) => {
            const userOrgIDs = userEdge.node.OrganisationGroups.edges.map(
              (grpEdge) => grpEdge.node.ID
            );
            return !userOrgIDs.includes(groupID);
          })
          .map((userEdge) => {
            return {
              value: userEdge.node.ID,
              label: userEdge.node.Name,
            };
          })
      : [];
  }, [groupID, data]);

  return (
    <div className={className}>
      <StyledLabel>Add User</StyledLabel>
      {addingAndRefreshing && <LoadingIndicator />}
      {!addingAndRefreshing && (
        <Select
          placeholder="Search by first name or last name..."
          value={userSearch}
          onInputChange={(userSearch) => {
            setUserSearch(userSearch);
            setDBSearch();
          }}
          onChange={(newMember) => {
            setAddingAndRefreshing(true);
            addMemberToGroup({
              variables: {
                GroupID: parseInt(groupID, 10),
                MemberID: newMember.value,
              },
            });
          }}
          options={options}
          noOptionsMessage={() => (loading ? 'Loading...' : 'No users found')}
        />
      )}
    </div>
  );
};

AddUserToGroup.propTypes = {
  className: PropTypes.string,
  groupID: PropTypes.string.isRequired,
  refetchGroupAndUsers: PropTypes.func.isRequired,
};

export default AddUserToGroup;
