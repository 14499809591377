import React from 'react';

const DiamondIcon = () => {
  return (
    <svg
      width="32"
      height="26"
      viewBox="0 0 32 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="diamond-icon-title"
      role="img"
    >
      <title id="diamond-icon-title">Diamond Icon</title>
      <path
        d="M26.821 2.41769L3.38804 2.41769C3.18719 4.69403 2.78549 9.32704 2.78549 9.6484C2.78549 9.96977 11.5337 18.3074 15.9078 22.436L28.0259 10.4518L29.0302 6.56867L26.821 2.41769Z"
        fill="#B8E3DB"
      />
      <rect
        x="0.0721741"
        y="3.09344"
        width="1.62742"
        height="7.49013"
        fill="#066458"
      />
      <rect
        x="1.69788"
        y="3.09305"
        width="1.46955"
        height="7.49013"
        fill="#249688"
      />
      <rect
        x="3.16037"
        y="4.62201"
        width="1.46955"
        height="5.96054"
        fill="#DFFFFA"
      />
      <rect
        x="26.933"
        y="3.0936"
        width="1.46955"
        height="1.54308"
        fill="#249688"
      />
      <rect
        x="19.4944"
        y="12.065"
        width="1.46955"
        height="1.54308"
        fill="#249688"
      />
      <rect
        x="18.0119"
        y="13.6085"
        width="1.46955"
        height="2.87454"
        fill="#249688"
      />
      <rect
        x="10.6111"
        y="12.0499"
        width="1.46955"
        height="1.54308"
        fill="#249688"
      />
      <rect
        x="12.0807"
        y="13.593"
        width="1.46955"
        height="2.89018"
        fill="#249688"
      />
      <rect
        x="13.576"
        y="16.4828"
        width="1.46955"
        height="2.9931"
        fill="#249688"
      />
      <rect
        x="16.5366"
        y="16.4841"
        width="1.46955"
        height="2.9931"
        fill="#249688"
      />
      <rect
        x="26.9308"
        y="4.59143"
        width="1.46955"
        height="1.54308"
        fill="#066458"
      />
      <rect
        x="28.4154"
        y="4.59207"
        width="1.46955"
        height="5.99098"
        fill="#249688"
      />
      <rect
        x="26.9308"
        y="4.59143"
        width="1.46955"
        height="5.99098"
        fill="#249688"
      />
      <rect
        x="3.17035"
        y="1.6246"
        width="23.7571"
        height="1.46934"
        fill="#249688"
      />
      <rect
        x="4.63071"
        y="3.1412"
        width="10.4162"
        height="1.46934"
        fill="#DFFFFA"
      />
      <rect
        x="16.5081"
        y="3.1416"
        width="10.4162"
        height="1.46934"
        fill="#DFFFFA"
      />
      <rect
        x="3.16751"
        y="10.5803"
        width="23.7571"
        height="1.46934"
        fill="#249688"
      />
      <rect
        x="1.69861"
        y="1.62451"
        width="1.46934"
        height="1.46934"
        fill="#066458"
      />
      <rect
        x="3.16751"
        y="0.00012207"
        width="23.7269"
        height="1.62742"
        fill="#066458"
      />
      <rect
        x="1.69861"
        y="10.5838"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="26.9251"
        y="1.62775"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="4.66562"
        y="13.5476"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="4.66492"
        y="12.0655"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="6.1488"
        y="12.0671"
        width="1.48214"
        height="1.48214"
        fill="#DFFFFA"
      />
      <rect
        x="7.62839"
        y="16.5115"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="7.62695"
        y="15.0292"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="9.11084"
        y="15.0309"
        width="1.48214"
        height="1.48214"
        fill="#DFFFFA"
      />
      <rect
        x="10.5947"
        y="19.4766"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="10.5933"
        y="17.9944"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="12.0786"
        y="17.9936"
        width="1.48214"
        height="1.48214"
        fill="#DFFFFA"
      />
      <rect
        x="12.075"
        y="19.4762"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="13.5681"
        y="19.477"
        width="1.48214"
        height="1.48214"
        fill="#DFFFFA"
      />
      <rect
        x="15.0485"
        y="19.4767"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="16.5202"
        y="19.4769"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="13.5689"
        y="20.9594"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="15.0492"
        y="20.9595"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="13.5568"
        y="22.441"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="16.5181"
        y="22.4398"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="15.0378"
        y="22.44"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="19.4851"
        y="19.477"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="22.4486"
        y="16.5128"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="25.4128"
        y="13.5471"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="28.3763"
        y="10.5829"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="3.17963"
        y="12.0657"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="28.4097"
        y="3.11087"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="29.8872"
        y="4.59128"
        width="1.48214"
        height="5.99098"
        fill="#066458"
      />
      <rect
        x="6.14664"
        y="15.0302"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="6.14594"
        y="13.5473"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="7.63907"
        y="13.5487"
        width="1.48214"
        height="1.48214"
        fill="#DFFFFA"
      />
      <rect
        x="9.10941"
        y="17.9939"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="9.1087"
        y="16.5112"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="10.5933"
        y="16.5115"
        width="1.48214"
        height="1.48214"
        fill="#DFFFFA"
      />
      <rect
        x="12.075"
        y="20.959"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="15.0378"
        y="23.9228"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="18.0013"
        y="20.9587"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="18.0013"
        y="19.4759"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="16.5167"
        y="20.958"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="20.9683"
        y="17.9958"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="19.4844"
        y="17.9948"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="17.9998"
        y="17.994"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="19.4801"
        y="16.5103"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="20.9683"
        y="16.5129"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="20.9676"
        y="15.0308"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="22.4479"
        y="15.0305"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="22.4443"
        y="13.5625"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="23.9289"
        y="13.5629"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="23.9282"
        y="12.0802"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="25.4163"
        y="12.0828"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="26.8917"
        y="10.5823"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="25.4398"
        y="4.62357"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="23.956"
        y="6.10596"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="22.4728"
        y="7.58649"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="20.9882"
        y="9.06924"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="19.5072"
        y="7.58673"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="18.0219"
        y="6.10428"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="16.5445"
        y="4.62366"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="15.0463"
        y="3.14053"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="13.5667"
        y="4.62268"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="12.0829"
        y="6.10507"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="10.6033"
        y="7.58743"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="9.11938"
        y="9.06931"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="7.63409"
        y="7.58649"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="6.15662"
        y="6.10544"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="4.63712"
        y="4.62155"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="3.15823"
        y="3.14227"
        width="1.48214"
        height="1.48214"
        fill="#249688"
      />
      <rect
        x="23.9282"
        y="15.0293"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
      <rect
        x="26.8959"
        y="12.0667"
        width="1.48214"
        height="1.48214"
        fill="#066458"
      />
    </svg>
  );
};

export default DiamondIcon;
