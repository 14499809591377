import { Modal } from '@virtidev/toolbox';
import styled from 'styled-components';
import newTheme from '../../../../../themes/new-theme';
import { NavLink } from 'react-router-dom';

export const ResizedModal = styled(Modal)`
  ${newTheme}
  .modal-content {
    margin: 0 var(--content-padding, 25px);
    border: 1px solid #ccc;
    border-radius: var(--card-border-radius, 24px);
    align-items: center;
  }

  .modal-box {
    width: 75%;
    max-width: 538px;
    height: 95vh;

    @media screen and (min-width: 768px) {
      width: 70%;
      max-width: none;
      height: 85vh;
    }
    @media screen and (min-width: 1200px) {
      width: 1080;
      max-width: none;
      height: 75vh;
    }
  }
`;

export const AssetContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: var(--content-padding);
  padding-bottom: var(--content-padding);
  width: 100%;
`;

export const FilterContainer = styled.div`
  margin-bottom: var(--content-padding);
  width: 100%;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const UploadNotice = styled.div`
  padding-left: 5px;
`;

export const FooterLink = styled(NavLink)`
  color: ${({ theme }) => theme.color.turquoise};
  text-decoration: underline;
`;