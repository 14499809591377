import React from 'react';
import { useQuery } from '@apollo/client';
import { startOfMonth, addMonths, startOfDay, differenceInDays } from 'date-fns';
import useUser from '../../../helpers/useUser';
import { READ_ORGANISATION_SESSION_DASHBOARD } from './SessionDashboard.query';
import {
  Card, 
  displayDate, 
  ProgressBar
} from '@virtidev/toolbox';
import {
  Container,
  PlanHeader,
  PlanTitle,
  ExpiryDate,
  ProgressBarContainer,
  SessionInfo,
  ResetInfo,
  SessionContent,
  SessionUsedCount,
  SessionUsedText,
} from './SessionDashboard.styled';

export default function SessionDashboard() {
  const { Organisation } = useUser();

  const { data, loading, error } = useQuery(
    READ_ORGANISATION_SESSION_DASHBOARD,
    {
      variables: {
        OrganisationID: Organisation?.ID,
      },
      skip: !Organisation?.ID,
    }
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data</div>;
  }

  const organisation = data?.readOneOrganisation;
  const platformLicense = organisation?.PlatformLicense;

  // Only render the component if PlatformLicense exists and the license type is "SESSION"
  if (!platformLicense || platformLicense.Type !== 'SESSION') {
    return null;
  }

  const sessionsUsed = organisation?.MonthlySessions || 0;
  const totalSessionsLimit = platformLicense?.SessionsPerMonth || 1500;

  const currentDate = new Date();
  const firstDayNextMonth = startOfMonth(addMonths(currentDate, 1));
  const resetInDays = differenceInDays(startOfDay(firstDayNextMonth), startOfDay(currentDate));

  
  const expirationDate =
    displayDate(platformLicense.ExpiryDate, true) ||
    displayDate(new Date(2025, 1, 1));

  const progressPercentage =
    totalSessionsLimit > 0 ? (sessionsUsed / totalSessionsLimit) * 100 : 100;

  return (
    <Container>
      <Card noPadding={true}>
        <div>
          <PlanHeader>
            <PlanTitle>Your plan: {platformLicense.Title}</PlanTitle>
            <ExpiryDate>
              Expires on <strong>{expirationDate}</strong>
            </ExpiryDate>
          </PlanHeader>
          <SessionContent>
            <SessionUsedCount>
              {sessionsUsed}/{totalSessionsLimit}
            </SessionUsedCount>
            <ProgressBarContainer>
              <ProgressBar progress={progressPercentage} max={100} />
            </ProgressBarContainer>
            <SessionInfo>
              <SessionUsedText>Sessions used this month</SessionUsedText>
              <ResetInfo>Resets in {resetInDays} days</ResetInfo>
            </SessionInfo>
          </SessionContent>
        </div>
      </Card>
    </Container>
  );
}
