import { gql } from '@apollo/client';

export const UPDATE_ORGANISATION = gql`
  mutation updateOrganisation($Input: UpdateOrganisationInput!) {
    updateOrganisation(input: $Input) {
      ID
      TUSEndpoint
      Partner
      VirtualHumanAPI
      Licenses
      VirtualHumanFreeformLimit
      VirtualHumanBranchingLimit
      Domain
      HubSpotCompanyID
    }
  }
`;