import styled, { css } from 'styled-components';

export const TitleContainer = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${spacing.md};
  `}
`;
