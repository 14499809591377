import { useQuery } from '@apollo/client';
import { Card, FilterChip } from '@virtidev/toolbox';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { usePageScroll } from '../../../Page';
import PaginationControl from '../../../PaginationControl';
import { usePaginationUrl } from '../../../PaginationControl/helpers/usePaginationUrl';
import { Block, Content } from '../../../Analytics/Analytics.styled';
import { MY_VIRTUAL_HUMAN_SESSIONS } from './MyVirtualHumanSessions.query';
import { useUser } from '../../../../helpers/useUser';
import useStateUrl from '../../../../helpers/useStateUrl';
import { useDebouncedSave } from '../../../form/hooks/useDebouncedSave';
import { FilterContainer, SearchInput } from './MyVirtualHumanSessions.styled';
import useSortUrl from '../../../../helpers/useSortUrl';
import { created as sortOptions } from '../../../../helpers/sortCollections';
import VirtualHumanAnalyticsSubmissionsTable from '@core/components/Analytics/VirtualHuman/VirtualHumanAnalyticsSubmissionsTable/VirtualHumanAnalyticsSubmissionsTable';
import LoadingPageIndicator from '@base/components/LoadingPageIndicator';
import {
  HeaderBlock,
  RightSideFilter,
} from '@core/components/Analytics/VirtualHuman/AnalyticsVirtualHuman.styled';

export const MyVirtualHumanSessions = () => {
  const [incomplete, setIncomplete] = useState(true);
  const { scrollTop } = usePageScroll();
  const { ID, OrganisationID } = useUser();

  const { page, pageSize, resetPage, controlProps } = usePaginationUrl({
    pageSize: 25,
    onchange: scrollTop,
  });

  const { value: search, updateValue: updateSearch } = useStateUrl({
    key: 'search',
  });

  const { sortField, sortDirection, updateSort } = useSortUrl(sortOptions);

  const filter = {
    MemberID: { eq: ID },
    VirtualHuman: {
      ID: { ne: 0 },
      OrganisationID: { eq: OrganisationID },
      ...(search && { Title: { contains: search } }),
    },
    ...(incomplete ? {} : { Finished: { eq: true } }),
  };

  const { data, error, loading } = useQuery(MY_VIRTUAL_HUMAN_SESSIONS, {
    variables: {
      filter,
      limit: pageSize,
      offset: (page - 1) * pageSize,
      sort: {
        [sortField]: sortDirection,
      },
    },
  });

  const handleChange = useMemo(
    () =>
      _.debounce(({ target }) => {
        resetPage();
        setIncomplete(!target.checked);
      }, 10),
    [setIncomplete, resetPage]
  );

  const onUpdateUserSearch = useCallback(
    (value) => {
      updateSearch(value || '');
      resetPage();
    },
    [updateSearch, resetPage]
  );

  const debouncedSearchProps = useDebouncedSave(search, {
    onUpdate: onUpdateUserSearch,
    enter: true,
  });

  const sessions = useMemo(
    () =>
      [...(data?.readAnalyticsSubmissions?.nodes || [])].map((node) => {
        const score =
          node?.AnalyticsEvents?.edges?.[0]?.node?.PDConversationScore;
        return {
          key: node.ID,
          title: node.VirtualHuman.Title,
          startDateTime: node.Created,
          endDateTime: node?.AnalyticsEvents?.edges?.[0]?.node?.Created,
          score:
            score > -1 && !node.VirtualHuman.HideScoresFromUsers ? score : null,
          logUrl: node.PDAssessmentID
            ? `/virtual-humans/${node.VirtualHuman.ID}/builder/patient/${node.VirtualHuman.ExternalID}/my-logs/${node.PDAssessmentID}`
            : undefined,
        };
      }),
    [data]
  );

  return (
    <Content>
      <Block>
        <Card noPadding={true}>
          <HeaderBlock>
            <FilterContainer>
              <SearchInput
                placeholder="Search by Virtual Human name..."
                {...debouncedSearchProps}
              />
              <RightSideFilter>
                <div>
                  <FilterChip checked={incomplete} onClick={handleChange}>
                    Show incomplete sessions
                  </FilterChip>
                </div>
              </RightSideFilter>
            </FilterContainer>
          </HeaderBlock>
          {error ? (
            <LoadingPageIndicator error={error} />
          ) : (
            <VirtualHumanAnalyticsSubmissionsTable
              offset={(page - 1) * pageSize}
              analyticsSubmissions={data?.readAnalyticsSubmissions.nodes}
              nameColumn="virtual-human"
              useUserAnalyticsLink={false}
              vhData={data?.readOneVirtualHuman}
              loading={loading}
              handleSort={updateSort}
              currentSort={{ sortField, sortDirection }}
              canDelete={false}
              showScores={true}
              noLinks={true}
              areOwnLogs={true}
            />
          )}
          <PaginationControl
            {...controlProps}
            total={data?.readAnalyticsSubmissions?.pageInfo?.totalCount}
          />
        </Card>
      </Block>
    </Content>
  );
};

export default MyVirtualHumanSessions;
