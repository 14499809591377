import styled, { css } from 'styled-components';

export const StyledHeatmapSectionWrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1300px;
  display: grid;
  gap: 1rem;
`;

export const StyledHeatmapArea = styled.div`
  aspect-ratio: 16/9;
`;

export const StyledNoVideo = styled.div`
  height: 200px;
  display: grid;
  place-items: center;
`;

export const NoHeatmapDataWrapper = styled.div`
  ${({ theme: { color } }) => css`
    text-align: center;
    background-color: ${color.blue40};
    width: 100%;
    aspect-ratio: 16/9;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.75rem;
  `}
`;
export const NoHeatmapDataIconWrapper = styled.div`
  ${({ theme: { color } }) => css`
    background-color: ${color.blue40};
  `}
`;
export const NoHeatmapDataTextContentWrapper = styled.div`
  ${({ theme: { color } }) => css`
    background-color: ${color.blue40};
    max-width: 268px;
    display: flex;
    align-items: space-between;
    flex-direction: column;
    gap: 0.5rem;
  `}
`;
export const NoHeatmapDataHeader = styled.h2`
  ${({ theme: { font, color } }) => css`
    ${font.h2};
    color: ${color.midnight5};
  `}
`;
export const NoHeatmapDataContent = styled.div`
  ${({ theme: { font, color } }) => css`
    ${font.body}
    color: ${color.midnight10};
  `}
`;
