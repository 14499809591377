import { gql } from '@apollo/client';

export const CREATE_SIM_VIDEO = gql`
  mutation createSimVideo(
    $URL: String!
    $Filename: String!
    $TusID: String!
    $OrganisationID: ID!
    $Content360: Boolean!
  ) {
    createSimulationFromURL(
      URL: $URL
      Filename: $Filename
      TusID: $TusID
      OrganisationID: $OrganisationID
      Content360: $Content360
    ) {
      ID
    }
  }
`;

export const CREATE_SIM_MEDIA = gql`
  mutation createSimMedia(
    $URL: String!
    $Filename: String!
    $TusID: String!
    $OrganisationID: ID!
    $Content360: Boolean!
  ) {
    createSimulationFromMediaURL(
      URL: $URL
      Filename: $Filename
      TusID: $TusID
      OrganisationID: $OrganisationID
      Content360: $Content360
    ) {
      ID
    }
  }
`;

export const CREATE_WITH_EXISTING_VIDEO = gql`
  mutation createSimulationFromVideoMedia($videoID: ID!) {
    createSimulationFromVideoMedia(VideoMediaID: $videoID) {
      ID
    }
  }
`;

export const CREATE_WITH_EXISTING_MEDIA = gql`
  mutation createSimulationFromMedia($mediaID: ID!) {
    createSimulationFromMedia(MediaID: $mediaID) {
      ID
    }
  }
`;
