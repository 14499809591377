import styled from 'styled-components';

export const StyledVideoArea = styled.div`
  position: relative;
`;

export const StyledVideo = styled.video`
  width: 100%;
`;

export const StyledVideoAreaNoOverflow = styled.div`
  padding-bottom: ${(props) =>
    props.stereoscopic ? props.videoHeight + 'px' : props.aspectRatioPercent};
  overflow: hidden;
`;
