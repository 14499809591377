import { useQuery } from '@apollo/client';
import useUser from '../../apps/core/src/helpers/useUser';
import { GET_ORG_VH_COUNTS } from './OrgVHCounts.query';
import useGetFreeAgentsCount from '../../utility/VHHooks/useGetFreeAgentsCount';
import { useCallback } from 'react';

const useGetVHCounts = (organisationID = null, apiEndpoint = null) => {
  let { OrganisationID } = useUser();
  if (organisationID) {
    OrganisationID = organisationID;
  }

  const {
    data: orgData,
    error: orgError,
    loading: orgLoading,
    refetch: refetchOrgCounts,
  } = useQuery(GET_ORG_VH_COUNTS, {
    variables: {
      OrganisationID,
    },
  });

  const defaultTotalCount =
    orgData?.readOneOrganisation.DefaultVirtualHumans.pageInfo?.totalCount ?? 0;
  const medicalTotalCount =
    orgData?.readOneOrganisation.MedicalVirtualHumans.pageInfo?.totalCount ?? 0;
  const branchingTotalCount =
    orgData?.readOneOrganisation.BranchingVirtualHumans.pageInfo?.totalCount ??
    0;
  const allOrgsUnusedFreeformLicenses =
    orgData?.readOneOrganisation.UnusedFreeformLicenses ?? 0;

  const {
    freeAgentsCount,
    isLoading: freeAgentsLoading,
    error: freeAgentsError,
    refetch: refetchAgentsCount,
  } = useGetFreeAgentsCount(apiEndpoint);

  const error = orgError || freeAgentsError;
  const isLoading = orgLoading || freeAgentsLoading;
  const maxVHs =
    (orgData?.readOneOrganisation?.VirtualHumanFreeformLimit ?? 0) +
    (orgData?.readOneOrganisation?.VirtualHumanBranchingLimit ?? 0);
  const orgVHCount =
    branchingTotalCount + defaultTotalCount + medicalTotalCount;

  const freeformTotalCount = medicalTotalCount + defaultTotalCount;
  // const canMakeBranching =
  //   branchingTotalCount <
  //   (orgData?.readOneOrganisation?.VirtualHumanBranchingLimit ?? 0);
  const canMakeFreeform =
    freeformTotalCount <
      (orgData?.readOneOrganisation?.VirtualHumanFreeformLimit ?? 0) &&
    freeAgentsCount > 0;

  const allOrgsMaxAllowedFreeformLicenses =
    freeAgentsCount - allOrgsUnusedFreeformLicenses;
  const additionalFreeformLicensesAssignable =
    freeAgentsCount - allOrgsUnusedFreeformLicenses;

  const agentBufferCount = 100;
  const overuseThreshold = 1.5 * agentBufferCount;
  const practicalFreeformLicensesAssignable =
    freeAgentsCount <= agentBufferCount
      ? 0
      : Math.max(0, additionalFreeformLicensesAssignable + overuseThreshold);

  // not actually used atm yet
  const refetch = useCallback(() => {
    refetchOrgCounts();
    refetchAgentsCount();
  }, [refetchAgentsCount, refetchOrgCounts]);

  return {
    error,
    orgVHCount,
    isLoading,
    branchingMax: orgData?.readOneOrganisation?.VirtualHumanBranchingLimit ?? 0,
    freeformMax: orgData?.readOneOrganisation?.VirtualHumanFreeformLimit ?? 0,
    maxVHs,
    branchingTotalCount,
    freeformTotalCount,
    canMakeBranching: true, // limit now removed off
    canMakeFreeform,
    freeAgentsCount,
    allOrgsUnusedFreeformLicenses,
    allOrgsMaxAllowedFreeformLicenses,
    additionalFreeformLicensesAssignable,
    practicalFreeformLicensesAssignable,
    refetch,
  };
};

export default useGetVHCounts;
