import styled, { css } from 'styled-components';

export const BoxContainer = styled.div`
  ${({ theme: { color, borderRadius } }) => css`
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed ${color.blue20};
    background: ${color.blue50};
    border-radius: ${borderRadius.sm};
    min-height: 200px;
    cursor: pointer;
  `}
`;

export const DraggingOverlay = styled.div`
  ${({ theme: { color, borderRadius } }) => css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${borderRadius.sm};
    background: ${color.blue40};
  `}
`;

export const DraggingContent = styled.div``;

export const BoxContent = styled.h2`
  ${({ theme: { font } }) => css`
    ${font.h2}
  `}
`;

export const BoxIcon = styled.div`
  height: 50px;
  width: 50px;
  margin-bottom: 1rem;
`;

export const BoxSub = styled.span`
  ${({ theme: { font, color } }) => css`
    ${font.smallnote}
    margin-bottom: calc(40px + 1rem);
    color: ${color.midnight10};
  `}
`;
