import { FC, useMemo } from 'react';
import { useLocation } from 'react-router';
import { GlobalStyle } from '@virtidev/toolbox';
import { ThemeProvider } from 'styled-components';
import {
  LegacyCssVars,
  VirtiGlobalStyles,
  lightTheme,
} from './VirtiTheme.styled';
import { inIframe } from '@base/utility/WindowUtility';
import { isPublicVHPlayerPage } from '@base/utility/VirtualHumanUtility';
import { isPublicSimPlayerPage } from '@core/components/Simulation/helpers/isPublicSimPlayerPage';

/**
 * @type {FC<{}>}
 */
export const VirtiTheme = ({ children }) => {
  const location = useLocation();

  const fullHeight = useMemo(() => {
    if (isPublicSimPlayerPage(location) || isPublicVHPlayerPage(location)) {
      return true;
    }

    return inIframe();
  }, [location]);

  return (
    <ThemeProvider theme={lightTheme}>
      <meta name="theme-color" content="#000000" />
      <GlobalStyle $reset />
      <VirtiGlobalStyles $fullHeight={fullHeight} />
      <LegacyCssVars />
      {children}
    </ThemeProvider>
  );
};

export default VirtiTheme;
