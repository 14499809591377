import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { READ_ORGANISATION } from '../queries/OrganisationQueries';
import withUser from '../HOCs/WithUser';
import StyledPage, {
  StyledTopArea,
  StyledPageContentInner,
  PageContentCard,
} from '../components/StyledPage';
import LoadingPageIndicator from '../components/LoadingPageIndicator';
import PageLoadError from '../components/PageLoadError';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import OrganisationEditForm from '../forms/OrganisationEditForm';
import StyledPageHeader from '../styled-components/StyledPageHeader';
import { CanEditOrganisation } from '../utility/Permissions';
import OrganisationAPI from '../apps/core/src/components/Organisation/OrganisationAPI/OrganisationAPI';
import APILogs from '../apps/core/src/components/Organisation/APILogs/APILogs';
import SessionDashboard from '../apps/core/src/components/Organisation/SessionDashboard/SessionDashboard';

const OrganisationPage = (props) => {
  const { data, loading, error } = useQuery(READ_ORGANISATION, {
    variables: { ID: props.userOrganisationID },
  });
  const [hasSetExtraData, setHasSetExtraData] = useState(false);
  useEffect(() => {
    // set organisation data as extra data for onboarding so we can check if certain onboarding objectives
    // have previously been completed or not (e.g. description is not empty)
    if (
      !hasSetExtraData &&
      data &&
      typeof data.readOneOrganisation !== 'undefined'
    ) {
      setHasSetExtraData(true);
    }
  }, [hasSetExtraData, data]);

  if (!props.userID) {
    return <RedirectWithPrevState to="/login" />;
  }
  if (!CanEditOrganisation()) {
    return <RedirectWithPrevState to="/" />;
  }
  if (!loading && !error && !data?.readOneOrganisation) {
    return <RedirectWithPrevState to="/" />;
  }

  return (
    <StyledPage pageKey="organisation" hasSaveBar={true}>
      {loading && <LoadingPageIndicator />}
      {error && <PageLoadError graphQLErrorObj={error} />}
      {!loading && !error && data && (
        <StyledPageContentInner>
          <StyledTopArea>
            <StyledPageHeader>Edit Profile</StyledPageHeader>
          </StyledTopArea>
          <PageContentCard>
            <OrganisationEditForm organisation={data.readOneOrganisation} />
          </PageContentCard>
          <br></br>
          <SessionDashboard />
          <br></br>
          <PageContentCard>
            <OrganisationAPI />
            <APILogs />
          </PageContentCard>
        </StyledPageContentInner>
      )}
    </StyledPage>
  );
};

OrganisationPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
};

export default withUser(OrganisationPage);
