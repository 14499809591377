import {
  FlatButtonContent,
  FlatButtonDescription,
  FlatButtonIconWrapper,
  FlatButtonMain,
  StyledFlatButton,
} from '@core/components/FlatButton/FlatButton.styled';
import { Icon } from '@virtidev/toolbox';
import React, { FC } from 'react';

/** @type {FC<{onClick?: React.MouseEventHandler<HTMLButtonElement>; description: string;iconName: string;}>} */
const FlatButton = ({ children, iconName, description, onClick }) => {
  return (
    <StyledFlatButton onClick={onClick}>
      <FlatButtonIconWrapper>
        <Icon icon={iconName} />
      </FlatButtonIconWrapper>
      <FlatButtonContent>
        <FlatButtonMain>{children}</FlatButtonMain>
        {description && (
          <FlatButtonDescription>{description}</FlatButtonDescription>
        )}
      </FlatButtonContent>
    </StyledFlatButton>
  );
};

export default FlatButton;
