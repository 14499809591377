import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import VideoBarThumbnail from './VideoBarThumbnail';

const StyledSimulationVideoBar = styled.div`
  position: relative;
  overflow: auto;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      filter: grayscale();
    `}
`;
const SimulationCount = styled.div`
  flex: 1;
  display: flex;
`;

const StyledSimulationVideos = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex: 1;
`;
const StyledSimulationVideosInner = styled.div`
  /* probs not necessary but used in stackoverflow example: min-height: min-content; */
  display: flex;
`;

const StyledSimulationVideoBarInner = styled.div`
  display: flex;
  ${SimulationCount} {
    display: none;
  }
`;

const SimulationVideoBarBasic = (props) => {
  // put start video at left side of video bar
  let orderedVideos = [];
  let startVideo = props.videos360.find(
    (video) => video.ID === props.startVideoID
  );
  if (startVideo) {
    orderedVideos = [
      startVideo,
      ...props.videos360.filter((video) => video.ID !== props.startVideoID),
    ];
  } else {
    orderedVideos = props.videos360;
  }

  const handleVideoThumbClick = useCallback(
    (videoID) => {
      if (props.onClick) {
        props.onClick(videoID);
      }
    },
    [props]
  );

  return (
    <StyledSimulationVideoBar data-testid="videobar" disabled={props.disabled}>
      <StyledSimulationVideoBarInner>
        <StyledSimulationVideos>
          <StyledSimulationVideosInner>
            {orderedVideos.map((simulationVideo, index) => (
              <VideoBarThumbnail
                key={simulationVideo.ID}
                simulationID={props.simulationID}
                video={simulationVideo}
                pageType={props.isAnalyticsView ? 'analytics' : 'interactions'}
                selectedVideoID={props.selectedVideoID}
                videoCount={props.videos360.length}
                isStartVideo={simulationVideo.ID === props.startVideoID}
                onClick={handleVideoThumbClick}
              />
            ))}
          </StyledSimulationVideosInner>
        </StyledSimulationVideos>
      </StyledSimulationVideoBarInner>
    </StyledSimulationVideoBar>
  );
};

SimulationVideoBarBasic.propTypes = {
  simulationID: PropTypes.string.isRequired,
  selectedVideoID: PropTypes.string.isRequired,
  startVideoID: PropTypes.string.isRequired,
  pageType: PropTypes.string,
  videos360: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};

SimulationVideoBarBasic.defaultProps = {};

export default SimulationVideoBarBasic;
