import ClockIcon from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/ScoreIcon/components/ClockIcon';
import DiamondIcon from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/ScoreIcon/components/DiamondIcon';
import MedalIcon from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/ScoreIcon/components/MedalIcon';
import React, { FC, useCallback } from 'react';
import PropTypes from 'prop-types';

/** @type {FC<{iconName: 'medal' | 'clock' | 'diamond'}>} */
const ScoreIcon = ({ iconName }) => {
  const icon = useCallback(() => {
    if (iconName === 'medal') {
      return <MedalIcon />;
    } else if (iconName === 'clock') {
      return <ClockIcon />;
    }
    return <DiamondIcon />;
  }, [iconName]);

  return <>{icon()}</>;
};

ScoreIcon.propTypes = {
  iconName: PropTypes.oneOf(['medal', 'clock', 'diamond']).isRequired,
};

export default ScoreIcon;
