import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { withPageTemplate } from '../../../templates';
import { Titlebar, usePageScroll } from '../../../components/Page';
import { Button, TextInput } from '@virtidev/toolbox';
import useUser, { withUser } from '../../../helpers/useUser';
import { usePaginationUrl } from '../../../components/PaginationControl/helpers/usePaginationUrl';
import { useApolloClient, useMutation } from '@apollo/client';
import { ADD_FEEDBACK_FORM } from '../../../../../../queries/FeedbackFormQueries';
import useStateUrl from '../../../helpers/useStateUrl';
import { useDebouncedSave } from '../../../components/form/hooks/useDebouncedSave';
import getConfig from '../../../../../../lib/config';
import {
  ActionBar,
  FieldWrapper,
} from '../../../components/form/ActionBar/ActionBar.styled';
import { StateUrlSelect } from '../../../components/form/Select';
import FeedbackFormsList from '../../../../../../components/FeedbackFormsList';
import WithOnboardingHandler from '../../../../../../HOCs/WithOnboardingHandler';
import { createdTitle as sortOptions } from '../../../helpers/sortCollections';
import { withFeedbackFormsAccess } from '../../../helpers/permissions';
import tracker from '../../../helpers/tracker';

const FeedbackFormsPage = (props) => {
  const { OrganisationID } = useUser();
  const { scrollTop } = usePageScroll();
  const { resetPage } = usePaginationUrl({
    onChange: scrollTop,
  });

  const [addForm] = useMutation(ADD_FEEDBACK_FORM, {
    onCompleted: (data) => {
      const ID = data?.createFeedbackForm?.ID;
      if (ID) {
        props.history.push(`/feedback-forms/${ID}`);
        props.updateProgress('create_form', ID);
        tracker.track('form_created', {
          form_id: ID,
        });
      }
    },
    update: (cache) => {
      // forces the pagination to refresh
      cache.evict({ fieldName: 'readFeedbackForms' });
    },
    onError: () => {
      props.addFlashMessage(
        'Something went wrong – form could not be created.',
        'error'
      );
    },
  });

  const handleCreate = () => {
    addForm({
      variables: {
        Input: {
          Title: 'New Form',
          OrganisationID,
          Type: 'WEB',
        },
      },
    });
  };

  const { value: filter, updateValue: setFilter } = useStateUrl({
    initialValue: '',
    onChange: scrollTop,
  });

  const handleFilterChange = useCallback(
    (value) => {
      resetPage();
      setFilter(value || '');
    },
    [setFilter, resetPage]
  );

  const debouncedProps = useDebouncedSave(filter, {
    onUpdate: handleFilterChange,
  });

  return (
    <>
      <Titlebar>Feedback Forms </Titlebar>
      <ActionBar>
        <FieldWrapper>
          <TextInput {...debouncedProps} placeholder="Search..." />
        </FieldWrapper>
        <FieldWrapper>
          <StateUrlSelect
            param="sort"
            options={sortOptions}
            placeholder="Sort..."
            inlineLabel="Sort:"
            initialValue={sortOptions[0].value}
            isSearchable={false}
          />
        </FieldWrapper>
        <Button icon="pen" color="turquoise" onClick={handleCreate}>
          Create New Form
        </Button>
      </ActionBar>
      <FeedbackFormsList handleCreate={handleCreate} />
    </>
  );
};

FeedbackFormsPage.propTypes = {};

export default _.flowRight(
  withPageTemplate({ pageKey: 'feedback-forms' }),
  WithOnboardingHandler,
  withUser,
  withFeedbackFormsAccess
)(FeedbackFormsPage);
