import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './ObjectiveGroupResult.styled';
import AnimateHeight from 'react-animate-height';
import 'react-circular-progressbar/dist/styles.css';
import { Icon } from '@virtidev/toolbox';
import { useTheme } from 'styled-components';

const ObjectiveGroupResult = ({
  allObjectives,
  hitObjectives,
  feedbackText,
  heading,
  easyMode,
  shownHintObjectiveIds,
  groupId,
}) => {
  const objectives = useMemo(() => {
    return allObjectives.filter((obj) => obj.groupId === groupId);
  }, [allObjectives, groupId]);
  const [expanded, setExpanded] = useState(false);
  const groupPointsPossible = objectives.reduce((carry, objective) => {
    return carry + Math.max(0, objective.scoreMetadata?.points ?? 0);
  }, 0);
  const groupObjectiveIds = objectives.map((objective) => objective.id);
  const groupPointsScored = hitObjectives.reduce((carry, objective) => {
    if (groupObjectiveIds.includes(objective.id)) {
      return carry + objective.calculatedPoints;
    }
    return carry;
  }, 0);

  const { color } = useTheme();

  if (objectives.length === 0) return <></>;
  return (
    <Styled.ExpandClickable as="div" onClick={() => setExpanded(!expanded)}>
      <Styled.Heading feedbackText={feedbackText ?? ''}>
        <Styled.ObjectiveTitle>
          {heading ? heading : 'Misc.'}
        </Styled.ObjectiveTitle>
        <Styled.ObjectiveCount>
          {`${groupPointsScored}/${groupPointsPossible}`}
        </Styled.ObjectiveCount>
        <Styled.ChevronWrapper $expanded={expanded}>
          <Icon icon="chevron" />
        </Styled.ChevronWrapper>
      </Styled.Heading>
      <AnimateHeight duration={300} height={expanded ? 'auto' : '0'}>
        <Styled.ObjectiveList>
          {feedbackText !== '' && (
            <Styled.ObjectiveGroupFeedbackWrapper>
              <Styled.ObjectiveGroupFeedbackText>
                <Styled.ObjectiveGroupFeedbackText>
                  {feedbackText}
                </Styled.ObjectiveGroupFeedbackText>
              </Styled.ObjectiveGroupFeedbackText>
            </Styled.ObjectiveGroupFeedbackWrapper>
          )}
          {objectives.map((objective) => {
            let multiplierText = '';
            const timesHit = hitObjectives.filter(
              (hitObjective) => hitObjective.id === objective.id
            ).length;
            const hintedToZeroPoints =
              shownHintObjectiveIds.includes(objective.id) && !easyMode;
            // we only want to show e.g. x2 if it is a negative and if it has been hit at least once
            if (objective?.scoreMetadata?.points < 0 && timesHit > 0) {
              multiplierText = ` (x${timesHit})`;
            }
            return (
              <Styled.Objective
                $scoreKey={objective?.scoreMetadata?.key}
                $points={objective?.scoreMetadata?.points}
                $timesHit={timesHit}
                $hintedToZeroPoints={hintedToZeroPoints}
              >
                <Styled.IconWrapper>
                  {timesHit > 0 && (
                    <Icon
                      icon={
                        objective.scoreMetadata?.points >= 0
                          ? 'success'
                          : 'error'
                      }
                      color={
                        objective.scoreMetadata?.points >= 0
                          ? color.turquoise
                          : color.error
                      }
                      type="solid"
                      size="1.1rem"
                    />
                  )}
                </Styled.IconWrapper>
                <Styled.ObjectiveTitle>{objective.name}</Styled.ObjectiveTitle>
                <Styled.ObjectivePoints>
                  <div>
                    {objective.scoreMetadata?.points}
                    {multiplierText}
                  </div>
                </Styled.ObjectivePoints>
              </Styled.Objective>
            );
          })}
        </Styled.ObjectiveList>
      </AnimateHeight>
    </Styled.ExpandClickable>
  );
};

ObjectiveGroupResult.propTypes = {
  heading: PropTypes.string.isRequired,
  objectives: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      scoreMetadata: PropTypes.shape({
        key: PropTypes.string.isRequired,
        points: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  hitObjectives: PropTypes.arrayOf(
    PropTypes.shape({
      scoreMetadataWithCalculated: PropTypes.shape({
        key: PropTypes.string.isRequired,
        calculatedPoints: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  groupId: PropTypes.number.isRequired,
};

export default ObjectiveGroupResult;
