import { GetAcceptedFiles, IsAcceptedFile } from '@base/utility/FileUtility';

const fileTypes = ['video', 'image', 'audio'];

export const getFileType = (type) => {
  return (
    fileTypes.find((fileType) =>
      IsAcceptedFile(type, GetAcceptedFiles(fileType))
    ) || null
  );
};

export default getFileType;
