import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import { READ_MEMBER } from '../queries/UserQueries'
import { setUserModeVar, setUserVar, userModeVar, userVar } from '../ApolloReactiveVars';
import { useApolloClient, useMutation } from '@apollo/client';
import { readOneMemberBasic } from '../query-functions/MemberQueryFunctions';
import { useHistory } from 'react-router-dom';
import { LOGIN_AS_USER } from '../apps/core/src/components/CXAnalytics/components/Signup/SignupUsage.query';
import { isPublicVHPlayerPage } from './VirtualHumanUtility';
import { getTrialRemainder } from '@core/components/Signup/legacy/helpers/getTrialRemainder';
import { isPublicSimPlayerPage } from '@core/components/Simulation/helpers/isPublicSimPlayerPage';

export const hasExpiredTrial = (org) => {
  return org.Trial && getTrialRemainder(org.TrialExpiryDate) < 1;
}

export const StoreLogin = function (Token, aiToken, userData, apolloClient, newOrganisationID = null) {
  if (Token) {
    UpdateToken(Token);
  }
  if (aiToken) {
    UpdateAIToken(aiToken);
  }

  UnsetLocalStorageUserData(true); // ensure leftover localstorage gets unset (mainly for avoiding permissions-related rendering of unlockable areas when switching organisations)
  // default organisation is the first organisation unless it's an expired trial, because we want to ensure users can access the account switcher
  let currentOrganisation = userData?.Organisations?.nodes?.find((org) => !hasExpiredTrial(org)) || userData?.Organisations?.nodes?.[0];
  // if we're setting up with a new organisation we should set it here
  if (newOrganisationID) {
    localStorage.setItem('currentOrganisationID', JSON.stringify(newOrganisationID));
  }
  let storageId = null;

  // log out if there's nothing found for the organisation list
  if (!currentOrganisation) {
    window.location.href = '/logout?msg=' + encodeURI("Unable to update login information, please try logging in again.");
    return;
  }

  try {
    storageId = JSON.parse(localStorage.getItem('currentOrganisationID') || 'null');
  }
  catch (e) {}
  // if we have an ID in memory then set it here
  if (storageId) {
    const orgBasedOnStorageID = userData.Organisations.nodes.find(org => org.ID === storageId);

    if (orgBasedOnStorageID) {
      // only set current organisation if it is found with the ID
      currentOrganisation = orgBasedOnStorageID;
    } else {
      console.error(`Couldn't find an organisation with the ID provided by currentOrganisationID. ID is "${storageId}" Setting organisation ID to "${currentOrganisation.ID}"`);
      localStorage.setItem("currentOrganisationID", JSON.stringify(currentOrganisation.ID));
    }
  } else {
    // there was no ID in memory so set it here
    try {
      localStorage.setItem('currentOrganisationID', JSON.stringify(currentOrganisation.ID));
    } catch (error) {
      window.location.href = '/logout?msg=' + encodeURI("That user does not belong to an organisation");
    }
  }
  if (currentOrganisation && currentOrganisation.VirtualHumanAPI) {
    localStorage.setItem("virtualHumanAPI", currentOrganisation.VirtualHumanAPI);
  }
  let user = formatUserDataForStorage(userData, currentOrganisation);
  if (Token) {
    user = { ...user, Token };
  }
  setUserVar(user);
  if (!userModeVar()) {
    setUserModeVar(user.UserType.toLowerCase() === 'user' ? 'user' : 'admin')
  }
}

const formatUserDataForStorage = function (userData, currentOrganisation) {
  return {
    ID: userData.ID,
    Name: userData.Name,
    FirstName: userData.FirstName,
    Surname: userData.Surname,
    Email: userData.Email,
    AvatarMedia: userData.AvatarMedia,
    OrganisationID: currentOrganisation.ID,
    Organisation: currentOrganisation,
    Organisations: Array.isArray(userData.Organisations) ? userData.Organisations : userData.Organisations.nodes,
    OnboardProgress: userData.OnboardProgress,
    UserType: userData.UserType,
    SignupMethod: userData.SignupMethod,
    ImpersonatorID: userData.ImpersonatorID,
  };
}

export const GetUserData = async (userID, organisationID, apolloClient) => {
  const memberData = await apolloClient.query({
    query: READ_MEMBER,
    variables: {
      ID: userID,
      OrganisationID: organisationID
    }
  })
  return memberData.data.readOneMember
}

export const UpdateUserData = function (userData, apolloClient) {
  const Token = GetAccessToken();
  const aiToken = GetAIToken();
  StoreLogin(Token, aiToken, userData, apolloClient);
}

export const UpdateToken = function (token) {
  try {
    const accessTokenDecrypted = jwtDecode(token);
    localStorage.setItem('token', JSON.stringify(token));

    // debugging expire 5 secs in future
    // localStorage.setItem('tokenExpiry', JSON.stringify(new Date().getTime() / 1000 + 5));
    localStorage.setItem('tokenExpiry', JSON.stringify(accessTokenDecrypted.exp));
    return true;
  } catch (e) {
    console.error('Bad token:', token);
    return false;
  }
}

export const UpdateAIToken = function (aiToken) {
  if (!aiToken) {
    console.error("VIRTUAL HUMAN TOKEN NOT PROVIDED")
    return false;
  }
  try {
    const accessTokenDecrypted = jwtDecode(aiToken);
    localStorage.setItem('ai_token', JSON.stringify(aiToken));

    // debugging expire 5 secs in future
    // localStorage.setItem('tokenExpiry', JSON.stringify(new Date().getTime() / 1000 + 5));
    localStorage.setItem('ai_tokenExpiry', JSON.stringify(accessTokenDecrypted.exp));
    return true;
  } catch (e) {
    console.error('Bad token:', aiToken);
    return false;
  }
}

export const TokenExpired = function (subtractSecondsFromExpiry = 30) {
  const expiry = localStorage.getItem('tokenExpiry');
  // console.log("token expired?")
  // console.log("time")
  // console.log(new Date().getTime() / 1000)
  // console.log("expiry")
  // console.log(expiry - subtractSecondsFromExpiry)
  return !expiry || expiry - subtractSecondsFromExpiry < new Date().getTime() / 1000
}

export const UnsetToken = function () {
  localStorage.removeItem('public_token');
  localStorage.removeItem('token');
  localStorage.removeItem('tokenExpiry');
}

export const UnsetAIToken = function () {
  localStorage.removeItem('ai_token');
  localStorage.removeItem('ai_tokenExpiry');
}

export const UnsetLocalStorageUserData = function (keepVar) {
  if (!keepVar) {
    userVar(null);
  }
  localStorage.removeItem('user');
  localStorage.removeItem('organisationID');
  localStorage.removeItem('organisationData');
  localStorage.removeItem("virtualHumanAccess");
  localStorage.removeItem("virtualHumanAPI");
  localStorage.removeItem("userMode");
}

const UnsetPreviousUserData = () => { // this is only for cleaning up "Login As User"
    localStorage.removeItem('previous_token');
    localStorage.removeItem('previous_ai_token');
    localStorage.removeItem('previous_customer_admin_id');
}

export const LogoutUser = function (callback) {
  UnsetPreviousUserData()
  UnsetLocalStorageUserData();
  UnsetToken();
  UnsetAIToken();
  if (callback) {
    callback();
  }
}

export const GetAccessToken = (privateToken = false) => {
  if (!privateToken && isPublicVHPlayerPage(document.location)) {
    return JSON.parse(localStorage.getItem('public_token') ?? 'null');
  }
  if (!privateToken && isPublicSimPlayerPage(document.location)) {
    return JSON.parse(localStorage.getItem('public_token') ?? 'null');
  }
  return JSON.parse(localStorage.getItem('token') ?? 'null');
}

export const GetAIToken = function () {
  if (isPublicVHPlayerPage(document.location)){
    return JSON.parse(localStorage.getItem('public_ai_token') ?? 'null');
  }
  return JSON.parse(localStorage.getItem('ai_token') ?? 'null');
}

export const StoreCurrentTokens = () => {
  const currentUser = userVar();
  localStorage.setItem('previous_token', localStorage.getItem('token'));
  localStorage.setItem('previous_ai_token', localStorage.getItem('ai_token'));
  localStorage.setItem('previous_customer_admin_id', JSON.stringify(currentUser.ID))
}

export const useLoginAsUser = () => {
  const client = useApolloClient();
  const history = useHistory();

  const [getToken, { loading }] = useMutation(LOGIN_AS_USER, {
    onCompleted: async (data) => {
      const currentUser = userVar();
      UpdateToken(data?.createUserToken?.Token);
      if (data?.createUserToken?.PDToken) {
        UpdateAIToken(data.createUserToken.PDToken);
      }
      const userData = await readOneMemberBasic(
        client,
        data.createUserToken.ID
      );
      if (userData?.data?.readOneMember) {
        StoreLogin(
          data.createUserToken.Token,
          data.createUserToken.PDToken,
          {
            ...userData.data.readOneMember,
            ImpersonatorID: currentUser.ID,
          },
          client
        );
      }

      history.replace('/home');
    },
  });

  const previousToken = JSON.parse(localStorage.getItem('previous_token'));

  const previousAiToken = JSON.parse(localStorage.getItem('previous_ai_token'));

  const prevUserID = JSON.parse(
    localStorage.getItem('previous_customer_admin_id')
  );

  // to go back to customer-admin account while viewing a user account
  const goBack = async () => {
    UnsetLocalStorageUserData(true);
    UpdateToken(previousToken);
    if (previousAiToken) {
      UpdateAIToken(previousAiToken);
    }
    const userData = await readOneMemberBasic(client, prevUserID);
    if (userData?.data?.readOneMember) {

      StoreLogin(
        previousToken,
        previousAiToken,
        userData.data.readOneMember,
        client
      );
    }
    UnsetPreviousUserData();

    history.replace('/cx-dashboard/product-dashboard');
  };

  const loginAsUser = (ID) => {
    StoreCurrentTokens();
    UnsetLocalStorageUserData(true);
     // keeping the current org ID around if logging in as a different user can confuse the system
    localStorage.removeItem('currentOrganisationID');
    getToken({
      // this will change to new query that allows an ID to be passed in to get token of any user, using a customer-admin token
      variables: {
        ID: ID,
      },
    });
  };

  return { loginAsUser, goBack, previousToken, loading };
};
