import { saveAs } from 'file-saver';

export const IsAcceptedFile = function (fileType, accepted) {
  if (!accepted) return true;
  if (!fileType) return false;
  // strip spaces
  // a check direct match
  if (accepted.includes(fileType)) return true;
  // check the file extension
  if (StripTypePrefix(accepted).includes(StripTypePrefix(fileType)))
    return true;
  // check image/* etc.
  const typePrefixes = ['image/', 'video/', 'audio/'];
  for (let key in typePrefixes) {
    const prefix = typePrefixes[key];
    if (accepted.includes(prefix + '*') && fileType.includes(prefix))
      return true;
  }
  return false;
};

const StripTypePrefix = function (fileType) {
  return fileType
    .replace('image/', '')
    .replace('video/', '')
    .replace('audio/', '')
    .toLowerCase();
};

export const GetAcceptedFiles = function (mediaType) {
  switch (mediaType) {
    case 'video':
    case 'video2D':
    case 'video3D':
      return '.mp4,video/*';
    case 'image':
      return '.png,.jpg,.jpeg';
    case 'audio':
      return '.wav,.mp3,audio/mpeg';
    default:
      return '';
  }
};

export const escapeCsvCell = function (cellValue) {
  const strValue =
    cellValue === null || cellValue === undefined ? '' : cellValue.toString();
  return `"${strValue.replace(/"/g, '""')}"`;
};

export const SaveAsCSV = function (parsedData, fileName = 'export.csv') {
  var blob = new Blob([parsedData], { type: 'text/csv;charset=utf-8' });
  saveAs(blob, fileName, { autoBom: true });
};

export const GetFileExportName = function (title) {
  const date = new Date();
  const today = date.toDateString();
  const filename = `${title}-${today.replace(/\s+/g, '-')}.csv`.toLowerCase();
  return filename;
};

const units = ['B', 'kB', 'MB', 'GB', 'TB'];
export const formatFilesize = (size) => {
  size = parseInt(size, 10);
  if (size <= 0) {
    return '0B';
  }
  const unitIndex = Math.floor(Math.log10(size) / Math.log10(1024));
  const newSize = Math.round((size / Math.pow(1024, unitIndex)) * 10) / 10;

  return `${newSize}${units[unitIndex]}`;
};

export const SaveFile = function (blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
};

export const SanitizeFileName = function (name) {
  return name.replace(/[^a-z0-9]/gi, '_').toLowerCase();
};
