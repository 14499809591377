import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import NavigationPromptManual from '../../../../../../../components/NavigationPromptManual';
import Button from '../../../form/Button';
import { READ_COURSE, UPDATE_COURSE } from './detailsForm.query';
import { DetailsContainer } from '../../CourseForm.styled';
import { CardTitle, Card } from '../Card';
import UploadImage from '../UploadImage/UploadImage';
import cleanupDetailsData from './helpers/cleanupDetailsData';
import rebuildDetailsData from './helpers/rebuildDetailsData';
import FieldContainer from '../../../form/FieldContainer';
import Resources from '../Resources/Resources';
import { TextInput, Tooltip, Datetime } from '@virtidev/toolbox';
import FeedbackForms from '../../../FeedbackForms/FeedbackForms/FeedbackForms';
import { useCurrentOrganisation } from '../../../../helpers/useCurrentOrganisation';
import { Savebar } from '../../../Page';
import {
  PublishedStatus,
  PublishIcon,
  SaveBarContent,
} from './DetailsForm.styled';
import AccessModal from '../AccessModal/AccessModal';
import AutosaveIndicator from '../../../form/AutosaveIndicator/AutosaveIndicator';
import useAutosave from '../../../../helpers/useAutosave';
import {
  SectionTitle,
  SectionTitleTooltipWrapper,
} from '../../../FeedbackForms/FeedbackForms/FeedbackForms.styled';
import { endOfDay, parseISO, startOfDay } from 'date-fns';
import SelectTagFieldForm from '../../../Tags/SelectTagFieldForm';

export const DetailsForm = ({ courseId }) => {
  const formMethods = useForm();
  const [thumbnailURL, setThumbnailURL] = useState();
  const [mediaTusID, setMediaTusID] = useState();
  const [initialised, setInitialised] = useState(false);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [autosaving, setAutosaving] = useState(false);

  const { register, reset, formState } = formMethods;

  const { data } = useQuery(READ_COURSE, {
    variables: {
      ID: courseId,
    },
  });

  const course = useMemo(() => data?.readOneCourse, [data]);

  useCurrentOrganisation(course?.OrganisationID);

  const [updateCourse] = useMutation(UPDATE_COURSE);

  const handleSubmit = useMemo(() => {
    if (!initialised) {
      return () => null;
    }
    return async (Input) => {
      const result = await updateCourse({
        variables: {
          Input,
        },
        optimisticResponse: {
          updateCourse: rebuildDetailsData(Input),
        },
      });

      setThumbnailURL(result.data.updateCourse.ImageMedia.URL);
    };
  }, [updateCourse, setThumbnailURL, initialised]);

  useAutosave(formMethods, handleSubmit, setAutosaving);

  const hideAccess = useCallback(
    () => setShowAccessModal(false),
    [setShowAccessModal]
  );

  const showAccess = useCallback(
    () => setShowAccessModal(true),
    [setShowAccessModal]
  );

  const { StartDate, DueDate } = course || {};

  useEffect(() => {
    if (course && !initialised) {
      const cleanData = cleanupDetailsData(course);
      reset(cleanData);
      setMediaTusID(course.ImageMedia.TusID);
      setInitialised(true);
    }
  }, [initialised, course, reset, mediaTusID]);

  const [published, dateDescription] = useMemo(() => {
    if (!StartDate) {
      return [false, 'Unpublished: No start date'];
    }
    const start = startOfDay(parseISO(StartDate));

    const startPublished = start <= endOfDay(new Date());
    const startStatus = startPublished ? 'Published' : 'Scheduled';
    if (!DueDate) {
      return [
        startPublished,
        <>
          {startStatus}: <Datetime datetime={StartDate} long noTime />
        </>,
      ];
    }

    return [
      startPublished,
      <>
        {startStatus}: <Datetime datetime={StartDate} long noTime />
        {' - '}
        <Datetime datetime={DueDate} long noTime />
      </>,
    ];
  }, [StartDate, DueDate]);

  return (
    <>
      <AccessModal show={showAccessModal} onHide={hideAccess} course={course} />
      <Savebar>
        <SaveBarContent>
          <AutosaveIndicator loading={autosaving} dirty={formState.isDirty} />
          <PublishedStatus>
            <PublishIcon $published={published} />
            {dateDescription}
            <Button color="turquoise" onClick={showAccess}>
              Manage access
            </Button>
          </PublishedStatus>
        </SaveBarContent>
      </Savebar>
      <FormProvider {...formMethods}>
        <DetailsContainer onSubmit={handleSubmit}>
          <NavigationPromptManual
            when={formState.isDirty}
            message="You have unsaved changes, are you sure you want to leave?"
          />
          <Card>
            <CardTitle>Course Details</CardTitle>
            <FieldContainer>
              <SectionTitle>Cover Image</SectionTitle>
              <UploadImage
                url={thumbnailURL}
                mediaTusID={mediaTusID}
                name="ImageMediaID"
                loading={!initialised}
                updateThumbnail={setThumbnailURL}
              />
            </FieldContainer>
            <FieldContainer>
              <SectionTitle>Tags</SectionTitle>
              <SelectTagFieldForm target={course} type="course" />
            </FieldContainer>
            <FieldContainer>
              <SectionTitle htmlFor="edit-course-title">
                Course Title
              </SectionTitle>
              <TextInput
                id="edit-course-title"
                disabled={!initialised}
                maxLength="255"
                {...register('Title')}
              />
            </FieldContainer>
            <FieldContainer>
              <SectionTitle htmlFor="edit-course-admin-title">
                <SectionTitleTooltipWrapper>
                  Admin Title{' '}
                  <Tooltip icon="help">
                    This title is shown only to admins for identification
                    purposes in the admin area. It is not displayed to learners.
                  </Tooltip>
                </SectionTitleTooltipWrapper>
              </SectionTitle>
              <TextInput
                id="edit-course-admin-title"
                disabled={!initialised}
                maxLength="255"
                {...register('AdminTitle')}
              />
            </FieldContainer>
            <FieldContainer>
              <SectionTitle htmlFor="edit-course-description">
                Description
              </SectionTitle>
              <TextInput
                id="edit-course-description"
                multiline
                disabled={!initialised}
                {...register('Description')}
              />
            </FieldContainer>
          </Card>
          <Card>
            <CardTitle>Feedback Forms</CardTitle>

            <FeedbackForms
              feedbackFormBefore={course?.FeedbackFormBefore}
              feedbackFormAfter={course?.FeedbackFormAfter}
              contentId={courseId}
              type="course"
            />
          </Card>
          <Card>
            <CardTitle>Resources</CardTitle>
            <Resources course={course} />
          </Card>
        </DetailsContainer>
      </FormProvider>
    </>
  );
};

export default DetailsForm;
