import React, {
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes, useTheme } from 'styled-components';
import { withApollo } from '@apollo/client/react/hoc';
import { useReactiveVar } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffectOnlyOnce } from '../utility/CustomHooks';
import {
  flashMessagesVar,
  removeFlashMessage,
  removeCurrentFlashMessages,
  setFlashMessagesAsCurrent,
  showOnboardDialogueVar,
} from '../ApolloReactiveVars';
import { createPortal } from 'react-dom';
import { Icon } from '@virtidev/toolbox';

const StyledFlashMessageHolder = styled.div`
  position: fixed;
  right: var(--flash-message-right);
  top: calc(
    ${(props) =>
      props.showOnboardDialog
        ? 'var(--flash-message-top-with-onboarding)'
        : 'var(--flash-message-top)'}
  );
  width: var(--flash-message-width);
  transition: top 1s;
  max-width: 50vw;
  min-width: 8rem;
  z-index: 2004;
`;

const FlashMessageHolder = (props) => {
  const [portalRef, setPortalRef] = useState();

  useLayoutEffect(() => {
    if (!document.body) {
      return;
    }
    const element = document.createElement('div');

    document.body.append(element);

    element.setAttribute('data-testid', 'FlashMessage');
    setPortalRef(element);

    return () => {
      element?.remove();
    };
  }, []);

  useEffectOnlyOnce(() => {
    setFlashMessagesAsCurrent();
    return () => {
      removeCurrentFlashMessages();
    };
  });

  const flashMessages = useReactiveVar(flashMessagesVar);
  const showOnboardDialog = useReactiveVar(showOnboardDialogueVar);

  // we only want flash messages that are to be activated immediately OR on the next page and where the page has changed
  // these will then automatically be purged from the cache when shown
  const messagesToShow = flashMessages.filter(
    (message) =>
      !message.showOnNextPage ||
      (message.showOnNextPage &&
        window.location.pathname !== message.activatedAtLocation)
  );
  // const messagesToShow = [
  //   { ID: 'a', messageType: 'success', text: 'Success' },
  //   { ID: 'b', messageType: 'warning', text: 'Warning' },
  //   { ID: 'c', messageType: 'error', text: 'Error' },
  //   { ID: 'd', messageType: 'info', text: 'Information' },
  // ];
  if (!portalRef) {
    return null;
  }

  return createPortal(
    <StyledFlashMessageHolder showOnboardDialog={showOnboardDialog}>
      {messagesToShow.map((message, index) => {
        return (
          <FlashMessage
            key={message.ID}
            index={index}
            duration={5000 + Math.min(index, 5) * 1000}
            messageType={message.messageType}
            ID={message.ID}
          >
            {message.text}
          </FlashMessage>
        );
      })}
    </StyledFlashMessageHolder>,
    portalRef
  );
};

FlashMessageHolder.propTypes = {
  client: PropTypes.object.isRequired,
};

const showMessage = keyframes`
  0% {
    margin-left: 4rem;
    opacity: 0.1;
  }

  100% {
    margin-left: 0;
    opacity: 1;
  }
`;

const hideMessage = keyframes`
  0% {
    max-height: 100px;
    opacity: 1;
  }

  100% {
    max-height: 0;
    opacity: 0;
  }
`;

const StyledFlashMessage = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  border-radius: 4px;
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
  padding: 0.65rem 1.5rem;
  font-weight: 500;
  font-size: var(--flash-message-font-size);
  margin-bottom: 0.5rem;
  color: var(--flash-message-text-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 3px solid var(--flash-message-color-default);
  ${(props) =>
    props.messageType === 'success' &&
    css`
      border-bottom: 3px solid var(--flash-message-color-success);
    `}
  ${(props) =>
    props.messageType === 'warning' &&
    css`
      border-bottom: 3px solid var(--flash-message-color-warning);
    `}
  ${(props) =>
    props.messageType === 'error' &&
    css`
      border-bottom: 3px solid var(--flash-message-color-error);
    `}
  animation-name: ${showMessage};
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  word-wrap: break-word;

  ${({ hidden }) =>
    hidden &&
    css`
      animation-name: ${hideMessage};
    `}
`;

const StyledText = styled.div`
  flex: 1;
  width: 150px;
  padding: 0.4rem 1rem;
`;

const StyledCloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 1.3rem;
  color: #717171;
  margin-left: auto;
  text-align: right;
  &:hover {
    color: #616161;
  }
`;

const StyledIconWrapper = styled.div``;

const FlashMessage = (props) => {
  const [timeRemaining, setTimeRemaining] = useState(props.duration);
  const [startTime, setStartTime] = useState(new Date().getTime());
  const [hidden, setHidden] = useState(false);
  const timer = useRef();
  const { color } = useTheme();

  const hide = useCallback(() => {
    setHidden(true);
    setTimeout(() => removeFlashMessage(props.ID), 300);
  }, [props.ID]);

  const resumeTimer = useCallback(() => {
    clearTimeout(timer?.current);
    setStartTime(new Date().getTime());
    timer.current = setTimeout(hide, timeRemaining);
  }, [timeRemaining, hide]);

  const pauseTimer = useCallback(() => {
    clearTimeout(timer?.current);
    const newRemaining = timeRemaining - (new Date().getTime() - startTime);
    setTimeRemaining(newRemaining);
  }, [timeRemaining, startTime]);

  useEffectOnlyOnce(() => {
    resumeTimer();
    return () => {
      clearTimeout(timer?.current);
    };
  });

  const icon = useMemo(() => {
    switch (props.messageType) {
      case 'success': {
        return <Icon icon="success" color={color.success} />;
      }
      case 'warning': {
        return <Icon icon="alert" color={color.warning} />;
      }
      case 'error': {
        return <Icon icon="error" color={color.error} />;
      }
      default: {
        return <Icon icon="information" color={color.blue} />;
      }
    }
  }, [props.messageType, color]);

  return (
    <StyledFlashMessage
      index={props.index}
      hidden={hidden ? '1' : undefined}
      messageType={props.messageType}
      onMouseEnter={pauseTimer}
      onMouseLeave={resumeTimer}
      tabIndex="-1"
    >
      <StyledIconWrapper>{icon}</StyledIconWrapper>
      <StyledText>{props.children}</StyledText>
      <StyledCloseButton onClick={hide}>
        <FontAwesomeIcon icon="times" />
      </StyledCloseButton>
    </StyledFlashMessage>
  );
};

FlashMessage.defaultProps = {
  duration: 5000,
  children: null,
  persistOnHover: true,
};

FlashMessage.propTypes = {
  ID: PropTypes.number.isRequired,
  children: PropTypes.node,
  duration: PropTypes.number,
  index: PropTypes.number.isRequired,
};

export { FlashMessage };
// export default withApollo(withRouter(FlashMessageHolder));
export default withApollo(FlashMessageHolder);
