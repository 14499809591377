import { Card } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const UploadCard = styled(Card)`
  gap: 0;
`;

export const UploadTitle = styled.h2`
  ${({ theme: { spacing, color } }) => css`
    padding: ${spacing.lg};
    border-bottom: 1px solid ${color.blue40};
  `}
`;

export const UploadContent = styled.div`
  ${({ theme: { spacing }, $padding }) => css`
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: ${spacing.md};
    padding: ${$padding ? spacing.lg : 0};
  `}
`;

export const UploadingOverlay = styled.div`
  ${({ theme: { type, color } }) => css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${type === 'light' ? color.white : color.midnight};
  `}
`;
