import { gql } from '@apollo/client';

export const READ_HEATMAP_DATA = gql`
  query readHeatmapData($filter: AnalyticsEventFilterFields) {
    readAnalyticsEvents(filter: $filter, limit: 1000) {
      edges {
        node {
          ID
          HeatmapData
          Type
          Finished
        }
      }
    }
  }
`;

export const READ_SIM_AND_VIDEOS = gql`
  query readSimVideos($SimulationID: ID!) {
    readOneSimulation(
      filter: { ID: { eq: $SimulationID }, Content360: { eq: true } }
    ) {
      ID
      Stereoscopic
      Title
      StartVideoID
      Videos {
        nodes {
          ID
          Content360
          URL
          Title
          EditorURL
          Rotation
          PosterURL
        }
      }
    }
  }
`;
