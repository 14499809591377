import React, { FC } from 'react';
import * as Styled from './AnalyticsSummaryItem.styled';
import ScoreIcon from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/ScoreIcon/ScoreIcon';

/** @type {FC<{ iconName: 'medal' | 'clock' | 'diamond', label: string; value: string;  }>} */
const AnalyticsSummaryItem = ({ label, value, iconName }) => {
  return (
    <Styled.CenteredContent>
      <Styled.Icon>
        <ScoreIcon iconName={iconName} />
      </Styled.Icon>
      <Styled.Value>{value}</Styled.Value>
      <Styled.Label>{label}</Styled.Label>
    </Styled.CenteredContent>
  );
};

export default AnalyticsSummaryItem;
