import React, { FC } from 'react';
import * as Styled from './CoachingSessionReportGenerator.styled';
import { Button } from '@virtidev/toolbox';

/**
 * @typedef {import('../../../../../../models/vh-server/vh-server-coaching-session-report.types').CoachingSessionReport} CoachingSessionReport
 * @typedef {import('../../../../../../models/vh-server/vh-server-coaching-session-report.types').CoachingSessionReportObjective} CoachingSessionReportObjective
 */

/**
 * @type {FC<{
 *   onGenerateClick?: (e: React.ChangeEvent<HTMLButtonElement>) => void;
 *   loading: boolean;
 * }>}
 */
const CoachingSessionReportGenerator = ({ onGenerateClick, loading }) => {
  return (
    <Styled.ReportWrapper>
      <Styled.ReportTextSection>
        <Styled.ReportTitle>Analyze performance</Styled.ReportTitle>
        <Styled.ReportText>
          Get an AI-generated qualitative analysis of how well this session
          covered the objectives and how the user could improve.
        </Styled.ReportText>
      </Styled.ReportTextSection>
      <div>
        <Button
          color="turquoise"
          design="outline"
          loading={loading}
          disabled={loading}
          onClick={onGenerateClick}
        >
          ✨ Generate with AI
        </Button>
      </div>
    </Styled.ReportWrapper>
  );
};

export default CoachingSessionReportGenerator;
