import _ from 'lodash';
import React, { Component } from 'react';
import styled from 'styled-components';
import AframeVideo from '../../../../../../../../components/AframeVideo';
import Heatmap from '../../../../../../../../components/Heatmap';
import {
  StyledVideo,
  StyledVideoArea,
  StyledVideoAreaNoOverflow,
} from './VideoWithHeatmap.styled';
import getConfig from '../../../../../../../../lib/config';

export default class VideoHeatmap extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.aframeVideoRef = React.createRef();
    this.videoAreaRef = React.createRef();

    this.state = {
      currentTime: 0,
      is3DPlayer: getConfig("REACT_APP_DEFAULT_VIDEO_PLAYER") === '3D',
      heatmapActive: true,
      videoDuration: 9999,
      intervalId: null,
      videoWidth: 0,
      videoHeight: 0,
      selectableInteractions: [],
      activeInteraction: null,
    };
  }

  _getVideoAreaRefWidth = () =>
    Math.round(this.videoAreaRef.current.offsetWidth);
  _getVideoAreaRefHeight = () =>
    Math.round((this.videoAreaRef.current.offsetWidth / 16) * 9);
  _getVideoRefHeight = () => {
    if (this.state.is3DPlayer) {
      return this._getVideoRef().parentNode.parentNode.offsetHeight;
    }
    return this._getVideoRef()
      ? Math.round(
          this._getVideoRef().offsetHeight /
            (this.props.simulationData.Stereoscopic ? 2 : 1)
        )
      : 500;
  };

  componentDidMount = () => {
    var intervalId = setInterval(this._updateVideoState, 10);
    this.setState({
      intervalId: intervalId,
      videoWidth: this._getVideoRef() ? this._getVideoAreaRefWidth() : 500,
      videoHeight: this._getVideoRef() ? this._getVideoRefHeight() : 500,
    });
    window.addEventListener('resize', this._updateVideoSizeState);
  };

  componentDidUpdate = () => {
    if (
      this._getVideoRef() &&
      (this._getVideoAreaRefWidth() !== this.state.videoWidth ||
        this._getVideoRefHeight() !==
          Math.round(
            this.state.videoHeight /
              (this.props.simulationData.Stereoscopic ? 2 : 1)
          ))
    ) {
      this._updateVideoSizeState();
    }
  };

  _updateVideoSizeState = _.throttle(() => {
    this.setState({
      videoWidth: this._getVideoAreaRefWidth(),
      videoHeight: this._getVideoRefHeight(),
      videoAreaHeight: this._getVideoAreaRefHeight(),
    });
  }, 100);

  _getVideoRef = () => {
    if (this.state.is3DPlayer && !this.props.simulationData.Stereoscopic) {
      return document.querySelector('#aframe-interactions-video');
    }
    const injectedStereoVid = document.querySelector('[id^="stereo_video_"]');
    if (this.state.is3DPlayer && injectedStereoVid) {
      return injectedStereoVid;
    }
    if (this.videoRef.current) {
      return this.videoRef.current;
    }
    return null;
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this._updateVideoSizeState);

    clearInterval(this.state.intervalId);
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(nextProps, this.props)) {
      return true;
    }
    if (!_.isEqual(nextState, this.state)) {
      return true;
    }
    return false;
  }

  _updateVideoState = () => {
    if (!this._getVideoRef()) return;
    const videoRefTime = this._getVideoRef().currentTime * 1000;
    const videoRefDuration = this._getVideoRef().duration * 1000;

    this.setState({
      currentTime: Math.round(videoRefTime),

      videoDuration: Math.round(videoRefDuration),
    });
  };

  render() {
    return (
      <StyledVideoArea
        ref={this.videoAreaRef}
        onContextMenu={() => null}
        data-testid="video-area"
      >
        <StyledVideoAreaNoOverflow
          videoHeight={this.state.videoHeight}
          stereoscopic={this.props.simulationData.Stereoscopic}
          aspectRatioPercent={
            (this.props.currentVideo.Height / this.props.currentVideo.Width) *
              100 +
            '%'
          }
        >
          <StyledVideo
            key={this.props.currentVideo.ID}
            data-testid="sim-video"
            onClick={() => null}
            ref={this.videoRef}
            controls
            onContextMenu={(e) => e.preventDefault()}
            controlsList="nodownload"
          >
            <source src={this.props.currentVideo.EditorURL} type="video/mp4" />
          </StyledVideo>
        </StyledVideoAreaNoOverflow>
        {/** show heatmap canvas to be used in 2d player */}
        {this.props.heatmapData && (
          <Heatmap
            canvasWidth={this.state.videoWidth || this.props.currentVideo.Width}
            canvasHeight={
              this.state.videoHeight || this.props.currentVideo.Height
            }
            currentTime={this.state.currentTime}
            isOffScreen={this.state.is3DPlayer}
            active={this.state.heatmapActive && !this.state.is3DPlayer}
            id="heatmap2D"
            data={this.props.heatmapData}
            opacity="0.8"
          />
        )}
      </StyledVideoArea>
    );
  }
}
