import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withUser from '../HOCs/WithUser';
import StyledPage, {
  StyledPageContentInner,
  StyledTopArea,
} from '../components/StyledPage';
import '../../node_modules/react-dropzone-component/styles/filepicker.css';
import '../../node_modules/dropzone/dist/min/dropzone.min.css';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import StyledPageHeader from '../styled-components/StyledPageHeader';
import CreateSimForm from '@core/components/Simulation/CreateSimForm/CreateSimForm';
import { flowRight } from 'lodash';
import { withTheme } from 'styled-components';

class SimulationPage extends Component {
  render() {
    if (!this.props.userID) {
      return <RedirectWithPrevState to="/login" />;
    }
    return (
      <StyledPage
        pageKey="new-simulation"
        goBackToLinkText="all simulations"
        goBackToLink="/simulations"
      >
        <StyledPageContentInner style={{ gap: this.props.theme.spacing.lg }}>
          <StyledTopArea>
            <StyledPageHeader>Create New Simulation</StyledPageHeader>
          </StyledTopArea>
          <CreateSimForm />
        </StyledPageContentInner>
      </StyledPage>
    );
  }
}

SimulationPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
};

export default flowRight(withUser, withTheme)(SimulationPage);
