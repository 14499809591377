import React from 'react'
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import 'aframe';
// import '../../node_modules/aframe-immersive-video-component/dist/aframe-immersive-video-component';
import './aframe/StereoscopicVideo';
import _ from 'lodash';

import { FormTypes, GetFormType, GetIconName, GetVideoPreviewIconNameFromInteraction } from '../utility/Interactions';
import Icon from "./icons/Icon";

const StyledWrapper = styled.div`
  width: 100%;
  /* height: 500px; */
  margin-bottom: 1rem;
  position: relative;
  ${props => props.useControls && css`
    margin-bottom: 4rem;
  `};
  user-select: none;
`

const StyledPlayButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  width: 2rem;
  margin-right: 0.2rem;
  position: absolute;
  bottom: -3rem;
`;

window.AFRAME.registerComponent('canvas-updater', {
  dependencies: ['geometry', 'material'],

  tick: function () {
    var el = this.el;
    var material;

    material = el.getObject3D('mesh').material;
    if (!material.map) { return; }
    material.map.needsUpdate = true;
  }
});

// window.AFRAME.registerComponent('draw-canvas', {
//   schema: {
//     sourceCanvas: { type: 'string', default: '' },
//     destinationCanvas: { type: 'string', default: '' },
//     second: { type: 'string', default: '0' },
//   },
//   init: function () {
//     this.el.addEventListener('componentchanged', function (e) {
//       console.log(e);
//       if (e.detail.name === 'second') {
//         const sourceCanvas = document.getElementById(this.data.sourceCanvas);
//         const destinationCanvas = document.getElementById(this.data.destinationCanvas);
//         console.log(sourceCanvas)
//         console.log(destinationCanvas)
//         if (!sourceCanvas || !destinationCanvas) return;
//         // this.ctx = this.canvas.getContext('2d');
//         // this.ctx.fillStyle = "#AFC5FF";
//         // this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
//         // this.ctx.fillStyle = "blue";
//         // this.ctx.fillRect(68, 68, 120, 120);
//         // this.ctx.fillStyle = "white";
//         // this.ctx.font = "36px Georgia";
//         // this.ctx.fillText(this.data.text, 80, 140);
//         const destCtx = destinationCanvas.getContext('2d');
//         //call its drawImage() function passing it the source canvas directly
//         destCtx.drawImage(sourceCanvas, 0, 0);
//       }
//     });
//   },
//   update: function () {
//     console.log('update!!!!!!!')
//     const sourceCanvas = document.getElementById(this.data.sourceCanvas);
//     const destinationCanvas = document.getElementById(this.data.destinationCanvas);
//     console.log(sourceCanvas)
//     console.log(destinationCanvas)
//     if (!sourceCanvas || !destinationCanvas) return;
//     // this.ctx = this.canvas.getContext('2d');
//     // this.ctx.fillStyle = "#AFC5FF";
//     // this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
//     // this.ctx.fillStyle = "blue";
//     // this.ctx.fillRect(68, 68, 120, 120);
//     // this.ctx.fillStyle = "white";
//     // this.ctx.font = "36px Georgia";
//     // this.ctx.fillText(this.data.text, 80, 140);
//     const destCtx = destinationCanvas.getContext('2d');
//     //call its drawImage() function passing it the source canvas directly
//     destCtx.drawImage(sourceCanvas, 0, 0);
//   }
// });
// Component to change to a sequential color on click.
// window.AFRAME.registerComponent('cursor-listener', {
//   init: function () {
//     this.el.addEventListener('click', function (evt) {
//       console.log(evt.detail.intersection.uv);
//     });
//   }
// });
class AframeVideo extends React.Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.state = {
      paused: true,
      videoWidth: 0,
      videoHeight: 0,
      // visibleInteractions: []
    }
    // this._checkAndUpdateVisibleInteractions = _.throttle(this._checkAndUpdateVisibleInteractions, 500);
  }

  componentDidUpdate(prevProps) {
    // two types of ways to use this component: with a single activeInteraction or an array of time-activated interactions
    // if (this.props.activeInteraction && (this.state.visibleInteractions.length === 0 || (this.state.visibleInteractions.length > 0 && this.state.visibleInteractions[0] !== this.props.activeInteraction))) {
    //   this.setState({
    //     visibleInteractions: [this.props.activeInteraction]
    //   })
    // }

    // if (prevProps.activeInteraction && !this.props.activeInteraction) {
    //   // hide when active interaction is reset
    //   this.setState({
    //     visibleInteractions: []
    //   })
    // }
    // else if (this.props.interactions) {
    //   // TODO: vary the behaviour based on type of interaction
    //   this._checkAndUpdateVisibleInteractions(this.props.interactions, this.videoRef.current.currentTime);
    // }
  }

  _checkAndUpdateVisibleInteractions = () => {
    // TODO: deal with pausing with questions separately (include questions for now)
    const timeActiveInteractions = this._getTimeActiveInteractions(this.props.interactions, this.videoRef.current.currentTime);
    // const timeActiveInteractions = this._getTimeActiveInteractions(this.props.interactions.filter(interaction => GetFormType(interaction) !== FormTypes.QUESTION), this.videoRef.current.currentTime);
    // check equality based on interaction IDs to see if any are new and if therefore UI needs updating 
    // console.log(document.querySelector('a-camera').components.camera.camera.rotation)
    // if (!_.isEqual(timeActiveInteractions.map(interaction => interaction.ID), this.state.visibleInteractions.map(interaction => interaction.ID))) {
    //   this.setState({
    //     visibleInteractions: timeActiveInteractions
    //   })
    // }
  }

  _getTimeActiveInteractions(interactions, currentTime) {
    currentTime = Math.round(currentTime * 1000);
    return interactions.filter(interaction => currentTime > interaction.Timestamp && currentTime < interaction.Timestamp + interaction.Duration);
  }

  _togglePlay = e => {
    const videoRef = this._getInternalVideoRef();
    if (!videoRef) return;
    this.setState(
      {
        paused: !videoRef.paused
      },
      () => {
        videoRef.paused
          ? videoRef.play()
          : videoRef.pause();
      }
    );
  };

  componentDidMount() {
    // let thisObj = this;
    // window.AFRAME.registerComponent('rotation-reader', {
    //   tick: function () {
    //     // `this.el` is the element.
    //     // `object3D` is the three.js object.
    //     thisObj.cameraRotation = this.el.getAttribute('rotation');
    //   }
    // });


    this.scene = document.querySelector("a-scene");
    this.vid = document.getElementById("aframe-interactions-video");
    // this.clickSphere = document.getElementById("click-sphere");sphere-with-heatmap
    // heatmap used because for some reason the click sphere blocks the vision of the heatmap despite opacity 0 (heatmap will show up if not transparent though?) 
    this.clickSphere = document.getElementById("sphere-with-heatmap");
    this.clickSphere.addEventListener('click', this._sphereClick);

    if (this.scene.hasLoaded) {
      this._onLoadComplete();
    } else {
      this.scene.addEventListener("loaded", this._onLoadComplete);
    }

    this._updateHeatmapCanvas = this._updateHeatmapCanvas.bind(this);
    this.canvasIntervalID = setInterval(this._updateHeatmapCanvas, 500);
    if (this.props.interactions) {
      this.interactionsIntervalId = setInterval(this._checkAndUpdateVisibleInteractions, 250);
    }


    // var cursorIntervalId = setInterval(this._updateCursorLocation, 250);
    // this.setState({
    //   cursorIntervalId: cursorIntervalId,
    // });

  }

  // copies the placeholder canvas into the canvas that is present here
  _updateHeatmapCanvas = function () {
    if (!this.props.canvasID || !this.props.visibleHeatmap) return;
    const sourceCanvas = document.getElementById(this.props.canvasID);
    const destinationCanvas = document.getElementById('canvas-material');
    if (!sourceCanvas || !destinationCanvas) return;
    const destCtx = destinationCanvas.getContext('2d');
    destCtx.clearRect(0, 0, destinationCanvas.width, destinationCanvas.height);
    // call its drawImage() function passing it the source canvas directly
    destinationCanvas.width = Math.round(sourceCanvas.width);
    destinationCanvas.height = Math.round(sourceCanvas.height);
    destCtx.drawImage(sourceCanvas, 0, 0);
  }

  _sphereClick = (e) => {
    console.log('click video sphere')
    // var wrapperEl = document.createElement('a-entity');
    // var boxEl = document.createElement('a-box');
    // boxEl.setAttribute('color', 'red');
    // wrapperEl.appendChild(boxEl);
    // this.scene.appendChild(wrapperEl);
    // boxEl.setAttribute('position', '8 0 0');
    // wrapperEl.setAttribute('position', '0 1.6 0');
    // second value is the y ring
    // third value is the x ring (yes, confusing )
    // wrapperEl.setAttribute('rotation', `0 ${this.cameraRotation.y + 90} ${this.cameraRotation.x}`);

    // if (this.props.onCameraMove) {
    // const xAndY = this._getXandYFromRotation(`0 ${this.cameraRotation.y + 90} ${this.cameraRotation.x}`);
    // this.props.onCameraMove(xAndY.x, xAndY.y);
    // }
    if (this.props.onClickVideo) {
      // this had to be replaced when inverting the scale of the sphere
      // this.props.onClickVideo(1 - e.detail.intersection.uv.x, e.detail.intersection.uv.y);
      this.props.onClickVideo(e.detail.intersection.uv.x, e.detail.intersection.uv.y);
    }
  }

  componentWillUnmount() {
    if (this.scene) {
      this.scene.removeEventListener('click', this._sphereClick);
      this.scene.removeEventListener("loaded", this._onLoadComplete);
    }
    clearInterval(this.canvasIntervalID);
    clearInterval(this.interactionsIntervalId);
    // clearInterval(this.state.cursorIntervalId);
    // clearInterval(this.state.canvasIntervalID);
  }

  _onLoadComplete = () => {
    if (this.props.onLoadComplete) {
      this.props.onLoadComplete();
    }
    // this.heatmapCanvas = document.querySelector('#' + this.props.canvasID);
    // this.videoAssetNode = document.querySelector('#video-asset');
    // if (this.heatmapCanvas) {
    //   this.heatmapCanvas.setAttribute('crossorigin', 'anonymous');
    // }
    // console.log("document.querySelector('#' + this.props.canvasID)")
    // console.log(document.querySelector('#' + this.props.canvasID))
    // this.aframeAssetsNode = document.querySelector('a-assets');
    // this.aframeAssetsNode.insertBefore(this.heatmapCanvas, this.videoAssetNode);

    // this.vid.play();
    // this.videoSphere.components.material.material.map.image.play();
  }

  _getRotation(x, y, videoStartRotation) {
    let xRot = 360 - (x * 360) - 90 + videoStartRotation;
    let yRot = (y * 180) - 90;
    return `0 ${xRot} ${yRot}`;
  }

  _getVideoSphereRotation(videoStartRotation) {
    return `0 ${270 + videoStartRotation} 0`;
  }

  _getXandYFromRotation = (rotation) => {
    const xRot = rotation.split(' ')[1];
    let x = (-((xRot - 90) / 360) + .5) % 1;
    if (x < 0) {
      x = Math.abs(Math.abs(x) - 1);
    }
    const yRot = rotation.split(' ')[2];
    let y = 1 - (yRot / 180 + .5);
    return { x, y }
  }

  _getIconWidth = formType => {
    if (formType === FormTypes.QUESTION) return "4.35";
    return "0.5";
  }

  _getIconHeight = formType => {
    if (formType === FormTypes.QUESTION) return "2.5";
    return "0.5";
  }

  _getInternalVideoRef = () => {
    if (this.props.stereoscopic) {
      const videoRef = document.querySelector('.stereoscopic_video');
      return videoRef ? videoRef : null;
    }
    return this.videoRef ? this.videoRef.current : null;
  }

  render() {
    let visibleInteractions = [...this.props.selectableInteractions]
    // if for some reason active interaction isn't being shown (e.g. its timestamp has ben edited) then make sure it is visible
    // edit: apparently we don't want to always show active interaction
    // if (this.props.activeInteraction && !visibleInteractions.map(interaction => interaction.ID).includes(this.props.activeInteraction.ID)) {
    //   visibleInteractions.unshift(this.props.activeInteraction);
    // }
    return (
      <StyledWrapper
        useControls={this.props.useControls}
        style={{
          height: this.props.videoAreaHeight
        }}
        data-testid="aframe-video"
        onMouseEnter={() => {
          // ensure nothing is highlighted when interacting with video - buggy due to drag and drop
          if (window.getSelection) { window.getSelection().removeAllRanges(); }
          else if (document.selection) { document.selection.empty(); }
        }}
      >
        <a-scene loading-screen="enabled: false" cursor="rayOrigin: mouse" embedded vr-mode-ui="enabled: false">
          {!this.props.stereoscopic && (
            <a-videosphere
              id="videoSphere"
              src="#aframe-interactions-video"
              rotation={this._getVideoSphereRotation(this.props.videoStartRotation)}
              radius="360"
            >
            </a-videosphere>
          )}
          {/* was used as a click sphere but for some reason visually blocked the heatmap despite transparency and opacity 0 (heatmap will show up if not transparent though?) <a-sphere
            id="click-sphere"
            rotation="0 90 0"
            radius="340"
            cursor-listener
            material={`side:back; transparent:true; opacity:0;`}
          >
          </a-sphere> */}
          {this.props.stereoscopic && (
            <a-entity id="stereoscopicVideoSphere" stereoscopic-video={`controls: false;open:false;type: 360_stereo_vertical; className: stereoscopic_video; source:${this.props.videoURL}`}></a-entity>
            // <a-entity id="stereoscopicVideoSphere" stereoscopic-video="controls: false;open:false;type: 360_stereo_vertical; source:/transcode_low.mp4"></a-entity>
          )}
          <a-sphere
            id="sphere-with-heatmap"
            rotation="0 90 0"
            position="0 0 0"
            radius="350"
            cursor-listener
            canvas-updater
            // invert the sphere to render properly
            scale="1 1 -1"
            // material={`src:url(http://localhost:3000/fake-heatmap.png); scale: 0.5 1; side:back; depthTest: false; flatShading: true; npot:true; transparent:true; opacity:0.8;`}
            material={`src:#canvas-material; side:back; depthTest: false; flatShading: true; npot:true; transparent:true; opacity:${this.props.visibleHeatmap ? 0.8 : 0};`}


          // material={`src:#canvas-material;repeat: 0.75 1; side:back; offset: 22100 1555; depthTest: false; flatShading: true; npot:true; transparent:true; opacity:0.8;`}
          >
          </a-sphere>
          <a-assets>
            <canvas id="canvas-material" crossorigin="anonymous" width="512" height="512"></canvas>
            {this.props.children}
            {this.props.videoURL && (
              <video ref={this.videoRef} src={this.props.videoURL} crossOrigin="anonymous" id="aframe-interactions-video" playsInline webkit-playsinline="true" controlsList="nodownload" onContextMenu={(e) => e.preventDefault()}>
              </video>
            )}
            {/* <img id="icon-hotspot" src="/images/icons/video_editing/icon-hotspot.png" />
            <img id="icon-info" src="/images/icons/video_editing/icon-info.png" /> */}
            <img alt="yellow bar with 'Question' in white, with empty grey box beneath" id="interactions-question" src="/images/icons/video_editing/icon-question-in-video.png" />
            <img alt="pink circle with bar and triangle pointing right" id="redirect" src="/images/icons/redesign/icon-redirect-in-video.png" />
            <img alt="pink circle with two pink triangle arrows pointing right" id="seek" src="/images/icons/redesign/icon-seek-in-video.png" />
            <img alt="pink circle with pink X in the mdidle" id="exit" src="/images/icons/redesign/icon-exit-in-video.png" />
            <img alt="pink circle with a pink spot in the middle" id="interactions-hotspot" src="/images/icons/redesign/icon-interactions-hotspot-in-video.png" />
            <img alt="teal circle with teal i character in the middle" id="interactions-infobox" src="/images/icons/redesign/icon-interactions-infobox-in-video.png" />
            {/* <img id="icon-question" src="/images/icons/redesign/icon-interactions-question-in-video.png" /> */}
          </a-assets>
          {/* <a-entity cursor-listener rotation={this._getRotation(.5, 0.25)}>
            <a-entity geometry="primitive: box" material="color: red" position="8 0 0"></a-entity>
          </a-entity> */}
          {/* <a-entity text={`value: test text;width: 10;`} position="8 0 0"></a-entity> */}
          {visibleInteractions && visibleInteractions.length > 0 && visibleInteractions.map(visibleInteraction => (
            <a-entity
              key={visibleInteraction.ID}
              id={`menu-${visibleInteraction.ID}`}
              rotation={this._getRotation(visibleInteraction.PosX, visibleInteraction.PosY, this.props.videoStartRotation)}
              onClick={() => { if (this.props.setActiveInteraction) { this.props.setActiveInteraction(visibleInteraction) } }}
            >
              <a-image
                width={this._getIconWidth(GetFormType(visibleInteraction))}
                height={this._getIconHeight(GetFormType(visibleInteraction))}
                rotation="0 -90 0"
                position="4 0 0"
                src={`#${GetVideoPreviewIconNameFromInteraction(visibleInteraction)}`}>
              </a-image>
              {/* <a-entity text={`value: ${visibleInteraction.Title};width: 3;align: center;wrapCount:15;`} rotation="0 -90 0" position="4 1.8 0"></a-entity> */}
            </a-entity>
          ))}
          <a-camera rotation-reader position="0 0 0">
            {/* <a-cursor material="color: white; shader: flat"></a-cursor> */}
          </a-camera>
        </a-scene>
        {this.props.useControls && (
          <StyledPlayButton onClick={this._togglePlay}>
            <Icon
              type="video_editing"
              size="small"
              resizeHeight={false}
              resizeWidth={false}
              name={
                this.state.paused ? "play" : "pause"
              }
            />
          </StyledPlayButton>
        )}
      </StyledWrapper>
    )
  }
}

AframeVideo.defaultProps = {
  useControls: false,
  enableAddInteractions: false,
  selectableInteractions: [],
  visibleHeatmap: false,
  videoStartRotation: 0
}

AframeVideo.propTypes = {
  activeInteraction: PropTypes.object,
  setActiveInteraction: PropTypes.func,
  videoAreaHeight: PropTypes.number,
  // onCameraMove: PropTypes.func,
  onClickVideo: PropTypes.func,
  useControls: PropTypes.bool,
  videoURL: PropTypes.string,
  interactions: PropTypes.array,
  enableAddInteractions: PropTypes.bool,
  canvasID: PropTypes.string,
  selectableInteractions: PropTypes.array.isRequired,
  visibleHeatmap: PropTypes.bool.isRequired,
  videoStartRotation: PropTypes.number,
}

export default AframeVideo
// export default React.forwardRef((props, ref) => <AframeVideo ref={ref} {...props}/>);