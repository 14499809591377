import VirtiCircularProgressbar from '@base/components/CircularProgressbar';
import {
  Actions,
  Cancel,
  FileCount,
  Pause,
  ProgressbarWrapper,
  ProgressWrapper,
  UploadDragBoxProgress,
} from '@core/components/MediaUploaders/components/UploadingProgress.styled';
import { useMemo } from 'react';

export const UploadingProgress = ({
  uploading,
  fileNum,
  fileCount,
  completed,
  size,
  progress,
  paused,
  onPause,
  onCancel,
}) => {
  const percent = useMemo(
    () => Math.round((progress.sent / progress.total) * 100) / 100,
    [progress]
  );

  return (
    <ProgressWrapper>
      {uploading && fileCount > 1 && (
        <FileCount data-testid="uploader-file-count-progress">
          Uploading file {fileNum + 1} of {fileCount}
        </FileCount>
      )}
      {completed && (
        <FileCount data-testid="uploader-file-count-complete">
          {fileCount} file
          {fileCount > 1 ? 's' : ''} uploaded – click or drag to upload more.
        </FileCount>
      )}
      {(uploading || completed) && (
        <>
          <ProgressbarWrapper $size={size}>
            <VirtiCircularProgressbar
              percentage={percent * 100}
              uploadBar={true}
            />
          </ProgressbarWrapper>
          {uploading && (
            <>
              <UploadDragBoxProgress
                progress={percent}
                paused={paused}
                totalBytes={progress.total}
              />
              <Actions>
                <Pause data-testid="uploader-pause-button" onClick={onPause}>
                  {paused ? 'Resume' : 'Pause'}
                </Pause>
                <Cancel data-testid="uploader-cancel-button" onClick={onCancel}>
                  Cancel
                </Cancel>
              </Actions>
            </>
          )}
        </>
      )}
    </ProgressWrapper>
  );
};

export default UploadingProgress;
