import { useInterval } from '@base/utility/CustomHooks';
import useGetVHData from '@base/utility/VHHooks/useGetVHData';
import React, { FC, useEffect } from 'react';

/**
 * @typedef {import('../../../../../../models/vh-server/vh-server-coaching-session-report.types').CoachingSessionReport} CoachingSessionReport
 * @typedef {import('../../../../../../models/vh-server/vh-server-coaching-session-report.types').CoachingSessionReportObjective} CoachingSessionReportObjective
 */

/**
 * @type {FC<{assessmentId: number; onReportUpdated: (report: CoachingSessionReport & {objectiveReports: CoachingSessionReportObjective[]} | null )=>void}>}
 */
const CoachingSessionReportPending = ({ assessmentId, onReportUpdated }) => {
  const {
    data: coachingSessionReport,
    loading: coachingSessionReportLoading,
    error: coachingSessionReportError,
    refetch,
  } = useGetVHData(`assessments/${assessmentId}/coaching-session-report`);

  useEffect(() => {
    if (coachingSessionReport?.data?.data?.generatingStatus === 'complete') {
      onReportUpdated(coachingSessionReport?.data?.data);
    }
  }, [coachingSessionReport, onReportUpdated]);

  useInterval(() => {
    refetch();
  }, 5000);
  return <></>;
};

export default CoachingSessionReportPending;
