import { ScorePercentCircleWrapper } from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/ScorePercentCircle/ScorePercentCircle.styled';
import React, { FC } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useTheme } from 'styled-components';

/** @type {FC<{scorePercent: number}>} */
const ScorePercentCircle = ({ scorePercent }) => {
  const { color } = useTheme();
  return (
    <ScorePercentCircleWrapper>
      <CircularProgressbar
        minValue={0}
        maxValue={1}
        value={scorePercent}
        strokeWidth={8}
        styles={buildStyles({
          strokeLinecap: 'butt',
          textSize: '1.5rem',
          pathColor: color.pink,
          trailColor: color.pink30,
        })}
      />
    </ScorePercentCircleWrapper>
  );
};

export default ScorePercentCircle;
