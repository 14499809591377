import { gql } from '@apollo/client';

export const pseudoOrganisationFragment = `
  ID
  Name
  Description
  LogoMediaID
  LogoMedia {
    ID
    TusID
    URL
  }
  AccountID
  VirtualHumanAPI
  DisableLeaderboard
  TotalUsers
  ActiveUsers
  ActiveAdmins
  Licenses
  MAU
  MAUChange
  InviteCodeUser
  InviteCodeAdmin
  VirtualHumanFreeformLimit
  VirtualHumanBranchingLimit
  TUSEndpoint
  CustomerStage
  Partner
  Archived
  Medical
`;

export const EDIT_ORGANISATION = gql`
  mutation updateOrganisation($Input: UpdateOrganisationInput!) {
    updateOrganisation(input: $Input) {
      ${pseudoOrganisationFragment}
    }
  }
`;

export const READ_ORGANISATION = gql`
  query readOneOrganisation($ID: ID!) {
    readOneOrganisation(filter: { ID: { eq: $ID } }) {
      ${pseudoOrganisationFragment}
      Domain
      HubSpotCompanyID
    }
  }
`;

export const READ_ORGANISATION_LICENSES = gql`
  query readOneOrganisation($ID: ID!) {
    readOneOrganisation(filter: { ID: { eq: $ID } }) {
      ID
      Licenses
      TotalUsers
      TotalAdmins
    }
  }
`;

export const READ_ORGANISATIONS = gql`
  query readOrganisations($filter: OrganisationFilterFields) {
    readOrganisations(filter: $filter) {
      edges {
        node {
          ${pseudoOrganisationFragment}
        }
      }
    }
  }
`;

export const READ_ORGANISATIONS_CX = gql`
  query readOrganisations {
    readOrganisations {
      edges {
        node {
          ID
          Name
          TotalUsers
          ActiveUsers
          ActiveAdmins
          TotalAdmins
          Licenses
          MAA
          MAU
          MAUChange
          DAU7Days
          DAU7DaysChange
          Courses(filter: { Status: { eq: "Published" } }) {
            pageInfo {
              totalCount
            }
          }
          Simulations(filter: { Status: { eq: "Published" } }) {
            pageInfo {
              totalCount
            }
          }
        }
      }
    }
  }
`;

export const READ_ORGANISATION_INVITE_CODES = gql`
  query readOrganisationInviteCodes($ID: ID!) {
    readOneOrganisation(filter: { ID: { eq: $ID } }) {
      ID
      InviteCodeUser
      InviteCodeAdmin
    }
  }
`;

export const READ_ORGANISATIONS_SIMPLE = gql`
  query readOrganisationsSimple($Filter: String) {
    readOrganisations(filter: { Name: { contains: $Filter } }) {
      edges {
        node {
          ID
          Name
          LogoURL
          LogoMedia {
            ID
            TusID
          }
          AccountID
          DisableLeaderboard
          VirtualHumanAPI
          Archived
        }
      }
    }
  }
`;
