import React, { useCallback, useMemo, useState } from 'react';
import ScoreOverview from '@core/components/Analytics/VirtualHuman/ScoreOverview/ScoreOverview';
import { Button, Loading, Modal } from '@virtidev/toolbox';
import useGetVHData from '@base/utility/VHHooks/useGetVHData';
import { useQuery } from '@apollo/client';
import { READ_VIRTUAL_HUMAN_FOR_SCORE_OVERVIEW } from '@core/components/Analytics/VirtualHuman/ScoreOverviewAnalyticsAreaModal/ScoreOverviewAnalyticsAreaModal.query';
import { ScoreOverviewModal } from '@core/components/Analytics/VirtualHuman/ScoreOverviewModal/ScoreOverviewModal.styled';
import * as Styled from './ScoreOverviewAnalyticsAreaModal.styled';
import useUser from '@core/helpers/useUser';

const ScoreOverviewAnalyticsAreaModal = ({
  analyticsSubmission,
  SSID,
  vhId,
  onHideModal,
  vhHideScoresFromUser,
  showRegenerateButton,
}) => {
  const { data: assessment, loading: loadingAssessment } = useGetVHData(
    `assessments/${analyticsSubmission.PDAssessmentID}`
  );
  const vhDBVH = useMemo(() => {
    return assessment?.data?.assessment?.patient ?? null;
  }, [assessment]);
  const { data: localeData } = useGetVHData(`languages`);
  const { data: SSVH } = useQuery(READ_VIRTUAL_HUMAN_FOR_SCORE_OVERVIEW, {
    variables: {
      ID: SSID,
    },
  });
  const locale = useMemo(() => {
    return localeData?.data?.find((lang) => lang.id === vhDBVH?.languageId);
  }, [localeData?.data, vhDBVH?.languageId]);

  const {
    data: objectiveGroupsData,
    loading: objectiveGroupsLoading,
    error: objectiveGroupsError,
  } = useGetVHData(`objective-groups?vhId=${vhId}`);
  const {
    data: coachingSessionReport,
    loading: coachingSessionReportLoading,
    error: coachingSessionReportError,
  } = useGetVHData(
    `assessments/${analyticsSubmission.PDAssessmentID}/coaching-session-report`
  );

  const shownHintObjectiveIds = useMemo(() => {
    return (
      assessment?.assessmentLogs?.reduce((carry, log) => {
        if (log.type === 'hint' && log.objectiveId) {
          carry.push(log.objectiveId);
        }
        return carry;
      }, []) ?? []
    );
  }, [assessment]);

  const {
    data: objectivesData,
    loading: objectivesLoading,
    error: objectivesError,
  } = useGetVHData(`objectives?vhId=${vhId}`);

  const { UserType } = useUser();
  const hideScoresFromUser = UserType === 'user' && vhHideScoresFromUser;

  const [sessionReport, setSessionReport] = useState(null);
  const [generationLoading, setGenerationLoading] = useState(false);
  const handleGenerationStarted = useCallback(() => {
    setGenerationLoading(true);
  }, []);
  const handleGenerationCompleted = useCallback((report) => {
    setGenerationLoading(false);
    setSessionReport(report);
  }, []);
  const handleGenerationError = useCallback(() => {
    setGenerationLoading(false);
  }, []);

  return (
    <ScoreOverviewModal show={true} onHide={onHideModal}>
      {(loadingAssessment ||
        objectiveGroupsLoading ||
        objectivesLoading ||
        coachingSessionReportLoading) && (
        <Styled.LoadingWrapper>
          <Loading size="large" />
        </Styled.LoadingWrapper>
      )}
      {objectiveGroupsData?.data?.data?.objectiveGroups &&
      objectivesData?.data?.data?.objectives &&
      assessment?.data?.assessment &&
      vhDBVH &&
      SSVH?.readOneVirtualHuman ? (
        <ScoreOverview
          generationPreviouslyStarted={generationLoading}
          onGenerationStarted={handleGenerationStarted}
          onGenerationCompleted={handleGenerationCompleted}
          onGenerationError={handleGenerationError}
          objectiveGroups={objectiveGroupsData?.data?.data?.objectiveGroups}
          allObjectives={objectivesData?.data?.data?.objectives}
          timeSpent={
            analyticsSubmission?.AnalyticsEvents?.edges?.[0]?.node?.TimeSpent
          }
          diagnosisSuccess={analyticsSubmission.PDDiagnosisScore === 100}
          vhDBVH={vhDBVH}
          ssVH={SSVH?.readOneVirtualHuman}
          hitObjectivesData={assessment?.data.assessment?.hitObjectivesData}
          pointsScored={assessment?.data.assessment?.objectivePointsAchieved}
          pointsPossible={assessment?.data.assessment?.objectivePointsPossible}
          assessmentId={analyticsSubmission.PDAssessmentID}
          hideScores={hideScoresFromUser}
          shownHintObjectiveIds={shownHintObjectiveIds}
          sessionReport={sessionReport ?? coachingSessionReport?.data?.data}
          easyMode={assessment?.data.assessment?.easyMode}
          BCP47Code={locale?.BCP47Code}
          showRegenerateButton={showRegenerateButton}
          footer={
            <Styled.CloseButtonWrapper>
              <Button color="turquoise" onClick={onHideModal}>
                Close
              </Button>
            </Styled.CloseButtonWrapper>
          }
        />
      ) : (
        <>
          {!loadingAssessment && !assessment?.data?.assessment && (
            <div>Couldn't retrieve required data</div>
          )}
        </>
      )}
    </ScoreOverviewModal>
  );
};

export default ScoreOverviewAnalyticsAreaModal;
