import _ from 'lodash';
import {
  Body,
  Container,
  SavebarHolder,
  SimulationsContainer,
} from './CourseForm.styled';
import { CardTitle, Box } from './components/Card';
import { ModuleList } from './components/ModuleList/ModuleList';
import DetailsForm from './components/DetailsForm/DetailsForm';
import { PagePartHolder } from '../Page/PagePartHolder';
import WithOnboardingHandler from '../../../../../HOCs/WithOnboardingHandler';
import PreviewList from './components/PreviewList/PreviewList';

export const EditCourseForm = ({ courseId }) => {
  return (
    <Container>
      <Body>
        <DetailsForm courseId={courseId} />
        <SimulationsContainer horizontal>
          <Box>
            <CardTitle>Course Structure</CardTitle>
            <ModuleList courseId={courseId} />
          </Box>
          <Box>
            <CardTitle>Pick Content</CardTitle>
            <PreviewList courseId={courseId} />
          </Box>
        </SimulationsContainer>
      </Body>
      <PagePartHolder Component={SavebarHolder} outlet="course-savebar" />
    </Container>
  );
};

export default WithOnboardingHandler(EditCourseForm);
