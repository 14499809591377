import React, { useState, useMemo, FC, ReactNode, useCallback } from 'react';
import PropTypes, { Validator } from 'prop-types';
import {
  ReStyledLinkWithPrevState,
  StyledButtonsWrapper,
  StyledCard,
  StyledImageLink,
  StyledLastEdited,
  StyledTitleWrapper,
  StyledLeftCornerIcons,
  StyledRightCornerIcons,
  FixedButton,
  StyledCardLinkTitleWrapper,
  StyledButtonsAreaWrapper,
  StyledCardLinkTitle,
  StyledCardLinkAdminTitle,
  StyledMenuHeader,
  UnpublishedBox,
  ReStyledLink,
  CardTooltip,
} from './DataListCard.styled';
import LoadingIndicator from '../../../../../components/LoadingIndicator';
import DataItems from './components/DataItems/DataItems';
import { getResizeImage } from '../../helpers/imageResizing/getResizeImage';
import {
  ConfirmationModal,
  Datetime,
  FALLBACK,
  Image,
} from '@virtidev/toolbox';
import CardCopyButton from './components/CardCopyButton/CardCopyButton';
import CardDeleteButton from './components/CardDeleteButton/CardDeleteButton';
import CardTooltipTags from './components/CardTooltipTags/CardTooltipTags';
import WithConfirmationBox from '@base/HOCs/WithConfirmationBox';

/**
 * @type {FC<{
 *    ID: string,
 *    cardType: 'simulation' | 'virtual human' | 'course',
 *    dataPoints: { title: string, data: ReactNode }[],
 * }>}
 */
export const DataListCard = ({
  confirm,
  dataPoints,
  onDelete,
  onCopy,
  LastEdited,
  visibleTo,
  published,
  ID,
  editLink,
  ThumbnailURL,
  Title,
  AdminTitle,
  analyticsLink,
  cardType,
  ImageMedia,
  otherIcons,
  otherMenuOptions,
  Tags,
  editTags,
  ...props
}) => {
  const [cardIsLoading, setCardIsLoading] = useState(false);

  const thumbnailUrl = useMemo(() => {
    if (ImageMedia?.TusID) {
      return getResizeImage(ImageMedia.TusID);
    }
    return ThumbnailURL;
  }, [ImageMedia, ThumbnailURL]);

  const tags = useMemo(() => Tags?.nodes || [], [Tags?.nodes]);

  const handleDelete = useCallback(() => {
    confirm(
      async () => {
        setCardIsLoading(true);
        await onDelete();
      },
      `Are you sure you want to delete this ${cardType}?`,
      `This action cannot be undone. All data for this ${cardType} will also be lost.`,
      'new-primary',
      Title
    );
  }, [confirm, onDelete, cardType, Title, setCardIsLoading]);

  const handleCopy = useCallback(() => {
    confirm(
      async () => {
        setCardIsLoading(true);
        await onCopy();
        setCardIsLoading(false);
      },
      `Duplicate this ${cardType}?`,
      `Are you sure you want to create a duplicate of this ${cardType}?`,
      'new-primary'
    );
  }, [confirm, onCopy, cardType, setCardIsLoading]);

  return (
    <StyledCard {...props}>
      <StyledLeftCornerIcons>{otherIcons}</StyledLeftCornerIcons>
      <StyledRightCornerIcons>
        <CardTooltipTags
          cardType={cardType}
          tags={tags}
          targetId={ID}
          editTags={editTags}
        />
        {visibleTo && (
          <CardTooltip
            icon={published ? 'v-bold' : 'clock'}
            iconSize={published ? '1.5rem' : null}
            $disabled={cardIsLoading}
            $unpublished={!published}
          >
            <StyledMenuHeader>Visible to:</StyledMenuHeader>
            {!published && (
              <UnpublishedBox>Unpublished, only admins can see.</UnpublishedBox>
            )}
            {published && !visibleTo.length && (
              <UnpublishedBox>Everyone</UnpublishedBox>
            )}
            {published &&
              visibleTo.map((group) => (
                <ReStyledLink key={group.ID} to={`/groups/${group.ID}`}>
                  {group.Name}
                </ReStyledLink>
              ))}
          </CardTooltip>
        )}
        {(onDelete || onCopy) && (
          <CardTooltip icon="dots" $disabled={cardIsLoading}>
            {onCopy && <CardCopyButton onCopy={handleCopy} />}
            {onDelete && <CardDeleteButton onClick={handleDelete} />}
            {otherMenuOptions}
          </CardTooltip>
        )}
      </StyledRightCornerIcons>
      <StyledImageLink to={editLink} disabled={cardIsLoading || !editLink}>
        {cardIsLoading ? (
          <LoadingIndicator />
        ) : (
          <Image src={thumbnailUrl} cover />
        )}
        {!!LastEdited && (
          <StyledLastEdited>
            <span>Last edited:</span>
            <Datetime datetime={LastEdited} long noTimeDisplay />
          </StyledLastEdited>
        )}
      </StyledImageLink>
      <StyledTitleWrapper>
        <StyledCardLinkTitleWrapper>
          <ReStyledLinkWithPrevState
            to={editLink}
            disabled={cardIsLoading || !editLink}
            title={Title}
          >
            {AdminTitle ? (
              <>
                <StyledCardLinkTitle>{Title || 'Untitled'}</StyledCardLinkTitle>
                <StyledCardLinkAdminTitle>
                  {AdminTitle || ''}
                </StyledCardLinkAdminTitle>
              </>
            ) : (
              <>{Title || 'Untitled'}</>
            )}
          </ReStyledLinkWithPrevState>
        </StyledCardLinkTitleWrapper>
      </StyledTitleWrapper>
      <DataItems items={dataPoints} />
      <StyledButtonsAreaWrapper>
        <StyledButtonsWrapper>
          <FixedButton
            type="link"
            color="primary"
            to={editLink}
            disabled={cardIsLoading || !editLink}
          >
            Edit
          </FixedButton>
          <FixedButton
            type="link"
            color="secondary"
            to={analyticsLink}
            disabled={cardIsLoading || !analyticsLink}
          >
            Analytics
          </FixedButton>
        </StyledButtonsWrapper>
      </StyledButtonsAreaWrapper>
    </StyledCard>
  );
};

DataListCard.propTypes = {
  ID: PropTypes.string.isRequired,
  Title: PropTypes.string.isRequired,
  LastEdited: PropTypes.string,
  editLink: PropTypes.string,
  analyticsLink: PropTypes.string,
  visibleTo: PropTypes.array,
  cardType:
    /** @type {Validator<'simulation' | 'virtual human' | 'course'>} */ (
      PropTypes.oneOf(['simulation', 'virtual human', 'course']).isRequired
    ),
};

export default WithConfirmationBox(DataListCard);
