import styled, { css } from 'styled-components';
import { TabsControlled } from '@core/components/Tabs';

export const InnerScrollbar = css`
  ::-webkit-scrollbar {
    width: 30px;
  }
  ::-webkit-scrollbar-thumb {
    border-left: 22px solid rgba(0, 0, 0, 0);
    background-color: var(--scrollbar-thumb-color);
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--scrollbar-bg-color);
    border-left: 22px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
  /* firefox */
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-bg-color);
  scrollbar-width: thin;
`;

export const AvatarSelector = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(min-content, 2fr) minmax(400px, 1fr);
  gap: 1.5rem;
`;

export const LabelWrapper = styled.label`
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 1;
  label {
    color: white;
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0px;
  }
`;

export const VoiceSelectorWrapper = styled.div`
  position: absolute;
  top: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  ${InnerScrollbar}
`;

export const TabContentWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

export const Tabs = styled(TabsControlled)`
  padding: 0 4px 16px;
`;

export const TabHeaderContent = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: center;
`;