const FormTypes = {
  QUESTION: 0,
  INFO_BOX: 1,
  HOTSPOT: 2,
  BOOKMARK: 3,
};
// alias for FormTypes
export const InteractionTypes = FormTypes;

const GetInteractionTypeIcon = (type) => {
  switch (type) {
    case InteractionTypes.INFO_BOX:
      return 'interactions-infobox';
    case InteractionTypes.HOTSPOT:
      return 'interactions-hotspot';
    case InteractionTypes.BOOKMARK:
      return 'interactions-bookmark';
    default:
      return 'interactions-question';
  }
  // switch (type) {
  //   case InteractionTypes.INFO_BOX:
  //     return "icon-info-invert";
  //   case InteractionTypes.HOTSPOT:
  //     return "icon-hotspot-invert";
  //   default:
  //     return "icon-question-invert";
  // }
};

const GetFormTypeDBText = (type) => {
  switch (type) {
    case InteractionTypes.INFO_BOX:
      return 'InfoBox';
    case InteractionTypes.HOTSPOT:
      return 'Hotspot';
    case InteractionTypes.BOOKMARK:
      return 'Bookmark';
    default:
      return 'Question';
  }
};

const GetFormActionDBTextDefault = (type, openContext) => {
  switch (type) {
    case InteractionTypes.INFO_BOX:
      return 'InfoBox';
    case InteractionTypes.HOTSPOT:
      return 'InfoBox'; // whatever we want as default hotspot
    case InteractionTypes.QUESTION:
      if (openContext) {
        return 'Hotspot';
      } else {
        return null;
      }
    case InteractionTypes.BOOKMARK:
    default:
      return null;
  }
};

const GetFormType = function (interaction) {
  if (interaction.Type === 'InfoBox') {
    return InteractionTypes.INFO_BOX;
  } else if (interaction.Type === 'Hotspot') {
    return InteractionTypes.HOTSPOT;
  } else if (interaction.Type === 'Bookmark') {
    return InteractionTypes.BOOKMARK;
  }
  return InteractionTypes.QUESTION;
};
// alias for GetFormType
export const GetInteractionType = function (interaction) {
  return GetFormType(interaction);
};

const GetIconName = function (type, iconAppearance = '') {
  switch (type) {
    case InteractionTypes.INFO_BOX:
      return 'interactions-infobox' + iconAppearance;
    case InteractionTypes.HOTSPOT:
      return 'interactions-hotspot' + iconAppearance;
    case InteractionTypes.BOOKMARK:
      return 'interactions-bookmark' + iconAppearance;
    default:
      return 'interactions-question' + iconAppearance;
  }
};

export const GetVideoPreviewIconNameFromInteraction = function (
  interaction,
  playerType = '3D'
) {
  if (
    playerType === '2D' &&
    GetInteractionType(interaction) === FormTypes.QUESTION
  ) {
    return 'icon-question-in-video';
  }
  return GetIconNameFromInteraction(interaction);
};

export const GetIconNameFromInteraction = function (
  interaction,
  iconAppearance = ''
) {
  const type = GetFormType(interaction);
  switch (type) {
    case InteractionTypes.INFO_BOX:
      return 'interactions-infobox' + iconAppearance;
    case InteractionTypes.HOTSPOT:
      if (interaction.Action === 'VideoRedirect') {
        return 'redirect';
      }

      if (interaction.Action === 'EndSim') {
        return 'interactions-hotspot';
      }
      return 'interactions-hotspot' + iconAppearance;
    case InteractionTypes.BOOKMARK:
      return 'interactions-bookmark' + iconAppearance;
    default:
      return 'interactions-question' + iconAppearance;
  }
};

export const GetInteractionLabel = function (interaction) {
  return interaction.Label || interaction.Title || '';
};

export const getToolboxIconName = (type) => {
  switch (type) {
    case 'InfoBox':
      return 'information';
    case 'Hotspot':
      return 'hotspot';
    case 'Question':
      return 'help';
    case 'Bookmark':
      return 'bookmark';
    default:
      return '';
  }
};

export {
  GetFormType,
  GetFormTypeDBText,
  FormTypes,
  GetIconName,
  GetFormActionDBTextDefault,
  GetInteractionTypeIcon,
};
