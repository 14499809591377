import styled, { css } from 'styled-components';

export const ScoreOverviewWrapper = styled.div``;
export const ScoreOverviewContent = styled.div`
  padding: 1.4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
export const SummaryText = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 2.375rem;
`;
export const MainSummary = styled.div`
  display: flex;
  ${({ $vhType, $disableDiagnosis }) =>
    ($vhType !== 'medical' || $disableDiagnosis) &&
    css`
      grid-template-columns: 1fr 2fr;
    `}
  ${({ $pointsPossible }) =>
    !$pointsPossible &&
    css`
      grid-template-columns: 1fr;
    `}
  margin-bottom: 2rem;
  align-items: flex-start;
  justify-content: space-around;
`;
export const TimeResult = styled.div``;
export const BoxResultIconText = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  align-items: center;
  column-gap: 0.75rem;
`;
export const BoxResult = styled.div`
  background-color: var(--pd-negative-color-lighter);
  color: var(--pd-negative-color);
  ${({ $success }) =>
    $success &&
    css`
      background-color: var(--pd-positive-color-lighter);
      color: var(--pd-positive-color);
    `}
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 1.25rem;
  row-gap: 0.7rem;
  border-radius: 16px;
  height: 100%;
  font-weight: bold;
  /* border: 1px solid var(--pd-secondary-color-light); */
`;

export const PillDataSection = styled.div`
  display: flex;
  column-gap: 0.5rem;
  justify-content: center;
  align-items: center;
`;

export const PointsText = styled.div`
  display: flex;
  align-items: center;
  padding-right: 4rem;
`;

export const FinishButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SummaryCircleFragment = css`
  ${({ theme }) => css`
    position: relative;
    text-align: center;
    width: 154px;
    height: 154px;
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`;

export const AbsoluteSummaryItem = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;

export const SummaryCircle = styled.div`
  ${SummaryCircleFragment}
`;

export const TurquoiseSummaryCircle = styled.div`
  ${SummaryCircleFragment}
  ${({ theme: { color } }) => css`
    background-color: ${color.turquoise10};
  `}
`;

export const BlueSummaryCircle = styled.div`
  ${SummaryCircleFragment}
  ${({ theme: { color } }) => css`
    background-color: ${color.blue10};
  `}
`;
export const Footer = styled.div`
  ${({ theme: { spacing, color } }) => css`
    border-top: 1px solid ${color.midnight30};
    padding: ${spacing.lg};
  `}
`;