import { useCallback } from 'react';
import Button from '../../../form/Button';
import { useDebouncedSave } from '../../../form/hooks/useDebouncedSave';
import CreateCourseModal from '../CreateCourseModal/CreateCourseModal';
import { useStateUrl } from '../../../../helpers/useStateUrl';
import { usePageScroll } from '../../../Page';
import { usePaginationUrl } from '../../../PaginationControl/helpers/usePaginationUrl';
import { StateUrlSelect } from '../../../form/Select';
import { TextInput } from '@virtidev/toolbox';
import { FieldWrapper } from '../../../form/ActionBar/ActionBar.styled';
import { createdTitle as sortOptions } from '../../../../helpers/sortCollections';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { StateUrlSelectTag } from '../../../form/Select/components/SelectTag/SelectTag';

export const ActionList = ({ onCreateCourse }) => {
  const history = useHistory();
  const location = useLocation();
  const { scrollTop } = usePageScroll();
  const { resetPage } = usePaginationUrl({
    onChange: scrollTop,
  });

  const { value: filter, updateValue: setFilter } = useStateUrl({
    initialValue: '',
    onChange: scrollTop,
  });

  const handleFilterChange = useCallback(
    (value) => {
      resetPage();
      setFilter(value || '');
    },
    [setFilter, resetPage]
  );

  const showCreate = useRouteMatch('/courses/new');

  const openCreate = useCallback(() => {
    history.push({ search: location.search, pathname: '/courses/new' });
  }, [history, location.search]);

  const closeCreate = useCallback(() => {
    history.push({ search: location.search, pathname: '/courses' });
  }, [history, location.search]);

  const debouncedProps = useDebouncedSave(filter, {
    onUpdate: handleFilterChange,
  });

  return (
    <>
      <FieldWrapper>
        <TextInput {...debouncedProps} placeholder="Search..." />
      </FieldWrapper>
      <FieldWrapper>
        <StateUrlSelectTag
          param="tag"
          multi
          onChange={resetPage}
          placeholder="Filter by tag..."
          options={[{ value: '0', label: '(No tags)' }]}
        />
      </FieldWrapper>
      <FieldWrapper>
        <StateUrlSelect
          param="sort"
          options={sortOptions}
          placeholder="Sort..."
          inlineLabel="Sort:"
          initialValue={sortOptions[0].value}
          isSearchable={false}
        />
      </FieldWrapper>
      <Button color="primary" type="button" onClick={openCreate}>
        Create New Course
      </Button>
      <CreateCourseModal
        show={!!showCreate}
        onHide={closeCreate}
        onCreateCourse={onCreateCourse}
      />
    </>
  );
};

export default ActionList;
