import styled, { css, DefaultTheme } from 'styled-components';

export const Container = styled.div`
  max-width: 548px;
`;

export const PlanHeader = styled.div`
  ${
    /** @param {{ theme: DefaultTheme }} p */
    ({ theme: { spacing, color } }) => css`
      border-bottom: 1px solid ${color.blue40};
      padding: ${spacing.md} ${spacing.lg};
      display: flex;
      justify-content: space-between;
      align-items: center;
    `
  }
`;

export const SessionContent = styled.div`
  ${
    /** @param {{ theme: DefaultTheme }} p */
    ({ theme: { spacing } }) => css`
      padding: ${spacing.lg};
      margin-bottom: ${spacing.md};
    `
  }
`;

export const PlanTitle = styled.h2`
  ${
    /** @param {{ theme: DefaultTheme }} p */
    ({ theme: { font } }) => css`
      ${font.h2}
    `
  }
`;

export const ExpiryDate = styled.span`
  ${
    /** @param {{ theme: DefaultTheme }} p */
    ({ theme: { font, color } }) => css`
      ${font.body}
      color: ${color.midnight5};
    `
  }
`;

export const ProgressBarContainer = styled.div`
  ${
    /** @param {{ theme: DefaultTheme }} p */
    ({ theme: { spacing } }) => css`
      margin: ${spacing.sm} 0;
    `
  }
`;

export const SessionInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SessionUsedCount = styled.div`
  ${
    /** @param {{ theme: DefaultTheme }} p */
    ({ theme: { font, color } }) => css`
      ${font.h1}
      color: ${color.midnight5};
    `
  }
`;

export const SessionUsedText = styled.div`
  ${
    /** @param {{ theme: DefaultTheme }} p */
    ({ theme: { font, color } }) => css`
      ${font.body}
      color: ${color.midnight5};
    `
  }
`;

export const ResetInfo = styled.div`
  ${
    /** @param {{ theme: DefaultTheme }}  */
    ({ theme: { font, color } }) => css`
      ${font.body}
      color: ${color.midnight10};
    `
  }
`;
