import styled, { css } from 'styled-components';

export const StyledFlatButton = styled.button`
  ${({ theme: { color, borderRadius } }) => css`
    display: flex;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-color: ${color.blue40};
    cursor: pointer;
    border-radius: ${borderRadius.md};
    text-align: left;
    padding: 1rem 1.5rem;
    padding-right: 4rem;
    border: none;
    color: ${color.midnight};
    gap: 1rem;
    &:hover {
      background-color: ${color.pink30};
    }
  `}
`;
export const FlatButtonIconWrapper = styled.span`
  margin-top: 0.2rem;
`;
export const FlatButtonContent = styled.span`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
`;
export const FlatButtonMain = styled.span`
  ${({ theme: { color, fontWeight } }) => css`
    color: ${color.midnight};
    font-weight: ${fontWeight.bold};
  `}
`;
export const FlatButtonDescription = styled.span`
  ${({ theme: { color } }) => css`
    color: ${color.midnight10};
  `}
`;
