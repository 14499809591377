import { useReactiveVar } from '@apollo/client';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import {
  setSecondarySidebarContentVar,
  setSidebarContractedVar,
  setUserModeVar,
  sidebarContractedVar,
  userModeVar,
} from '@base/ApolloReactiveVars';
import { CanViewAddUsersButton } from '@base/utility/Permissions';
import AdminSidebar from './components/AdminSidebar/AdminSidebar';
import UserSidebar from './components/UserSidebar/UserSidebar';
import {
  Container,
  Content,
  HelpItemWrapper,
  LogoLink,
  LogoSvg,
  Spacer,
  ToggleContainer,
  ToggleExpand,
  ToggleLabel,
} from './Sidebar.styled';

import lightLogo from './images/virti-light.svg';
import lightLogoSmall from './images/virti-light-small.svg';
import BulkInviteModal from '@core/components/Users/BulkInvite/BulkInviteModal';
import LinkItem from '@core/components/Sidebar/components/LinkItem/LinkItem';
import { InviteTeamButtonItem } from '@core/components/Sidebar/components/InviteTeamButtonItem/InviteTeamButtonItem.styled';
import { Icon } from '@virtidev/toolbox';
import SubSidebar from '@core/components/Sidebar/components/SubSidebar/SubSidebar';
import classNames from 'classnames';
import { useClickOutsideCallback } from '@base/utility/EventHooks';
import { debounce } from 'lodash';

export const Sidebar = (props) => {
  const containerRef = useRef(/** @type {HTMLDivElement | null} */ (null));
  const userMode = useReactiveVar(userModeVar);
  const contracted = useReactiveVar(sidebarContractedVar);
  const { pathname } = useLocation();
  const [hovered, setHovered] = useState(false);
  const [showInviteUsersModal, setShowInviteUsersModal] = useState(false);
  const [forceClosed, setForceClosed] = useState(false);

  useEffect(() => {
    const observedUserMode =
      pathname.startsWith('/my-') ||
      pathname.startsWith('/home') ||
      pathname.includes('/my-logs/');
    if (pathname !== '/profile') {
      setUserModeVar(observedUserMode ? 'user' : 'admin');
    }
  }, [pathname]);

  useLayoutEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const activeItem = containerRef.current.querySelector(
      '.sidebar-item.active'
    );

    if (activeItem) {
      activeItem.scrollIntoView();
    }
  }, [pathname, userMode]);

  const handleHideAddUsersModal = useCallback(() => {
    setShowInviteUsersModal(false);
  }, []);

  const handleShowInvite = useCallback(
    (e) => {
      if (e?.preventDefault) {
        e.preventDefault();
      }
      setShowInviteUsersModal(true);
    },
    [setShowInviteUsersModal]
  );

  const closeSubSidebar = useCallback(() => {
    setTimeout(() => setSecondarySidebarContentVar(null), 100);
  }, []);

  const forceCloseSidebar = useMemo(() => {
    const reset = debounce(() => {
      setForceClosed(false);
    }, 300);

    return () => {
      setForceClosed(true);
      reset();
    };
  }, []);

  const toggle = useCallback(() => {
    setSidebarContractedVar(!contracted);

    if (!contracted) {
      setHovered(false);
      forceCloseSidebar();
      setSecondarySidebarContentVar(null);
    }
  }, [contracted, forceCloseSidebar]);

  useClickOutsideCallback(containerRef, () => {
    closeSubSidebar();
  });

  const enterSidebar = useCallback(() => setHovered(true), []);

  const leaveSidebar = useCallback(() => setHovered(false), []);

  return (
    <Container
      ref={containerRef}
      {...props}
      $contracted={contracted}
      className={classNames(
        hovered && 'sidebar-hover',
        contracted && 'sidebar-contracted',
        forceClosed && 'sidebar-force-closed'
      )}
      onMouseLeave={leaveSidebar}
    >
      <Content
        $inviteShow={CanViewAddUsersButton()}
        onMouseMove={enterSidebar}
        onMouseEnter={enterSidebar}
      >
        <LogoLink aria-label="Virti dashboard" to="/" onClick={closeSubSidebar}>
          <LogoSvg src={lightLogo} className="big-logo" />
          <LogoSvg src={lightLogoSmall} className="small-logo" />
        </LogoLink>
        <ToggleContainer>
          <Spacer />
          <ToggleExpand onClick={toggle}>
            <ToggleLabel>
              {contracted ? 'Keep expanded' : 'Collapse'}
            </ToggleLabel>

            <Icon
              icon={contracted ? 'menu-expand' : 'menu-collapse'}
              size="20px"
            />
          </ToggleExpand>
        </ToggleContainer>
        {userMode === 'admin' && (
          <AdminSidebar setShowInviteUsersModal={setShowInviteUsersModal} />
        )}
        {userMode === 'user' && (
          <UserSidebar setShowInviteUsersModal={setShowInviteUsersModal} />
        )}
        <HelpItemWrapper>
          {CanViewAddUsersButton() && (
            <>
              <InviteTeamButtonItem icon="plus" onClick={handleShowInvite}>
                Invite Teammates
              </InviteTeamButtonItem>
              <BulkInviteModal
                show={showInviteUsersModal}
                onHide={handleHideAddUsersModal}
              />
            </>
          )}
          <LinkItem icon="help" to={'/help'}>
            Help
          </LinkItem>
        </HelpItemWrapper>
      </Content>

      <SubSidebar />
    </Container>
  );
};

export default Sidebar;
