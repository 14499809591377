import { gql } from '@apollo/client';

export const READ_ORGANISATION_SESSION_DASHBOARD = gql`
  query readOneOrganisation(
    $OrganisationID: ID!
  ) {
    readOneOrganisation(
      filter: { ID: { eq: $OrganisationID } }
    ) {
      ID
      MonthlySessions
      PlatformLicense {
        Title
        Type
        ExpiryDate
        SessionsPerMonth        
      }
    }
  }
`;