import PropTypes from 'prop-types';
import { Button, Icon, Loading, TextInput } from '@virtidev/toolbox';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { READ_VIDEOS } from './AssetSelectModal.query';
import useUser from '../../helpers/useUser';
import { useDebouncedSave } from '../form/hooks/useDebouncedSave';
import { usePageScroll } from '../Page';
import PaginationControl from '../PaginationControl';
import {
  AssetContainer,
  FilterContainer,
  FooterLink,
  ModalFooter,
  ResizedModal,
  UploadNotice,
} from './AssetSelectModal.styled';
import VideoCard from './components/VideoCard/VideoCard';

export const VideoSelectModal = ({
  visible,
  mediaType,
  organisationID,
  hideModal,
  onConfirm,
  disabledVideoIDs,
  loading,
  closeWhenMutationComplete,
  multiSelect,
  adding,
  title,
}) => {
  const prevLoading = useRef(loading);
  const { scrollTop } = usePageScroll();
  const { ID, UserType, OrganisationID } = useUser();
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const pageSize = 20;
  const [selectedVideos, setSelectedVideos] = useState([]);

  const handlePageChange = useCallback(
    (newPage) => {
      setPage(newPage);
      scrollTop();
    },
    [setPage, scrollTop]
  );

  const handleFilterChange = useCallback(
    (value) => {
      handlePageChange(1);
      setFilter(value || '');
    },
    [setFilter, handlePageChange]
  );

  const debouncedProps = useDebouncedSave(filter, {
    onUpdate: handleFilterChange,
  });

  const { data, loading: videosLoading } = useQuery(READ_VIDEOS, {
    variables: {
      limit: pageSize,
      offset: (page - 1) * pageSize,
      sort: { Created: 'DESC', ID: 'DESC' },
      filter: {
        Title: { contains: filter },
        Organisation: { ID: { eq: organisationID || OrganisationID } },
        ...(mediaType !== 'video'
          ? { Content360: { eq: mediaType === 'video3D' } }
          : {}),
      },
    },
    skip: !visible,
  });

  useEffect(() => {
    return () => {
      if (visible) {
        setSelectedVideos([]);
      }
    };
  }, [visible]);

  useEffect(() => {
    if (closeWhenMutationComplete && prevLoading.current && !loading) {
      hideModal();
    }
    prevLoading.current = loading;
  }, [closeWhenMutationComplete, loading, hideModal]);

  const videos = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.readVideoMedias.edges.map(({ node }) => node);
  }, [data]);

  const handleSelect = useCallback(
    (id) => {
      setSelectedVideos((currentVideos) => {
        if (!multiSelect) {
          const video = videos.find(({ ID }) => ID === id);
          return [video];
        }

        const newVideos = currentVideos.filter(({ ID }) => ID !== id);
        if (newVideos.length !== currentVideos.length) {
          return newVideos;
        }

        const video = videos.find(({ ID }) => ID === id);
        return [...currentVideos, video];
      });
    },
    [videos, setSelectedVideos, multiSelect]
  );

  const handleConfirm = useCallback(() => {
    onConfirm(multiSelect ? selectedVideos : selectedVideos[0]);
  }, [onConfirm, selectedVideos, multiSelect]);

  const buttonLabel = useMemo(() => {
    if (!multiSelect) {
      return adding ? 'Add' : 'Select';
    }

    const suffix = selectedVideos.length ? ` (${selectedVideos.length})` : '';

    return adding ? `Add${suffix}` : `Select${suffix}`;
  }, [multiSelect, selectedVideos, adding]);

  const noVideoMessage = useMemo(() => {
    if (videos.length > 0 || videosLoading) {
      return null;
    }

    if (!filter) {
      return 'No media found';
    }
    return `No media found with your search "${filter}".`;
  }, [videos, videosLoading, filter]);

  return (
    <ResizedModal
      show={visible}
      title={title}
      render={() => (
        <>
          <FilterContainer>
            <TextInput {...debouncedProps} placeholder="Search..." />
          </FilterContainer>
          {videosLoading && <Loading size="large" />}
          {noVideoMessage}
          <AssetContainer>
            {videos.map((video) => (
              <VideoCard
                key={video.ID}
                video={video}
                onSelect={handleSelect}
                disabled={disabledVideoIDs.includes(video.ID)}
                selected={selectedVideos.find(({ ID }) => video.ID === ID)}
              />
            ))}
          </AssetContainer>
          <PaginationControl
            onPageChange={handlePageChange}
            page={page}
            pageSize={pageSize}
            total={data?.readVideoMedias?.pageInfo?.totalCount}
          />
        </>
      )}
      footerRender={() => (
        <ModalFooter>
          <UploadNotice>
            Upload new media in{' '}
            <FooterLink to="/media" target="_blank">
              Media library <Icon icon="expand" size="small" />
            </FooterLink>
          </UploadNotice>
          <Button
            color="turquoise"
            disabled={!selectedVideos.length || loading}
            loading={loading}
            onClick={handleConfirm}
          >
            {buttonLabel}
          </Button>
        </ModalFooter>
      )}
      onHide={hideModal}
    />
  );
};

VideoSelectModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  mediaType: PropTypes.oneOf(['video2D', 'video3D', 'video']),
  organisationID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  disabledVideoIDs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  loading: PropTypes.bool,
  closeWhenMutationComplete: PropTypes.bool,
  title: PropTypes.string,
};

VideoSelectModal.defaultProps = {
  mediaType: 'video',
  organisationID: null,
  disabledVideoIDs: [],
  loading: false,
  closeWhenMutationComplete: false,
  title: 'Select existing media',
};

export default VideoSelectModal;
