import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import withUser from '../HOCs/WithUser';
import StyledPage, {
  StyledPageContentInnerNoFlex,
  FlexTopArea,
} from '../components/StyledPage';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import StyledPageHeader from '../styled-components/StyledPageHeader';
import { CanViewUsersList } from '../utility/Permissions';
import UserExportButton from '../components/buttons/UserExportButton';
import LicenseCount from '../components/LicenseCount';
import styled from 'styled-components';
import WithOnboardingHandler from '../HOCs/WithOnboardingHandler';
import UserTable from '../apps/core/src/components/Users/UserTable/UserTable';
import BulkInviteModal from '../apps/core/src/components/Users/BulkInvite/BulkInviteModal';
import useUser from '@core/helpers/useUser';
import { Button } from '@virtidev/toolbox';

const StyledHeaderButtons = styled.div`
  display: flex !important;
  justify-content: flex-end;
`;

const UsersPage = () => {
  const { ID: userID, OrganisationID: userOrganisationID } = useUser();
  const [showInviteModal, setShowInviteModal] = useState(false);

  const handleClickAddUsers = useCallback(() => {
    setShowInviteModal(true);
  }, []);
  const handleHideModal = useCallback(() => {
    setShowInviteModal(false);
  }, []);

  if (!userID) {
    return <RedirectWithPrevState to="/login" />;
  }
  if (!CanViewUsersList()) {
    return <RedirectWithPrevState to="/simulations" />;
  }

  return (
    <StyledPage pageKey="users">
      <StyledPageContentInnerNoFlex>
        <StyledPageHeader>Users</StyledPageHeader>
        <FlexTopArea>
          <StyledPageHeader style={{ flex: 1, minWidth: '150px' }}>
            <LicenseCount organisationID={userOrganisationID} />
          </StyledPageHeader>
          <StyledHeaderButtons style={{ minWidth: '70%' }}>
            <Button color="secondary" onClick={handleClickAddUsers}>
              Add New Users
            </Button>
            <BulkInviteModal onHide={handleHideModal} show={showInviteModal} />
            <UserExportButton />
          </StyledHeaderButtons>
        </FlexTopArea>
        <UserTable />
      </StyledPageContentInnerNoFlex>
    </StyledPage>
  );
};

UsersPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
};

export default _.flowRight(WithOnboardingHandler, withUser)(UsersPage);
