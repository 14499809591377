import { useMutation, useQuery } from '@apollo/client';
import { produce } from 'immer';
import { useMemo } from 'react';
import useUser from '../useUser';
import {
  READ_MEMBER_ONBOARD_PROGRESS,
  UPDATE_MEMBER_WELCOME_PROGRESS,
} from './useWelcomeFlow.query';

export const useWelcomeFlow = (firstCourseComplete, activeModule) => {
  const { ID, SignupMethod } = useUser();

  const { data } = useQuery(READ_MEMBER_ONBOARD_PROGRESS, {
    variables: {
      ID,
    },
  });

  const [updateMember] = useMutation(UPDATE_MEMBER_WELCOME_PROGRESS);

  const welcomeProgressTemplate = useMemo(() => {
    if (data?.readOneMember?.CompletedSimulations?.pageInfo?.totalCount > 0) {
      return {
        welcomeVideoSkipped: false,
        seenFirstTour: true,
        seenSecondTour: false,
        seenVHTour: false,
      };
    } else {
      return {
        welcomeVideoSkipped: true,
        seenFirstTour: true,
        seenSecondTour: false,
        seenVHTour: false,
      };
    }
  }, [data?.readOneMember?.CompletedSimulations]);

  if (data) {
    const method = SignupMethod || 'public';
    if (method === 'public' && data?.readOneMember?.OnboardProgress) {
      const OnboardProgress = JSON.parse(data?.readOneMember?.OnboardProgress);
      if (OnboardProgress) {
        // the first time we set welcomeProgress will be when the first tour starts, so anyone with welcomeProgress will have seenFirstTour as true
        if (!OnboardProgress.welcomeProgress) {
          const updatedProgress = produce(OnboardProgress, (state) => {
            state.welcomeProgress = welcomeProgressTemplate;
          });

          const updatedProgressJSON = JSON.stringify(updatedProgress);

          updateMember({
            variables: {
              Input: {
                ID,
                OnboardProgress: updatedProgressJSON,
              },
            },
          });

          //start first tour
          //set welcomeProgress data structure
        } else {
          if (
            OnboardProgress.welcomeProgress.seenFirstTour &&
            !OnboardProgress.welcomeProgress.seenVHTour &&
            !firstCourseComplete &&
            activeModule === 1
          ) {
            // assuming that the second module is virtual human
            const updatedProgress = produce(OnboardProgress, (state) => {
              state.welcomeProgress.seenVHTour = true;
            });

            const updatedProgressJSON = JSON.stringify(updatedProgress);

            updateMember({
              variables: {
                Input: {
                  ID,
                  OnboardProgress: updatedProgressJSON,
                },
              },
            });
          }

          if (
            OnboardProgress.welcomeProgress.seenFirstTour &&
            firstCourseComplete &&
            !OnboardProgress.welcomeProgress.seenSecondTour
          ) {
            const updatedProgress = produce(OnboardProgress, (state) => {
              state.welcomeProgress.seenSecondTour = true;
            });

            const updatedProgressJSON = JSON.stringify(updatedProgress);

            updateMember({
              variables: {
                Input: {
                  ID,
                  OnboardProgress: updatedProgressJSON,
                },
              },
            });
          }
        }
      }
    }
  }
};
