import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import styled from 'styled-components';
import _ from 'lodash';

import { EDIT_SIMULATION } from '../queries/SimulationQueries';
import SaveBar from '../components/SaveBar';
import StyledLabel from '../styled-components/StyledLabel';
import withFlashMessaging from '../HOCs/WithFlashMessaging';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import StyledSelect from '../styled-components/StyledSelect';
import GroupCheckboxesForSimulation from './GroupCheckboxesForSimulation';
import ConfirmationModal from '../components/modals/ConfirmationModal';
import TextEditorArea from '../components/TextEditorArea';
import { Checkbox } from '../styled-components/StyledCheckboxes';
import NavigationPrompt from '../components/NavigationPromptManual';
import Breakpoints from '../themes/Breakpoints';
import TranscodingIcon from '../components/icons/TranscodingIcon';
import {
  CanPublishSimulations,
  CanReadyForPublishSimulations,
  CanEditSimulationGroups,
  CanEditSimulationFeatured,
  CanEditSimulationHiddenInList,
} from '../utility/Permissions';
import WithOnboardingHandler from '../HOCs/WithOnboardingHandler';
import MediaUploader from '../apps/core/src/components/MediaUploaders/MediaUploader';
import getConfig from '../lib/config';
import FeedbackForms from '../apps/core/src/components/FeedbackForms/FeedbackForms/FeedbackForms';
import {
  TextInput,
  Tooltip,
  Button,
  AutosaveIndicator,
} from '@virtidev/toolbox';
import tracker from '../apps/core/src/helpers/tracker';
import SelectTagFieldForm from '../apps/core/src/components/Tags/SelectTagFieldForm';
import HeaderPill from '@core/components/Simulation/Header/HeaderPill/HeaderPill';
import LastEditedIndicator from '@core/components/Simulation/LastEditedIndicator/LastEditedIndicator';

const SavebarContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const SavebarStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const StyledEditFormWrapper = styled.div`
  display: grid;
  grid-template-columns: 19rem auto;
  grid-template-areas: 'groupList metaForm';
  /* grid-template-areas:
    "groupList video"
    "metaForm metaForm"; */
  grid-column-gap: 2rem;
`;

const StyledGroupListArea = styled.div`
  grid-area: groupList;
  background-color: none;
`;

const StyledGroupListAreaContent = styled.div`
  border-radius: var(--card-border-radius);
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
`;

const StyledMetaFormArea = styled.div`
  grid-area: metaForm;
  position: relative;
  /* margin-top: 2rem; */
  border-radius: var(--card-border-radius);
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: ${Breakpoints.large}) {
    grid-template-columns: 1fr;
  }
`;

const StyledDescriptionEditor = styled.div`
  margin-top: 2rem;
`;

const FeedbackFormSelectorWrapper = styled.div`
  max-width: 450px;
`;

const StyledFieldContainer = styled.div`
  padding: 1rem;
  padding-bottom: 0;
`;
const StyledPublishedWrapper = styled.div`
  padding: 1rem;
`;

const StyledUploadImageWrapper = styled.div`
  max-width: 320px;
  margin-bottom: 2rem;
`;

const StyledLabelWithTooltip = styled(StyledLabel)`
  display: flex;
  justify-content: space-between;
`;

class SimulationEditForm extends Component {
  editableValues = [
    { dbName: 'AdminTitle', label: 'Admin Title' },
    { dbName: 'Description', label: 'Description' },
  ];

  constructor(props) {
    super(props);
    // set state to initial data values
    this.state = this.editableValues.reduce(
      (carry, editableValue) => {
        carry[editableValue.dbName] = props.dataObject
          ? props.dataObject[editableValue.dbName] || ''
          : '';
        return carry;
      },
      {
        submittingForm: false,
        removedImg: false,
        redirectTo: null,
        remountKey: 1,
        uploadedImgFile: props.dataObject.ImageMedia.URL,
        uploadedTusID: props.dataObject.ImageMedia.TusID,
        Status: props.dataObject.Status || 'Unpublished',
        Featured: props.dataObject.Featured,
        CoursesOnly: props.dataObject.CoursesOnly,
        PublicAccess: props.dataObject.PublicAccess,
        ViewingPosition: props.dataObject.ViewingPosition || 'Standing',
        HideScoresFromUsers: props.dataObject.HideScoresFromUsers,
      }
    );
  }

  componentDidMount() {
    this.setState({
      prevFormData: this._getFormData(),
    });
  }

  _getFormData = () => {
    const input = this.editableValues.reduce((carry, editableValue) => {
      carry[editableValue.dbName] = this.state[editableValue.dbName];
      return carry;
    }, {});
    let status = ['Published', 'Unpublished', 'Ready'].includes(
      this.state.Status
    )
      ? this.state.Status
      : null;
    return _.cloneDeep({
      ...input,
      ID: this.props.dataObject.ID,
      ImageMediaID: this.state.ImageMediaID,
      Featured: this.state.Featured,
      Status: status,
      CoursesOnly: this.state.CoursesOnly,
      PublicAccess: this.state.PublicAccess,
      HideScoresFromUsers: this.state.HideScoresFromUsers,
      ViewingPosition: this.state.ViewingPosition,
    });
  };

  _submitForm = (e, editSimulationMutation) => {
    e.preventDefault();

    const formData = this._getFormData();

    // set to redirect once mutation is complete
    this.setState({
      submittingForm: true,
    });
    editSimulationMutation({
      variables: {
        ID: this.props.dataObject.ID,
        Input: formData,
      },
    });
  };

  _onPublishedChange = (e) => {
    this.setState({
      Status: e.target.value,
    });
  };

  _textInputChange = (newValue, editableValueObject) => {
    let updated = {};
    updated[editableValueObject.dbName] = newValue;
    this.setState(updated);
  };

  _getImgSrc = (dataObject) => {
    if (this.state.removedImg) return null;

    return this.state.uploadedImgFile || dataObject?.ImageMedia?.URL || null;
  };

  render() {
    const currentFormDataDirty = !_.isEqual(
      this.state.prevFormData,
      this._getFormData()
    );
    return (
      <>
        <NavigationPrompt
          when={currentFormDataDirty}
          message="You have unsaved changes, are you sure you want to leave?"
        >
          {({ isActive, onCancel, onConfirm }) =>
            isActive && (
              <ConfirmationModal onCancel={onCancel} onConfirm={onConfirm} />
            )
          }
        </NavigationPrompt>
        {this.state.redirectTo && (
          <RedirectWithPrevState to={this.state.redirectTo} />
        )}
        <Mutation
          onCompleted={(data) => {
            this.props.addFlashMessage('Updated simulation', 'success');
            this.props.updateProgress('edit_simulation');
            tracker.track('simulation_edited', {
              simulation_id: data.updateSimulation.ID,
            });
            this.setState({
              submittingForm: false,
              prevFormData: this._getFormData(),
            });
          }}
          onError={() => {
            this.props.addFlashMessage('Failed to update simulation', 'error');
            this.setState({
              submittingForm: false,
            });
          }}
          mutation={EDIT_SIMULATION}
        >
          {
            /**
             * @param {Function} editSimulation
             * @param {{ error?: any, loading?: boolean }} result
             */
            (editSimulation, { error, loading }) => (
              <StyledEditFormWrapper>
                <StyledGroupListArea>
                  <StyledGroupListAreaContent>
                    <StyledFieldContainer>
                      <StyledLabel>Transcoding:</StyledLabel>
                      <TranscodingIcon
                        includeText={true}
                        transcodingStatus={this.props.transcodingStatus}
                        // status={this.props.dataObject.Status}
                      />
                    </StyledFieldContainer>
                    <StyledFieldContainer>
                      <StyledLabelWithTooltip htmlFor="admin-title">
                        Admin Title:{' '}
                        <Tooltip icon="help">
                          This title is shown only to admins for identification
                          purposes in the admin area. It is not displayed to
                          learners.
                        </Tooltip>
                      </StyledLabelWithTooltip>
                      <TextInput
                        name="admin-title"
                        id="admin-title"
                        maxLength="255"
                        onChange={(e) => {
                          this.setState({ AdminTitle: e.target.value });
                        }}
                        value={this.state.AdminTitle}
                      />
                    </StyledFieldContainer>
                    <StyledPublishedWrapper>
                      <StyledLabel htmlFor="published-status">
                        Status:
                      </StyledLabel>
                      <StyledSelect
                        value={this.state.Status}
                        onChange={this._onPublishedChange}
                        id="published-status"
                        disabled={
                          !CanPublishSimulations() &&
                          this.state.Status === 'Published'
                        }
                      >
                        <option value="Unpublished">Unpublished</option>
                        {CanReadyForPublishSimulations() && (
                          <option value="Ready">Ready to Publish</option>
                        )}
                        {(CanPublishSimulations() ||
                          this.state.Status === 'Published') && (
                          <option value="Published">Published</option>
                        )}
                      </StyledSelect>
                      {CanEditSimulationFeatured() && (
                        <Checkbox
                          dataTestId="sim-featured"
                          htmlID="sim-featured"
                          label="Featured"
                          ID="sim-featured"
                          checked={this.state.Featured}
                          onChange={(e) =>
                            this.setState({ Featured: e.target.checked })
                          }
                          name="sim-featured"
                          // disabled={this.props.isMutating}
                        />
                      )}
                      {CanEditSimulationHiddenInList() && (
                        <Checkbox
                          dataTestId="sim-list-hidden"
                          htmlID="sim-list-hidden"
                          label="Hide in Simulation Listing"
                          tooltip="Only show this simulation as part of courses in the app"
                          ID="sim-list-hidden"
                          checked={this.state.CoursesOnly}
                          onChange={(e) =>
                            this.setState({ CoursesOnly: e.target.checked })
                          }
                          name="sim-list-hidden"
                        />
                      )}
                      <Checkbox
                        dataTestId="sim-sitting-viewing-position"
                        htmlID="sim-sitting-viewing-position"
                        label="Sitting POV (VR)"
                        tooltip="The simulation is intended to be viewed from a sitting (rather than standing) position."
                        ID="sim-sitting-viewing-position"
                        checked={this.state.ViewingPosition === 'Sitting'}
                        onChange={(e) =>
                          this.setState({
                            ViewingPosition: e.target.checked
                              ? 'Sitting'
                              : 'Standing',
                          })
                        }
                        name="sim-sitting-viewing-position"
                      />
                      <Checkbox
                        dataTestId="sim-hide-score"
                        htmlID="sim-hide-score"
                        label="Hide Scores from Learners"
                        tooltip="Hides the score for this Simulation from Learner in the Interactive Video and Logs, an Admin will still see the scores."
                        ID="sim-hide-score"
                        checked={this.state.HideScoresFromUsers}
                        onChange={(e) =>
                          this.setState({
                            HideScoresFromUsers: e.target.checked,
                          })
                        }
                        name="sim-hide-score"
                      />
                    </StyledPublishedWrapper>
                    {CanEditSimulationGroups() && (
                      <GroupCheckboxesForSimulation
                        className="edit-simulation-access"
                        dataObject={this.props.dataObject}
                        simulationID={this.props.dataObject.ID}
                        checkedGroupIDs={
                          this.props.dataObject
                            ? this.props.dataObject.OrganisationGroups.nodes.map(
                                (group) => group.ID
                              )
                            : []
                        }
                      />
                    )}
                  </StyledGroupListAreaContent>
                </StyledGroupListArea>
                <StyledMetaFormArea>
                  <div>
                    <StyledUploadImageWrapper id="thumbnail-dropzone">
                      <StyledLabel>Thumbnail:</StyledLabel>
                      <div>
                        <MediaUploader
                          key={this.state.remountKey}
                          mediaType="image"
                          mediaTusID={this.state.uploadedTusID}
                          src={this._getImgSrc(this.props.dataObject)}
                          onSuccess={({ ID, URL, TusID }) => {
                            this.setState({
                              ImageMediaID: ID,
                              uploadedImgFile: URL,
                              uploadedTusID: TusID,
                              removedImg: false,
                              remountKey: this.state.remountKey + 1,
                            });
                          }}
                          onRemove={() =>
                            this.setState({
                              ImageMediaID: '0',
                              uploadedImgFile: null,
                              uploadedTusID: null,
                              removedImg: true,
                              remountKey: this.state.remountKey + 1,
                            })
                          }
                        />
                      </div>
                    </StyledUploadImageWrapper>
                    <StyledLabel>Tags:</StyledLabel>
                    <SelectTagFieldForm
                      target={this.props.dataObject}
                      type="simulation"
                    />
                    <StyledDescriptionEditor>
                      <TextEditorArea
                        name="description"
                        id="description"
                        onChange={(newValue) => {
                          this.setState({ Description: newValue });
                        }}
                        label="Description/Background:"
                        value={this.state.Description}
                      />
                    </StyledDescriptionEditor>
                  </div>
                  <FeedbackFormSelectorWrapper>
                    <FeedbackForms
                      contentId={this.props.dataObject.ID}
                      feedbackFormBefore={
                        this.props.dataObject.FeedbackFormBefore
                      }
                      feedbackFormAfter={
                        this.props.dataObject.FeedbackFormAfter
                      }
                      type="simulation"
                    />
                  </FeedbackFormSelectorWrapper>
                </StyledMetaFormArea>
                <SaveBar>
                  <Button
                    disabled={!currentFormDataDirty}
                    loading={this.state.submittingForm}
                    color="turquoise"
                    onClick={(e) =>
                      this._submitForm(
                        e,
                        editSimulation,
                        '/simulations/' +
                          this.props.dataObject.ID +
                          '/interactions'
                      )
                    }
                  >
                    Save
                  </Button>
                </SaveBar>
              </StyledEditFormWrapper>
            )
          }
        </Mutation>
      </>
    );
  }
}

SimulationEditForm.propTypes = {
  dataObject: PropTypes.object,
  addFlashMessage: PropTypes.func,
  transcodingStatus: PropTypes.string,
};

export default _.flowRight(
  WithOnboardingHandler,
  withFlashMessaging
)(SimulationEditForm);
