/**
 * @param {string} email
 * @returns {boolean}
 */
export const isBusinessEmail = (email) => {
  if (email && email.endsWith('@virti.com')) {
    return true;
  }
  var re =
    /^([\w.+-]+)@(?!(?:hotmail|gmail|yahoo|icloud|outlook|naver|live|proton)\.)(([a-zA-Z\d-]+\.)+[a-zA-Z]{2,})$/
  return re.test(email);
};