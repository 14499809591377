import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import StyledPage from '../../components/StyledPage';
import {
  READ_SIMULATION,
  EDIT_SIMULATION,
  SET_PUBLIC,
} from '../../queries/SimulationQueries';
import LoadingPageIndicator from '../../components/LoadingPageIndicator';
import PageLoadError from '../../components/PageLoadError';
import RedirectWithPrevState from '../../components/RedirectWithPrevState';
import { useFlashMessaging } from '../WithFlashMessaging';
import DeleteSimulationBtnInMenu from '../../components/buttons/DeleteSimulationBtnInMenu';
import WithSimulationPageRedirects from '../WithSimulationPageRedirects';
import useUser from '../../apps/core/src/helpers/useUser';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { TitlebarContainer } from '../../apps/core/src/components/Page/components/Titlebar/Titlebar.styled';
import { Header } from '../../apps/core/src/components/Page/components/PageHeader/PageHeader.styled';
import { ActionbarContainer } from '../../apps/core/src/components/Page/components/Actionbar/Actionbar.styled';
import SimulationTabNavCopy from '../../apps/core/src/components/Simulation/Header/SimulationTabNavCopy';
import EditableTitle from '../../components/button-modal-forms/EditableTitle';
import CopySimulationButton from '../../components/buttons/CopySimulationButton/CopySimulationButton';
import { Button } from '@virtidev/toolbox';
import SimShareForm from '@core/components/Simulation/SimShareForm/SimShareForm';

const WithSimulationPageTemplate = (
  WrappedComponent,
  pageKey = undefined,
  hasSaveBar = false,
  stretchFullHeight = false,
  renderBackBar = true
) => {
  const SimulationPageTemplate = (props) => {
    const { id: simID, videoID } = useParams();
    const { ID, OrganisationID } = useUser();
    const { addFlashMessage } = useFlashMessaging();
    const [showShare, setShowShare] = useState(false);

    const { loading, error, data } = useQuery(READ_SIMULATION, {
      variables: {
        ID: simID,
      },
      skip: !ID || !simID,
    });

    const simulation = useMemo(() => data?.readOneSimulation, [data]);

    // temporary, setting the simulation public for backwards compatibility with mobile apps
    const [setPublic] = useMutation(SET_PUBLIC, {
      variables: { simId: simulation?.ID },
    });

    const openShareModal = useCallback(() => {
      if (simulation && !simulation?.PublicAccess) {
        setPublic();
      }
      setShowShare(true);
    }, [setPublic, simulation]);

    const closeShareModel = useCallback(
      () => setShowShare(false),
      [setShowShare]
    );

    const [mutateEditSimulation] = useMutation(EDIT_SIMULATION);

    const handleUpdateTitle = useCallback(
      async (newTitle) => {
        try {
          await mutateEditSimulation({
            variables: {
              ID: simID,
              Input: {
                ID: simID,
                Title: newTitle,
              },
            },
          });

          addFlashMessage('Simulation name sucessfully updated');
        } catch (e) {
          addFlashMessage(
            'There was an error updating the simulation name',
            'error'
          );
        }
      },
      [simID, addFlashMessage, mutateEditSimulation]
    );

    if (!ID) {
      return <RedirectWithPrevState to="/login" />;
    }

    if (!data && loading) {
      return <LoadingPageIndicator />;
    }

    if (error) {
      return <PageLoadError graphQLErrorObj={error} />;
    }

    if (!simulation) {
      return <RedirectWithPrevState to="/simulations" />;
    }

    return (
      <StyledPage
        stretchFullHeight={stretchFullHeight}
        pageKey={pageKey}
        hasSaveBar={hasSaveBar}
        goBackToLinkText="all simulations"
        goBackToLink="/simulations"
        renderBackBar={renderBackBar}
      >
        <TitlebarContainer>
          <Header>
            <EditableTitle
              className="edit-title"
              title={
                data.readOneSimulation.Title
                  ? data.readOneSimulation.Title
                  : '(Unnamed)'
              }
              onSave={handleUpdateTitle}
              loading={props.loading}
              maxLength={50}
              editable
              editTitleMenuButtons={
                <>
                  <CopySimulationButton
                    organisationID={OrganisationID}
                    simulationID={simID}
                    title={data.readOneSimulation.Title}
                  />
                  <DeleteSimulationBtnInMenu
                    userOrganisationID={OrganisationID}
                    simulationID={simID}
                    simulationTitle={data.readOneSimulation.Title}
                  />
                </>
              }
            />
            <Button color="primary" onClick={openShareModal} icon="link">
              Share
            </Button>
          </Header>
        </TitlebarContainer>
        <ActionbarContainer>
          <SimulationTabNavCopy
            simulationID={simID}
            selectedVideoID={videoID}
            transcodingStatus={data.readOneSimulation.TranscodingStatus}
          />
        </ActionbarContainer>

        <WrappedComponent
          {...props}
          data={data}
          // for backward compatibility
          userOrganisationID={OrganisationID}
          userID={ID}
        />

        <SimShareForm
          show={showShare}
          onClose={closeShareModel}
          simulation={simulation}
        />
      </StyledPage>
    );
  };

  return SimulationPageTemplate;
};

export default _.flowRight(
  WithSimulationPageTemplate,
  WithSimulationPageRedirects
);
