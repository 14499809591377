import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import LoadingIndicator from '../../../../../../components/LoadingIndicator';
import * as Styled from './EnvironmentAvatarSelectors.styled';
import { getResizeImage } from '@core/helpers/imageResizing/getResizeImage';
import {
  READ_ENVIRONMENTS,
  READ_VIRTUAL_HUMAN_AVATARS,
} from './EnvironmentAvatarSelectors.query';
import TabbedItemSelector from './components/TabbedItemSelector/TabbedItemSelector';
import { Tab } from '../../Tabs';
import { useFormContext, useWatch } from 'react-hook-form';
import VoiceSelector from '../VoiceSelector/VoiceSelector';
import { useFeature } from '../../LaunchDarkly';
import VHImage from '@core/components/VirtualHumans/EnvironmentAvatarSelectors/components/VHImage/VHImage';
import { Card, Icon, TabHead, TabTitle } from '@virtidev/toolbox';
import {
  CardHeader,
  CardSection,
} from '@core/components/VirtualHumanForm/VirtualHumanForm.styled';

const parseSelectOptions = function (nodes) {
  return nodes.map((node) => ({
    label: node.DisplayName ?? node.Name ?? '',
    value: node.ID,
    imageURL: getResizeImage(
      node.AvatarMedia?.TusID ?? node.ImageMedia?.TusID,
      {
        resize: {
          width: 1200,
          height: 674,
        },
      }
    ),
  }));
};

const EnvironmentAvatarSelectors = ({ localeCode, voiceString, vhType }) => {
  const showAvatarEnvironmentsInPreview = useFeature(
    'show-avatar-environment-previews'
  );
  const showAvatarEnvironmentsInDevelopment = useFeature(
    'show-in-dev-avatars-environments'
  );
  const envFilterVars = {
    ...(!showAvatarEnvironmentsInPreview ? { IsPreview: { eq: false } } : {}),
    ...(!showAvatarEnvironmentsInDevelopment
      ? { IsDevelopment: { eq: false } }
      : {}),
  };
  const {
    data: environmentsData,
    loading: environmentsLoading,
    environmentsError,
  } = useQuery(READ_ENVIRONMENTS, {
    variables: { filter: envFilterVars },
  });
  const avatarFilterVars = {
    ...(!showAvatarEnvironmentsInPreview ? { IsPreview: { eq: false } } : {}),
    ...(!showAvatarEnvironmentsInDevelopment
      ? { IsDevelopment: { eq: false } }
      : {}),
  };
  const {
    data: avatarsData,
    loading: avatarsLoading,
    error: avatarsError,
  } = useQuery(READ_VIRTUAL_HUMAN_AVATARS, {
    variables: { filter: avatarFilterVars },
  });

  const {
    EnvironmentID: environmentID,
    AvatarID: avatarID,
    VHVoiceID: voiceID,
  } = useWatch();

  const methods = useFormContext();
  const { setValue } = methods;

  const selectedEnvironment = useMemo(() => {
    let selectedEnvironment = environmentsData?.readEnvironments?.nodes?.find(
      (env) => env.ID === environmentID
    );
    if (!environmentID || environmentID === '0') {
      selectedEnvironment = environmentsData?.readEnvironments?.nodes?.find(
        (env) => env.Name.indexOf('Office') === 0
      );
    }
    return selectedEnvironment;
  }, [environmentsData, environmentID]);

  const getCompatibleAvatars = useCallback(
    (environment) => {
      if (!environment?.Avatars) return [];
      const compatibleAvatarIDs = environment.Avatars.nodes.map(
        (avatar) => avatar.ID
      );
      return avatarsData?.readAvatars?.nodes?.filter((avatar) =>
        compatibleAvatarIDs.includes(avatar.ID)
      );
    },
    [avatarsData?.readAvatars?.nodes]
  );

  const compatibleAvatars = useMemo(() => {
    return getCompatibleAvatars(selectedEnvironment);
  }, [selectedEnvironment, getCompatibleAvatars]);

  const currentAvatar = useMemo(() => {
    return avatarsData?.readAvatars?.nodes?.find(
      (avatar) => avatarID === avatar.ID
    );
  }, [avatarID, avatarsData]);

  // // when avatar changes we should set the voice to the avatar's default voice
  // const prevAvatarId = usePrevious(avatarID);
  // useEffect(() => {
  //   if (currentAvatar?.DefaultVoiceID && prevAvatarId !== avatarID) {
  //     setValue('VHVoiceID', currentAvatar.DefaultVoiceID);
  //   }
  // }, [currentAvatar?.DefaultVoiceID, setValue, avatarID, prevAvatarId]);

  const queriesLoading = environmentsLoading || avatarsLoading;
  const dataAllPresentLoading =
    environmentsData?.readEnvironments?.nodes &&
    avatarsData?.readAvatars?.nodes;
  const anyError = environmentsError ?? avatarsError;
  const environmentsOptions = useMemo(
    () => parseSelectOptions(environmentsData?.readEnvironments?.nodes ?? []),
    [environmentsData?.readEnvironments?.nodes]
  );
  const avatarsOptions = useMemo(
    () => parseSelectOptions(compatibleAvatars ?? []),
    [compatibleAvatars]
  );

  const handleEnvironmentChange = useCallback(
    (newItemID) => {
      const newEnvironment = environmentsData?.readEnvironments.nodes.find(
        (newEnv) => newEnv.ID === newItemID
      );
      const newEnvCompatibleAvatars = getCompatibleAvatars(newEnvironment);
      // ensure that if environment changes and current avatar isn't compatible with new environment, set to first compatible avatar
      let updateAvatarID = undefined;
      if (
        newEnvironment &&
        !newEnvCompatibleAvatars.map((av) => av.ID).includes(avatarID)
      ) {
        updateAvatarID =
          newEnvCompatibleAvatars && newEnvCompatibleAvatars.length > 0
            ? newEnvCompatibleAvatars[0].ID
            : 0;
      }
      setValue('EnvironmentID', newItemID);
      if (updateAvatarID) {
        setValue('AvatarID', updateAvatarID);
      }
    },
    [
      avatarID,
      environmentsData?.readEnvironments.nodes,
      getCompatibleAvatars,
      setValue,
    ]
  );

  const handleAvatarChange = useCallback(
    (newItemID) => {
      setValue('AvatarID', newItemID);
    },
    [setValue]
  );

  const [selectedTabName, setSelectedTabName] = useState('environment');
  const handleTabChange = useCallback((tabName) => {
    setSelectedTabName(tabName);
  }, []);

  const handleVoiceChange = useCallback(
    (newItem) => {
      setValue('VHVoiceID', newItem.ID);
    },
    [setValue]
  );

  return (
    <Styled.AvatarSelector>
      {queriesLoading && <LoadingIndicator />}
      {anyError && <div>{anyError}</div>}
      {!queriesLoading && dataAllPresentLoading && !anyError && (
        <>
          <div>
            <CardSection>
              <CardHeader>Virtual Human</CardHeader>
              <VHImage
                avatarNodes={avatarsData?.readAvatars?.nodes ?? []}
                selectedAvatarID={avatarID}
                environmentNodes={
                  environmentsData?.readEnvironments?.nodes ?? []
                }
                selectedEnvironmentID={environmentID}
              />
            </CardSection>
          </div>
          <Card>
            <Styled.Tabs
              fill
              onChange={handleTabChange}
              selectedTabName={selectedTabName}
              tabsContentComponent={Styled.TabContentWrapper}
            >
              <Tab name="environment" title="Environment">
                <TabbedItemSelector
                  onItemChange={handleEnvironmentChange}
                  items={environmentsOptions ?? []}
                  selectedID={environmentID}
                />
              </Tab>
              <Tab name="avatar" title="Avatar">
                <TabbedItemSelector
                  onItemChange={handleAvatarChange}
                  items={avatarsOptions ?? []}
                  selectedID={avatarID}
                />
              </Tab>
              <Tab
                name="voice"
                headerRender={({ active, disabled, ...props }) => {
                  return (
                    <TabHead $active={active} $disabled={disabled} {...props}>
                      <Styled.TabHeaderContent>
                        <TabTitle>Voice</TabTitle>
                        <Icon
                          icon="help"
                          color="midnight"
                          id="voice-tab-help"
                        />
                      </Styled.TabHeaderContent>
                    </TabHead>
                  );
                }}
                title="Voice"
              >
                <Styled.VoiceSelectorWrapper>
                  <VoiceSelector
                    onChange={handleVoiceChange}
                    voiceID={voiceID}
                    voiceString={voiceString}
                    filterLocaleCode={localeCode}
                    gender={currentAvatar?.Gender}
                    vhType={vhType}
                  />
                </Styled.VoiceSelectorWrapper>
              </Tab>
            </Styled.Tabs>
          </Card>
        </>
      )}
    </Styled.AvatarSelector>
  );
};

EnvironmentAvatarSelectors.propTypes = {
  voiceString: PropTypes.string,
  localeCode: PropTypes.string.isRequired,
};

export default EnvironmentAvatarSelectors;
