import React from 'react';

const MedalIcon = () => {
  return (
    <svg
      width="21"
      height="29"
      viewBox="0 0 21 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="medal-icon-title"
      role="img"
    >
      <title id="medal-icon-title">Medal Icon</title>
      <rect
        x="5.3512"
        y="23.9189"
        width="4.45946"
        height="1.21622"
        fill="#E39CB8"
      />
      <rect
        x="11.0273"
        y="23.9189"
        width="4.45946"
        height="1.21622"
        fill="#E39CB8"
      />
      <rect
        x="5.3512"
        y="25.1351"
        width="3.44595"
        height="1.21622"
        fill="#E39CB8"
      />
      <rect
        x="12.0408"
        y="25.1351"
        width="3.44595"
        height="1.21622"
        fill="#E39CB8"
      />
      <rect
        x="5.3512"
        y="26.3513"
        width="2.22973"
        height="1.21622"
        fill="#E39CB8"
      />
      <rect
        x="13.257"
        y="26.3513"
        width="2.22973"
        height="1.21622"
        fill="#E39CB8"
      />
      <rect
        x="5.3512"
        y="27.5676"
        width="1.21622"
        height="1.21622"
        fill="#E39CB8"
      />
      <rect
        x="14.2699"
        y="27.5676"
        width="1.21622"
        height="1.21622"
        fill="#E39CB8"
      />
      <rect
        x="5.35107"
        y="19.8649"
        width="10.1351"
        height="4.05406"
        fill="#E39CB8"
      />
      <rect
        x="5.35107"
        y="19.8649"
        width="10.1351"
        height="1.21622"
        fill="#DD6B9C"
      />
      <rect
        x="7.78412"
        y="21.0811"
        width="6.56757"
        height="1.21622"
        transform="rotate(90 7.78412 21.0811)"
        fill="#DD6B9C"
      />
      <rect
        x="14.2699"
        y="21.0811"
        width="6.56757"
        height="1.21622"
        transform="rotate(90 14.2699 21.0811)"
        fill="#DD6B9C"
      />
      <rect
        x="4.33777"
        y="1.21616"
        width="12.3649"
        height="1.21622"
        fill="#F6DEE7"
      />
      <rect
        x="4.33777"
        y="17.4324"
        width="12.3649"
        height="1.21622"
        fill="#F6DEE7"
      />
      <rect
        x="3.12158"
        y="2.4324"
        width="14.7973"
        height="1.21622"
        fill="#F6DEE7"
      />
      <rect
        x="3.12158"
        y="16.2162"
        width="14.7973"
        height="1.21622"
        fill="#F6DEE7"
      />
      <rect
        x="1.9054"
        y="3.64862"
        width="17.2297"
        height="12.5676"
        fill="#F6DEE7"
      />
      <rect
        x="1.90533"
        y="2.43243"
        width="1.21622"
        height="1.21622"
        fill="#EBB3C9"
      />
      <rect
        x="0.688965"
        y="3.64865"
        width="1.21622"
        height="12.5676"
        fill="#EBB3C9"
      />
      <rect
        x="19.1351"
        y="3.64865"
        width="1.21622"
        height="12.5676"
        fill="#EBB3C9"
      />
      <rect
        x="17.919"
        y="2.43243"
        width="1.21622"
        height="1.21622"
        fill="#EBB3C9"
      />
      <rect
        x="17.919"
        y="16.2162"
        width="1.21622"
        height="1.21622"
        fill="#EBB3C9"
      />
      <rect
        x="1.90527"
        y="16.2162"
        width="1.21622"
        height="1.21622"
        fill="#EBB3C9"
      />
      <rect
        x="16.7028"
        y="17.4325"
        width="1.21622"
        height="1.21622"
        fill="#EBB3C9"
      />
      <rect
        x="3.12152"
        y="17.4325"
        width="1.21622"
        height="1.21622"
        fill="#EBB3C9"
      />
      <rect
        x="3.12152"
        y="1.21619"
        width="1.21622"
        height="1.21622"
        fill="#EBB3C9"
      />
      <rect
        x="16.7028"
        y="1.21619"
        width="1.21622"
        height="1.21622"
        fill="#EBB3C9"
      />
      <rect
        x="4.33789"
        y="-3.05176e-05"
        width="12.3649"
        height="1.21622"
        fill="#EBB3C9"
      />
      <rect
        x="1.90527"
        y="3.64865"
        width="1.21622"
        height="12.5676"
        fill="#DC8EAD"
      />
      <rect
        x="17.9189"
        y="3.64865"
        width="1.21622"
        height="12.5675"
        fill="#DC8EAD"
      />
      <rect
        x="3.12146"
        y="16.2162"
        width="1.21622"
        height="1.21622"
        fill="#DC8EAD"
      />
      <rect
        x="16.7025"
        y="16.2162"
        width="1.21622"
        height="1.21622"
        fill="#DC8EAD"
      />
      <rect
        x="16.7025"
        y="2.43243"
        width="1.21622"
        height="1.21622"
        fill="#DC8EAD"
      />
      <rect
        x="3.12164"
        y="2.43243"
        width="1.21622"
        height="1.21622"
        fill="#DC8EAD"
      />
      <rect
        x="4.33777"
        y="1.21622"
        width="12.3647"
        height="1.21622"
        fill="#DC8EAD"
      />
      <rect
        x="4.33777"
        y="17.4324"
        width="12.3649"
        height="1.21622"
        fill="#DC8EAD"
      />
      <rect
        x="4.33771"
        y="18.6487"
        width="12.3649"
        height="1.21622"
        fill="#EBB3C9"
      />
      <rect
        x="9.81067"
        y="4.66217"
        width="1.21622"
        height="1.21622"
        fill="#DD6B9C"
      />
      <rect
        x="6.36481"
        y="9.32431"
        width="1.21622"
        height="1.21622"
        fill="#DD6B9C"
      />
      <rect
        x="13.2571"
        y="9.32431"
        width="1.21622"
        height="1.21622"
        fill="#DD6B9C"
      />
      <rect
        x="5.35132"
        y="8.10812"
        width="1.21622"
        height="1.21622"
        fill="#DD6B9C"
      />
      <rect
        x="7.58154"
        y="12.5676"
        width="1.21622"
        height="1.21622"
        fill="#DD6B9C"
      />
      <rect
        x="8.59448"
        y="5.87839"
        width="3.64865"
        height="2.22973"
        fill="#DD6B9C"
      />
      <rect
        x="7.58154"
        y="9.32431"
        width="5.67568"
        height="3.44595"
        fill="#DD6B9C"
      />
      <rect
        x="12.0409"
        y="12.5676"
        width="1.21622"
        height="1.21622"
        fill="#DD6B9C"
      />
      <rect
        x="6.36481"
        y="8.10812"
        width="9.12163"
        height="1.21622"
        fill="#DD6B9C"
      />
      <rect
        x="13.2571"
        y="12.5676"
        width="1.21622"
        height="2.43244"
        fill="#DD6B9C"
      />
      <rect
        x="6.36481"
        y="12.5676"
        width="1.21622"
        height="2.43244"
        fill="#DD6B9C"
      />
    </svg>
  );
};

export default MedalIcon;
