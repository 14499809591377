import React, { useCallback, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useApolloClient, useMutation } from '@apollo/client';
import { LOGIN, SIGN_UP_USER } from '@base/queries/UserQueries';
import {
  StoreLogin,
  UpdateAIToken,
  UpdateToken,
} from '@base/utility/LoginUtility';
import { readOneMemberBasic } from '@base/query-functions/MemberQueryFunctions';
import {
  setSuccessfulUserSignup,
  setUserModeVar,
} from '@base/ApolloReactiveVars';
import StyledLink from '@base/styled-components/StyledLink';
import useUser from '@core/helpers/useUser';
import RegisterForm from '@core/components/Signup/RegisterForm/RegisterForm';
import { withSplashPageTemplate } from '@core/templates';

const NewMemberSignupPage = () => {
  const /** @type {{userType: string;}} */ { userType } = useParams();
  const { ID: userID } = useUser();

  const client = useApolloClient();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const [getToken] = useMutation(LOGIN);
  const [submittedUserInfo, setSubmittedUserInfo] = useState(
    /** @type {{email: string; password: string;}|null} */ (null)
  );
  const [error, setError] = useState(/** @type {string | null} */ (null));

  const handleLogin = useCallback(
    async (tokenData) => {
      if (!tokenData?.createVirtiToken?.Token) {
        setError('There was an issue logging in with the retrieved token');
        setLoading(false);
        return;
      }

      UpdateToken(tokenData.createVirtiToken.Token);
      if (
        tokenData.createVirtiToken.hasOwnProperty('PDToken') &&
        tokenData.createVirtiToken.PDToken
      ) {
        UpdateAIToken(tokenData.createVirtiToken.PDToken);
      }

      const userData = await readOneMemberBasic(
        client,
        tokenData.createVirtiToken.Member.ID
      );

      if (!userData) {
        setLoading(false);
        setError('There was an issue retrieving the created user');
        return;
      }

      StoreLogin(
        tokenData.createVirtiToken.Token,
        tokenData.createVirtiToken.PDToken,
        userData.data.readOneMember,
        client
      );
      setUserModeVar(userData.UserType === 'user' ? 'user' : 'admin');

      history.replace(userData.UserType === 'user' ? '/home' : '/');
    },
    [client, history]
  );

  const [signUpUser, { error: signupError }] = useMutation(SIGN_UP_USER, {
    onCompleted: async (signupData) => {
      setSuccessfulUserSignup(true);

      if (!signupData?.signupUser || !submittedUserInfo || signupError) {
        setError(signupError?.message ?? 'Unable to register account.');
        setLoading(false);
        return;
      }

      const { data: tokenData } = await getToken({
        variables: {
          Email: submittedUserInfo.email,
          Password: submittedUserInfo.password,
        },
      });

      if (!tokenData?.createVirtiToken) {
        setError('Unable to login with the new account.');
        setLoading(false);
        return;
      }

      handleLogin(tokenData);
    },
    onError: (error) => {
      setLoading(false);
      setError(error.message);
    },
  });

  const /** @type {{orgHash: string;}} */ { orgHash } = useParams();

  const handleSubmit = useCallback(
    /**
     * @param {import('@core/components/Signup/RegisterForm/helpers/register-form.types').RegisterFormInput} input
     */
    async (input) => {
      setError('');
      setLoading(true);
      setSubmittedUserInfo({ email: input.Email, password: input.Password });
      signUpUser({
        variables: {
          FirstName: input.FirstName,
          Surname: input.Surname,
          Email: input.Email,
          Password: input.Password,
          InviteCode: orgHash,
        },
      });
    },
    [orgHash, signUpUser]
  );

  if (
    userType !== 'admin' &&
    userType !== 'user' &&
    userType !== 'organisation'
  ) {
    return <Redirect to="/logout" />;
  }

  if (userID) {
    return (
      <div>
        Please <StyledLink to="/logout">sign out</StyledLink> before activating
        a new account.
      </div>
    );
  }

  return (
    <RegisterForm
      loading={isLoading}
      existingOrg={true}
      error={error}
      onSubmit={handleSubmit}
    />
  );
};

export default withSplashPageTemplate({ pageKey: 'link-signup' })(
  NewMemberSignupPage
);
