import { NavLink } from 'react-router-dom';
import styled, { css, DefaultTheme, keyframes } from 'styled-components';
import { itemContainerCss } from '../../Sidebar.styled';

export const SubSidebarLink = styled(NavLink)`
  ${itemContainerCss}
  padding: 12px 35px;

  .sidebar-contracted & {
    padding: 12px 35px;
    width: var(--sidebar-width-expanded);
  }

  .sidebar-hover.sidebar-contracted & {
    padding: 12px 35px;
  }
`;

const closeSidebar = keyframes`
  0% {
    left: var(--sidebar-width-expanded);
  }
  100% {
    left: var(--sidebar-width-contracted);
  }
`;

export const SubContent = styled.div`
  ${
    /** @param {{ $show?: boolean, $contracted?: boolean, theme: DefaultTheme }} p */
    ({ theme: { color }, $show }) => css`
      background: ${color.midnight};
      position: fixed;
      top: 0;
      height: 100vh;
      min-height: 100%;
      z-index: 2;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      width: 0;
      transition: width 300ms, border-left 300ms, left 300ms;
      border: 0 solid transparent;

      scrollbar-color: transparent transparent;
      scrollbar-width: none;

      .sidebar-hover &,
      &:hover {
        scrollbar-color: unset;
        scrollbar-width: unset;
        overflow-x: hidden;
      }

      left: var(--sidebar-width-expanded);

      .sidebar-contracted & {
        left: var(--sidebar-width-contracted);
        animation: ${closeSidebar} 300ms linear 1;
      }
      .sidebar-hover.sidebar-contracted & {
        left: var(--sidebar-width-expanded);
      }
      ${$show &&
      css`
        width: calc(var(--sidebar-width-expanded) - 70px);
        border-left: 1px solid ${color.midnight10};
      `}
    `
  }
`;

export const Spacer = styled.div`
  flex: 1;
  max-height: 180px;
`;
