import styled, { css } from 'styled-components';
import { Clickable } from 'reakit/Clickable';

export const Heading = styled.div`
  display: grid;
  grid-template-columns: 1fr 8rem 1rem;
  align-items: center;
  line-height: 1.3rem;
  gap: 1.5rem;
  padding-right: 0.7rem;
  ${({ theme }) => css`
    ${theme.font.label}
    color: ${theme.color.midnight5};
  `}
`;

export const ExpandClickable = styled(Clickable)`
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid var(--pd-secondary-color-light);
  padding: 1rem;
  margin-bottom: 0.5rem;
`;
export const ObjectiveCount = styled.strong`
  margin-left: auto;
  display: flex;
  gap: 1rem;
`;
export const Objective = styled.div`
  ${({
    $timesHit,
    $points,
    $scoreKey,
    $hintedToZeroPoints,
    theme: { color },
  }) => css`
    border-radius: 6px;
    padding: 0.6rem 1rem 0.6rem 0.6rem;
    justify-content: center;
    align-items: center;
    display: grid;
    grid-template-columns: 1.75rem 1fr 4rem;
    font-size: 0.9rem;
    background-color: ${color.blue50};
    ${$timesHit > 0 &&
    $points > 0 &&
    $scoreKey !== 'neutral' &&
    css`
      background-color: ${color.turquoise20};
    `}
    ${$timesHit > 0 &&
    $points < 0 &&
    $scoreKey !== 'neutral' &&
    css`
      background-color: ${color.pink20};
    `}
  ${$timesHit > 0 &&
    ($scoreKey === 'neutral' || $hintedToZeroPoints) &&
    css`
      background-color: ${color.blue50};
    `}
  `}
  ${({ theme: { color, font } }) => css`
    ${font.note}
    color: ${color.midnight10};
  `}
`;

export const ObjectiveTitle = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ObjectivePoints = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  row-gap: 0.2rem;
  ${({ theme: { color, font } }) => css`
    ${font.label}
    color: ${color.midnight10};
  `}
`;

export const ObjectiveList = styled.div`
  padding-top: 1rem;
  display: grid;
  row-gap: 0.5rem;
`;
export const Expandable = styled.div``;
export const ChevronWrapper = styled.div`
  transform: rotate(${({ $expanded }) => (!$expanded ? '90deg' : '-90deg')});
  display: flex;
  justify-content: center;
`;

export const ObjectiveGroupFeedbackWrapper = styled.div``;

export const ObjectiveGroupFeedbackText = styled.p`
  ${({ theme: { font, color } }) => css`
    ${font.note}
    color: ${color.midnight10};
  `}
`;

export const IconWrapper = styled.div`
  display: flex;
`;