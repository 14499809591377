import { useMutation } from '@apollo/client';
import { produce } from 'immer';
import { useState, useCallback, useRef, useMemo } from 'react';
import { Icon, Loading } from '@virtidev/toolbox';
import WithConfirmationBox from '../../../../../../../HOCs/WithConfirmationBox';
import useFlashMessage from '../../../FlashMessage';
import { useDebouncedSave } from '../../../form/hooks/useDebouncedSave';
import {
  ActionsContainer,
  DeleteAction,
  EditAction,
  ToggleIcon,
} from '../Module/Module.styled';
import { useUnitDragDrop } from './hooks/useUnitDragDrop';
import {
  DELETE_UNITS,
  MODULE_FRAGMENT,
  UPDATE_ASSESSMENT,
  UPDATE_PASSMARK,
} from './courseUnit.query';
import {
  AssessmentToggle,
  DragIcon,
  DropUnitArea,
  PassInput,
  TitleContainer,
  TitleContent,
  TitleText,
  UnitContainer,
  UnitContent,
} from './CourseUnit.styled';
import Tooltip from '../../../Tooltip/Tooltip';
import { Link } from 'react-router-dom';

export const CourseUnit = ({
  data,
  index,
  onSort,
  moduleId,
  units,
  confirm,
}) => {
  const { addFlashMessage } = useFlashMessage();
  const [opened, setOpened] = useState(false);
  const passMarkRef = useRef();
  const { ID, Simulation, VirtualHuman, Type, Assessment, PassMark } = data;

  const { Title } = useMemo(() => {
    if (Type === 'VirtualHuman') {
      return VirtualHuman;
    }
    return Simulation;
  }, [Type, VirtualHuman, Simulation]);

  const icon = useMemo(
    () => (Type === 'VirtualHuman' ? 'virtual-human' : 'video'),
    [Type]
  );

  const [deleteUnit] = useMutation(DELETE_UNITS);

  const [updateAssessment, { loading: updatingAssessment }] =
    useMutation(UPDATE_ASSESSMENT);

  const [updatePassMark, { loading: updatingPassMark }] =
    useMutation(UPDATE_PASSMARK);

  const {
    dragging,
    enabled,
    dragRef,
    dragViewRef,
    hover,
    above,
    type,
    dropRef,
  } = useUnitDragDrop({ data, index, onSort, moduleId, units });

  const toggleOpened = useCallback(
    () => !ID.includes('new') && setOpened(!opened),
    [setOpened, opened, ID]
  );

  const label = useMemo(
    () => (type === 'VirtualHuman' ? 'virtual human' : 'interactive video'),
    [type]
  );

  const handleDelete = useCallback(() => {
    confirm(
      async () => {
        await deleteUnit({
          variables: {
            ids: [ID],
          },
          update: (cache, result) => {
            const fragment = cache.readFragment({
              id: `Module:${moduleId}`,
              fragment: MODULE_FRAGMENT,
            });

            const newFragment = produce(fragment, (state) => {
              state.Units.edges = state.Units.edges.filter(
                ({ node }) => node.ID !== ID
              );
            });

            cache.writeFragment({
              id: `Module:${moduleId}`,
              data: newFragment,
              fragment: MODULE_FRAGMENT,
            });
          },
        });

        addFlashMessage(
          `The simulation '${Title}' has been removed from the module.'`
        );
      },
      null,
      `Are you sure you want to remove this simulation '${Title}'?`,
      'delete'
    );
  }, [deleteUnit, ID, moduleId, addFlashMessage, Title, confirm]);

  const handleAssessmentChange = useCallback(
    async (e) => {
      const checked = e.target.checked;

      updateAssessment({
        variables: {
          Input: {
            ID,
            Assessment: checked,
            PassMark: 0,
          },
        },
        optimisticResponse: {
          updateUnit: {
            ID,
            Assessment: checked,
            __typename: 'Unit',
          },
        },
      });
    },
    [ID, updateAssessment]
  );

  const handlePassMarkChange = useCallback(
    async (input) => {
      const newValue = Number.isNaN(Number(input))
        ? 0
        : Math.max(0, Math.min(100, Number(input)));

      if (Number(input) !== newValue) {
        passMarkRef.current.setValue(newValue);
      }
      await updatePassMark({
        variables: {
          Input: {
            ID,
            PassMark: newValue,
          },
        },
        optimisticResponse: {
          updateUnit: {
            ID,
            PassMark: newValue,
            __typename: 'Unit',
          },
        },
      });
    },
    [ID, updatePassMark]
  );

  const debounceProps = useDebouncedSave(PassMark, {
    onUpdate: handlePassMarkChange,
  });

  const link = useMemo(() => {
    if (!data) {
      return null;
    }
    return Type === 'VirtualHuman'
      ? `/virtual-humans/${data?.VirtualHuman?.ID}`
      : `/simulations/${data?.Simulation?.ID}`;
  }, [Type, data]);

  // need a ref to get around a cyclic dependency between handleChange and useDebounce
  passMarkRef.current = debounceProps;

  return (
    <UnitContainer ref={dropRef}>
      {hover && above && <DropUnitArea top>Move {label} here?</DropUnitArea>}
      <TitleContainer ref={dragViewRef} dragging={dragging}>
        <DragIcon
          ref={dragRef}
          icon={enabled ? 'menu' : 'clock'}
          type="outline"
          size="16px"
          color="#c3c3c3"
          dragging={dragging}
        />
        <TitleContent
          onClick={Type === 'Simulation' ? toggleOpened : undefined}
        >
          <Icon
            icon={icon}
            color="var(--secondary-color, #46beaf)"
            size="1rem"
          />
          <TitleText>{Title}</TitleText>
          {!ID.includes('new') && Type === 'Simulation' && (
            <ToggleIcon
              opened={opened}
              icon="chevron"
              type="outline"
              size="16px"
              color="#c3c3c3"
            />
          )}
        </TitleContent>
      </TitleContainer>
      {Type === 'Simulation' && (
        <UnitContent opened={opened}>
          <AssessmentToggle
            flipped
            checked={Assessment}
            onChange={handleAssessmentChange}
          >
            Score
          </AssessmentToggle>
          <Tooltip>
            The percentage of questions answered correctly to pass the unit
          </Tooltip>
          {(updatingPassMark || updatingAssessment) && <Loading />}
          <PassInput {...debounceProps} disabled={!Assessment} />
        </UnitContent>
      )}
      {!ID.includes('new') && (
        <ActionsContainer>
          <DeleteAction type="button" onClick={handleDelete}>
            <Icon icon="bin" type="outline" size="16px" />
          </DeleteAction>
          <EditAction as={Link} to={link}>
            <Icon icon="expand" size="16px" />
          </EditAction>
        </ActionsContainer>
      )}
      {hover && !above && <DropUnitArea>Move {label} here?</DropUnitArea>}
    </UnitContainer>
  );
};

export default WithConfirmationBox(CourseUnit);
