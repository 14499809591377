import StyledLink from '../../../../../../../styled-components/StyledLink';
import { GetFormattedTimeLong } from '../../../../../../../utility/TimeFormatting';
import StyledScorePercentage from '../../../../../../../styled-components/StyledScorePercentage';
import styled from 'styled-components';
import { HoveringTooltip } from '../../../../../../../components/HelpTooltip';
import { Datetime, Icon, Tooltip } from '@virtidev/toolbox';
import { Actions } from '../../Analytics.styled';
import ActionMenuButton from '../../../Users/Actions/ActionMenuButton';
import { useCallback } from 'react';
import PropTypes from 'prop-types';

const StyledSuccessWrapper = styled.div`
  position: relative;
  margin: auto;
  display: inline-block;
  &:hover .hovering-tooltip {
    display: block;
    width: auto;
    top: -0.5rem;
    transform: translateX(-44%) translateY(-100%);
  }
`;

const PlaceholderIcon = styled.div`
  width: 1rem;
  height: 1rem;
`;

export const LegacyVirtualHumanSubmissionItem = ({
  showPosition,
  position,
  score,
  nameColumn,
  link,
  name,
  medicalScores,
  vhID,
  ExternalID,
  onDelete,
  hasObjectives,
}) => {
  const handleDelete = useCallback(
    () => onDelete(score.ID),
    [onDelete, score.ID]
  );

  const completionEvent =
    score.AnalyticsEvents.edges.length > 0
      ? score.AnalyticsEvents.edges[0].node
      : null;
  const PDDiagnosisScore = completionEvent?.PDDiagnosisScore ?? null;
  const PDConversationScore = completionEvent?.PDConversationScore ?? null;
  const Diagnosis = completionEvent?.Diagnosis ?? null;
  const PDAssessmentID =
    score?.PDAssessmentID ?? completionEvent?.PDAssessmentID ?? null;
  const TimeSpent = completionEvent?.TimeSpent ?? null;

  return (
    <tr key={score.ID}>
      {showPosition && <td>{position}</td>}
      {nameColumn && (
        <td>
          <StyledLink to={link}>{name}</StyledLink>
        </td>
      )}
      <td title={score.Created}>
        <Datetime
          datetime={score.Created}
          noTimeDisplay
          long
          emptyDisplay="-"
        />
      </td>
      {/* workaround for old data that was in seconds */}
      <td align="center">
        {!TimeSpent
          ? '-'
          : GetFormattedTimeLong(
              TimeSpent < 1000 ? TimeSpent * 1000 : TimeSpent
            )}
      </td>
      {hasObjectives && (
        <td>
          <StyledScorePercentage
            align="center"
            score={PDConversationScore}
            showNA={PDConversationScore === -1 || !score.Finished}
            NA={PDConversationScore === -1 ? 'N/A' : '-'}
          >
            {PDConversationScore}
          </StyledScorePercentage>
        </td>
      )}
      {medicalScores && (
        <>
          <td>
            <StyledSuccessWrapper>
              {Diagnosis && (
                <HoveringTooltip className="hovering-tooltip">
                  {Diagnosis}
                </HoveringTooltip>
              )}
              {PDDiagnosisScore === -1 && 'N/A'}
              {PDDiagnosisScore !== -1 && !score.Finished && '-'}
              {score.Finished && PDDiagnosisScore === 100 && (
                <Icon icon="tick" color="var(--positive-color)" />
              )}
              {score.Finished && PDDiagnosisScore === 0 && (
                <Icon
                  style={{
                    opacity: Diagnosis ? '1' : '0.4',
                  }}
                  icon="cross"
                  color="var(--negative-color)"
                />
              )}
            </StyledSuccessWrapper>
          </td>
        </>
      )}
      <td>
        <Actions>
          {!!PDAssessmentID ? (
            <StyledLink
              to={`/virtual-humans/${vhID}/builder/patient/${ExternalID}/chat-logs/${PDAssessmentID}`}
            >
              <Icon icon="document" />
            </StyledLink>
          ) : (
            <PlaceholderIcon />
          )}
          <Tooltip type="menu" icon="dots" placement="bottom-end" autoClose>
            <ActionMenuButton icon="bin" onClick={handleDelete}>
              Delete
            </ActionMenuButton>
          </Tooltip>
        </Actions>
      </td>
    </tr>
  );
};

LegacyVirtualHumanSubmissionItem.propTypes = {
  score: PropTypes.shape({
    ID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    Score: PropTypes.number,
    Finished: PropTypes.bool,
    FirstScore: PropTypes.number,
    LatestScore: PropTypes.number,
    // should be an array but should only have one result (END_SIM)
    AnalyticsEvents: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            ID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            Type: PropTypes.string,
            TimeSpent: PropTypes.number,
            PDAssessmentID: PropTypes.string,
            PDDiagnosisScore: PropTypes.number,
            PDConversationScore: PropTypes.number,
            Diagnosis: PropTypes.string,
            Created: PropTypes.string,
            Member: PropTypes.shape({
              ID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
              Name: PropTypes.string,
            }),
            VirtualHuman: PropTypes.shape({
              ID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
              Name: PropTypes.string,
            }),
          }),
        })
      ),
    }),
  }),
  hasObjectives: PropTypes.bool,
};

export default LegacyVirtualHumanSubmissionItem;
