import { useFlashMessage } from '@core/components/FlashMessage';
import { sendPostRequest } from '@vh/services/PDService';
import { useCallback, useState } from 'react';

/**
 * @typedef {import('../../../../models/vh-server/vh-server-coaching-session-report.types').CoachingSessionReport} CoachingSessionReport
 * @typedef {import('../../../../models/vh-server/vh-server-coaching-session-report.types').CoachingSessionReportObjective} CoachingSessionReportObjective
 */

/**
 * @type {({onComplete, onError}: {
 *  onStarted?: () => void;
 *  onComplete: (report: CoachingSessionReport & {objectiveReports: CoachingSessionReportObjective[];}) => void;
 *  onError: (e: any) => void;
 * }) => {
 *  generateReport: (assessmentId: number, regenerate?: boolean) => void
 * }}
 */
export const useGenerateSessionReport = ({
  onComplete,
  onError,
  onStarted,
}) => {
  const { addFlashMessage } = useFlashMessage();
  const generateReport = useCallback(
    async (assessmentId, regenerate = false) => {
      if (onStarted) {
        onStarted();
      }
      try {
        const response = await sendPostRequest(
          `assessments/${assessmentId}/coaching-session-report`,
          {
            regenerate,
          }
        );
        if (response?.data?.data) {
          onComplete(response.data.data);
        } else {
          addFlashMessage('Failed to parse response', 'error');
        }
      } catch (e) {
        addFlashMessage('Failed to generate report', 'error');
        if (onError) {
          onError(e);
        }
      }
    },
    [onStarted, onComplete, addFlashMessage, onError]
  );
  return { generateReport };
};
