import styled, { css } from 'styled-components';
import UploadDragBoxProgressBase from '@base/components/upload/UploadDragBoxProgress';

export const ProgressWrapper = styled.div`
  ${({ theme: { spacing } }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 ${spacing.md};
    flex: 1;
    align-self: stretch;
    gap: ${spacing.sm};
  `}
`;

export const FileCount = styled.div``;

const getSize = (size) => {
  switch (size) {
    default: {
      return '100%;';
    }
  }
};

export const ProgressbarWrapper = styled.div`
  ${
    /** @param {{ $size?: string }} p */
    ({ $size }) => css`
      width: ${getSize($size)};
      height: ${getSize($size)};
      max-height: calc(100% - 8rem);
      flex: 1;
      margin: 0 auto 0.5em auto;
      .CircularProgressbar {
        max-height: 100%;
      }
    `
  }
`;

export const UploadDragBoxProgress = styled(UploadDragBoxProgressBase)`
  font-size: 0.8rem;
  color: var(--primary-font-color-light);

  p + p {
    margin-top: 0.5em;
  }
`;

export const Actions = styled.div`
  font-size: 0.8rem;
`;

export const Pause = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  color: var(--link-text-color);
  text-decoration: none;
  font-weight: bold;

  &:hover {
    color: var(--link-text-color-hover);
  }
`;

export const Cancel = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  color: var(--link-text-color);
  text-decoration: none;
  font-weight: bold;

  &:hover {
    color: var(--link-text-color-hover);
  }
`;
