import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { READ_SIMULATIONS_PAGINATED } from '../queries/SimulationQueries';
import withUser from '../HOCs/WithUser';
import StyledPage, {
  StyledPageContentInner,
  StyledTopArea,
} from '@base/components/StyledPage';
import RedirectWithPrevState from '@base/components/RedirectWithPrevState';
import { SimulationList } from '@base/components/SimulationItem';
import {
  StyledDataBlockHeader,
  StyledDataBlockHeaderText,
} from '@base/styled-components/StyledDataBlocks';
import StyledPageHeader from '@base/styled-components/StyledPageHeader';
import { CanViewDashboard, UserTypes } from '@base/utility/Permissions';
import WithOnboardingHandler from '@base/HOCs/WithOnboardingHandler';
import OnboardingProgress from '@base/components/OnboardingProgress';
import QuickActions from '@core/components/Dashboard/QuickActions/QuickActions';
import ContactSalesModal from '@core/components/Signup/ContactSalesModal/ContactSalesModal';
import TrialBanner from '@core/components/Signup/legacy/TrialBanner/TrialBanner';
import useUser from '@core/helpers/useUser';
import SimulationDataCard from '@core/components/SimulationsList/components/SimulationDataCard/SimulationDataCard';
import PendingContentCopyBanner from '@core/components/Banners/PendingContentCopyBanner/PendingContentCopyBanner';
import BannerContainer from '@core/components/Banners/BannerContainer/BannerContainer';
import DashboardUsagePanel from '@core/components/Dashboard/DashboardUsagePanel/DashboardUsagePanel';
import { StyledSectionHeader } from '@core/components/Dashboard/QuickActions/QuickActions.styled';
import { withPageTemplate } from '@core/templates';
import { Titlebar } from '@core/components/Page';
import { Content } from '@base/pages/DashboardPage.styled';

export const DashboardPage = (props) => {
  const canView = CanViewDashboard();
  const { Organisation, SignupMethod, UserType } = useUser();
  const [show, setShow] = useState(false);
  const skipQueries = !canView || !props.userID || !Organisation?.ID;

  const { data: simData } = useQuery(READ_SIMULATIONS_PAGINATED, {
    variables: {
      offset: 0,
      limit: 3,
      sort: { Created: 'DESC' },
      filter: { Organisation: { ID: { eq: Organisation?.ID } } },
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: skipQueries,
  });

  const simulations = useMemo(() => {
    const sims = simData?.readSimulations?.edges?.map(({ node }) => node);

    if (!sims) {
      return [];
    }

    return sims;
  }, [simData]);

  const trial = useMemo(() => {
    const method = SignupMethod || 'public';

    return Organisation?.Trial && method === 'public';
  }, [Organisation, SignupMethod]);

  if (!props.userID) {
    return <RedirectWithPrevState to="/login" />;
  }
  if (!canView) {
    return (
      <RedirectWithPrevState
        to={
          props.userType === UserTypes.USER
            ? '/home'
            : Organisation?.Trial
            ? '/home'
            : '/simulations'
        }
        state={{ justLoggedIn: true }}
      />
    );
  }
  return (
    <StyledPage pageKey="dashboard">
      <OnboardingProgress userID={props.userID} />

      <ContactSalesModal show={show} setShow={setShow} />
      <Content>
        <StyledPageHeader>Dashboard</StyledPageHeader>

        {trial && <TrialBanner />}
        {UserType === 'admin' && <PendingContentCopyBanner />}

        <QuickActions />

        <DashboardUsagePanel skip={skipQueries} />
        {!!simulations?.length && (
          <>
            <StyledSectionHeader>Latest Simulations Added</StyledSectionHeader>
            <SimulationList>
              {simulations.map((simulation) => (
                <SimulationDataCard
                  simulation={simulation}
                  key={simulation.ID}
                />
              ))}
            </SimulationList>
          </>
        )}
      </Content>
    </StyledPage>
  );
};

DashboardPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
  userOrganisationID: PropTypes.number,
  userOrganisationName: PropTypes.string,
};

export default _.flowRight(withUser, WithOnboardingHandler)(DashboardPage);
