import PropTypes from 'prop-types';
import LearnerDataCard from '@core/components/DataListCard/LearnerDataCard';

const MyCourseItem = (props) => {
  return (
    <LearnerDataCard
      to={`/my-courses/${props.data.ID}`}
      thumbnailUrl={props.data?.ImageMedia?.URL}
      title={props.data.Title}
      progress={props.data.MemberProgress}
    />
  );
};

MyCourseItem.propTypes = {
  data: PropTypes.shape({
    ID: PropTypes.string,
    Title: PropTypes.string,
    MemberProgress: PropTypes.number,
  }),
};

export default MyCourseItem;
