import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { useRef, useEffect, useCallback } from 'react';
import { Loading } from '@virtidev/toolbox';
import { useDebouncedSave } from '../../../form/hooks/useDebouncedSave';
import { ToggleIcon } from '../Module/Module.styled';
import { UPDATE_TITLE } from './moduleTitle.query';
import {
  DragIcon,
  Input,
  TitleContainer,
  TitleContent,
  TitleText,
} from './ModuleTitle.styled';

export const ModuleTitle = ({
  dragViewRef,
  dragRef,
  enabled,
  toggle,
  data,
  opened,
  editable,
  dragging,
}) => {
  const { Title, ID } = data;
  const inputRef = useRef();

  useEffect(() => {
    if (editable) {
      inputRef.current.focus();
    }
  }, [editable]);

  const [updateTitle, { loading: updating }] = useMutation(UPDATE_TITLE);

  const onUpdate = useCallback(
    async (newTitle) => {
      await updateTitle({
        variables: {
          Input: {
            ID,
            Title: newTitle,
          },
        },
        optimisticResponse: {
          updateModule: {
            ID,
            Title: newTitle,
            __typename: 'Module',
          },
        },
      });
    },
    [ID, updateTitle]
  );

  const debounceProps = useDebouncedSave(Title, { onUpdate });

  return (
    <TitleContainer ref={dragViewRef} dragging={dragging}>
      <DragIcon
        ref={dragRef}
        icon={enabled ? 'menu' : 'clock'}
        type="outline"
        size="16px"
        color="#fff"
        dragging={dragging}
      />
      <TitleContent onClick={editable ? null : toggle}>
        {editable ? (
          <Input ref={inputRef} {...debounceProps} type="text" />
        ) : (
          <TitleText>{Title}</TitleText>
        )}
        {updating && <Loading contrast />}
        {!ID.includes('new') && (
          <ToggleIcon
            onClick={editable ? toggle : null}
            opened={opened}
            icon="chevron"
            type="outline"
            size="16px"
            color="#fff"
          />
        )}
      </TitleContent>
    </TitleContainer>
  );
};

export default ModuleTitle;
