import styled, { css } from 'styled-components';

export const CenteredContent = styled.div`
  ${({ theme: { color } }) => css`
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.9rem;
  `}
`;

export const Value = styled.div`
  ${({ theme: { font, color } }) => css`
    ${font.h1}
    color: ${color.midnight5};
  `}
`;
export const Label = styled.div`
  ${({ theme }) => css`
    ${theme.font.smallnote}
    margin-top: -0.4rem
  `}
`;
export const Icon = styled.div`
  display: flex;
  justify-content: center;
`;
