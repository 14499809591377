import { gql } from '@apollo/client';

export const ADD_MEMBER_TO_GROUP = gql`
  mutation addMemberToGroup($GroupID: ID!, $MemberID: ID!) {
    addMemberToGroup(GroupID: $GroupID, MemberID: $MemberID) {
      ID
      Name
      UserType
      FirstName
      Surname
    }
  }
`;

export const READ_MEMBERS_SELECT = gql`
  query readSelectMembers($filter: String, $organisationID: ID, $limit: Int) {
    readMembers(
      limit: $limit
      filter: {
        Disabled: { eq: false }
        Organisations: { ID: { eq: $organisationID } }
      }
      Name: $filter
    ) {
      edges {
        node {
          ID
          Name
          OrganisationGroups {
            edges {
              node {
                ID
              }
            }
          }
        }
      }
    }
  }
`;
