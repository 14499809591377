import { forwardRef, useMemo } from 'react';
import useUser from './useUser';
import RedirectWithPrevState from '../../../../components/RedirectWithPrevState';
import NoAccessPage from '../pages/user/error/no-access';
import { useLocation, useParams } from 'react-router-dom';
import LoadingPage from '../pages/user/error/loading';
import {
  useCanEditVirtualHumans,
  useSimulationAuthor,
} from './permissions.hooks';

export const withLoggedInAccess = (Component) => {
  const LoggedInAccessCheck = forwardRef((props, ref) => {
    const user = useUser();

    if (!user?.ID) {
      return <RedirectWithPrevState to="/login" />;
    }

    return <Component ref={ref} {...props} />;
  });

  return LoggedInAccessCheck;
};

export const withCXAccess = (Component) => {
  const CXAccessCheck = forwardRef((props, ref) => {
    const { UserType } = useUser();

    if (UserType !== 'customeradmin') {
      return <NoAccessPage />;
    }

    return <Component ref={ref} {...props} />;
  });

  return withLoggedInAccess(CXAccessCheck);
};

export const withCourseAccess = (Component) => {
  const CourseAccessCheck = forwardRef((props, ref) => {
    const { UserType } = useUser();
    const access = ['admin', 'super', 'customeradmin'].includes(UserType);

    if (!access) {
      return <NoAccessPage />;
    }

    return <Component ref={ref} {...props} />;
  });

  return withLoggedInAccess(CourseAccessCheck);
};

export const withAnalyticsAccess = (Component) => {
  const AnalyticsAccessCheck = forwardRef((props, ref) => {
    const { UserType } = useUser();
    const access = [
      'admin',
      'super',
      'customeradmin',
      'contentcreator',
    ].includes(UserType);

    if (!access) {
      return <NoAccessPage />;
    }

    return <Component ref={ref} {...props} />;
  });

  return withLoggedInAccess(AnalyticsAccessCheck);
};

export const withSimulationAccess = (Component) => {
  const SimulationAccessCheck = forwardRef((props, ref) => {
    const { ID, UserType } = useUser();
    const { simulationId } = useParams();
    const typeAccess = ['admin', 'super', 'customeradmin'].includes(UserType);
    const { data: simulation, loading } = useSimulationAuthor(simulationId, {
      skip: !!typeAccess,
    });

    const access = useMemo(() => {
      if (typeAccess) {
        return true;
      }

      // can't check against the simulation, so let it pass
      if (!simulation || !simulationId) {
        return true;
      }

      const authorId = simulation?.Author?.ID;
      if (authorId && authorId === ID) {
        return true;
      }

      const editors =
        simulation?.Editors?.nodes?.map?.((editor) => editor.ID) ||
        simulation?.Editors?.edges?.map?.(({ node: editor }) => editor.ID);
      if (editors && editors.includes(ID)) {
        return true;
      }
    }, [simulation, simulationId, ID, typeAccess]);

    if (!access) {
      return <NoAccessPage />;
    }
    if (loading) {
      return <LoadingPage />;
    }

    return <Component ref={ref} {...props} />;
  });

  return withLoggedInAccess(SimulationAccessCheck);
};

export const withVirtualHumanAccess = (Component) => {
  const VHAccessCheck = forwardRef((props, ref) => {
    const { UserType } = useUser();
    const location = useLocation();

    const canEditVirtualHumans = useCanEditVirtualHumans();

    if (!canEditVirtualHumans && !location.pathname.includes('/my-logs/')) {
      return <NoAccessPage />;
    }

    return <Component ref={ref} {...props} />;
  });

  return withLoggedInAccess(VHAccessCheck);
};

export const withMediaAccess = (Component) => {
  const MediaAccessCheck = forwardRef((props, ref) => {
    const { UserType } = useUser();

    const access = [
      'admin',
      'super',
      'customeradmin',
      'contentcreator',
    ].includes(UserType);

    if (!access) {
      return <RedirectWithPrevState to="/" />;
    }
    return <Component ref={ref} {...props} />;
  });

  return withLoggedInAccess(MediaAccessCheck);
};

export const withFeedbackFormsAccess = (Component) => {
  const FeedbackFormsAccessCheck = forwardRef((props, ref) => {
    const { UserType } = useUser();

    const access = [
      'admin',
      'super',
      'customeradmin',
      'contentcreator',
    ].includes(UserType);

    if (!access) {
      return <RedirectWithPrevState to="/" />;
    }
    return <Component ref={ref} {...props} />;
  });

  return withLoggedInAccess(FeedbackFormsAccessCheck);
};
