import React, { FC, useCallback, useEffect } from 'react';
import WebPlayerWrapper from '@base/components/WebPlayerWrapper';
import { IsMobile } from '@base/utility/BrowserCheck';
import {
  Link,
  PrivacyField,
  RegisterFormContainer,
  RegisterFormTitle,
} from '@core/components/Signup/RegisterForm/RegisterForm.styled';
import RegisterInfo from '@core/components/Signup/RegisterInfo/RegisterInfo';
import { RegisterLoadingLogo } from '@core/components/Signup/RegisterLoading/RegisterLoading.styled';
import { Button, Icon, Loading } from '@virtidev/toolbox';
import {
  ButtonWrapper,
  RegisterDemoContainer,
  RegisterDemoLogo,
  RegisterDemoText,
  SkipButton,
  VideoWrapper,
} from '@core/components/Signup/RegisterDemo/RegisterDemo.styled';
import { useHistory } from 'react-router-dom';

/**
 * @type {FC<{
 *    signupType?: string | null,
 *    loading?: boolean,
 *    unitID: string,
 *    simID: string,
 *    error: string | null,
 *    trialLength?: number,
 *    existingOrg?: boolean,
 * }>}
 */
export const RegisterDemo = ({
  signupType,
  loading,
  unitID,
  simID,
  error,
  trialLength,
  existingOrg,
}) => {
  const history = useHistory();
  const simCompleted = useCallback(() => history.push('/'), [history]);

  const openDeepLink = useCallback(() => {
    window.location.href = 'https://virti.page.link/WtXr';
  }, []);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (signupType !== 'web' || error || !simID || !unitID) {
      simCompleted();
    }
    // Skip the welcome video for now, but don't bypass the supported device check
    if (simID && unitID && !IsMobile()) {
      simCompleted();
    }
  }, [signupType, loading, error, simID, unitID, simCompleted]);

  if (IsMobile()) {
    return (
      <>
        {!existingOrg && <RegisterInfo hideDetails hideTablet />}
        <RegisterFormContainer>
          <RegisterLoadingLogo src="/images/virti-dark.svg" />
          <RegisterFormTitle>Continue on a supported device</RegisterFormTitle>
          <p>It looks like you're on a mobile device.</p>
          <p>
            Visit <Link to="/">app.virti.com</Link> on a desktop browser for the
            full web experience (we recommend Chrome).
          </p>
          <p>Or continue on mobile with our app.</p>
          <PrivacyField />
          <Button color="secondary" onClick={openDeepLink}>
            Get the Virti app
          </Button>
        </RegisterFormContainer>
      </>
    );
  }

  return (
    <>
      {!existingOrg && (
        <RegisterInfo hideDetails hideTablet trialLength={trialLength} />
      )}
      <RegisterDemoContainer>
        <RegisterDemoLogo src="/images/virti-dark.svg" />
        {!error && (
          <RegisterDemoText>
            Almost done - play the video below to understand what we're about!
          </RegisterDemoText>
        )}
        <VideoWrapper>
          {loading && <Loading size="large" />}
          {simID && unitID && (
            <WebPlayerWrapper
              simulationID={simID}
              unitID={unitID}
              welcomeVideo
              trackerProperties={{
                flow: 'organisation_self_registration',
              }}
              onSimulationComplete={simCompleted}
            />
          )}
        </VideoWrapper>
        <ButtonWrapper>
          <SkipButton onClick={simCompleted}>
            <Icon icon="fastforward" /> &nbsp; Skip step
          </SkipButton>
        </ButtonWrapper>
      </RegisterDemoContainer>
    </>
  );
};

export default RegisterDemo;
