import React from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'tus-js-client';

import withFlashMessaging from '../../HOCs/WithFlashMessaging';
import UploadDragBox from './UploadDragBox';
import WithConfirmationBox from '../../HOCs/WithConfirmationBox';
import {
  IsAcceptedFile,
  GetAcceptedFiles,
  formatFilesize,
} from '../../utility/FileUtility';
import { setStateAsync } from '../../utility/ReactUtil';
import NavigationPromptManual from '../NavigationPromptManual';
import { withUser } from '../../apps/core/src/helpers/useUser';
import { withTusEndpoint } from '../../apps/core/src/components/Cx/OrgTUSEndpointForm/withTusEndpoint';

class UploadTUSHandler extends React.Component {
  constructor(props) {
    super(props);
    this.dragBoxRef = React.createRef();
    this.state = {
      bytesAccepted: 0,
      totalBytes: 0,
      files: null,
      filesUploadedProgress: 0,
      status: 'no file selected',
      uploadUrl: null,
      dragging: false,
      progress: 0,
      completed: false,
      key: 0,
      paused: false,
      uploading: false,
    };
  }

  componentWillUnmount() {
    // ensure upload is aborted if it is in process
    if (this.upload && this.state.uploading) {
      this.upload.abort(true);
    }
  }

  _getFileID(upload) {
    return upload.url.split('/').slice(-1)[0].split('+')[0];
  }

  _getFileType = (file) => file.type.split('/')[0];

  _upload = async (file) => {
    const endpoint = await this.props.getTusEndpoint(this.props.mediaType);
    // Create a new tus upload
    this.upload = new Upload(file, {
      endpoint,
      removeFingerprintOnSuccess: true, // (github note) a boolean indicating if the fingerprint in the storage will be removed when the upload is successfully completed. This value is false for not breaking the previous API contract, but we strongly suggest to set it to true to avoid cluttering the storage space. The effect is that if the same file is uploaded again, it will create an entirely new upload. Furthermore, this option will only change behaviour if resume is set to true.
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: file.name,
        filetype: file.type,
      },

      onError: (error) => {
        this.setState({
          status: `upload failed ${error}`,
        });
        if (this.props.onError) {
          this.props.onError();
        }
        if (this.props.onComplete) {
          this.props.onComplete();
        }
      },
      chunkSize: this.props.chunkSize,
      onProgress: (bytesAccepted, totalBytes) => {
        this.setState({
          totalBytes: totalBytes,
          bytesAccepted: bytesAccepted,
          progress: (bytesAccepted / totalBytes) * 100,
        });
      },
      onSuccess: async () => {
        await setStateAsync(
          {
            status: 'upload finished',
            uploadUrl: this.upload.url,
            completed: true,
            filesUploadedProgress: this.state.filesUploadedProgress + 1,
            uploading: false,
            progress: 100,
          },
          this
        );
        // run onSuccess after each file
        if (this.props.onSuccess) {
          this.props.onSuccess(this.upload, this._getFileID(this.upload));
        }
        if (
          this.state.files &&
          this.state.filesUploadedProgress < this.state.files.length
        ) {
          await this._upload(
            this.state.files[this.state.filesUploadedProgress]
          );
        }
        // run onComplete at end of all files
        if (
          this.state.files.length === this.state.filesUploadedProgress &&
          this.props.onComplete
        ) {
          this.props.onComplete();
        }
      },
    });
    // reimplement old functionality from tus v1 to resume from localstorage
    const previousUploads = await this.upload.findPreviousUploads();
    if (previousUploads.length > 0) {
      this.upload.resumeFromPreviousUpload(previousUploads[0]);
    }
    // Start the upload
    this.upload.start();
    if (this.props.onSending) {
      this.props.onSending();
    }
    this.setState({
      uploading: true,
      paused: false,
      completed: false,
    });
  };

  _cancelUpload = async () => {
    await this.upload.abort();
    this.setState({
      files: null,
      uploading: false,
      paused: false,
      progress: 0,
      completed: false,
    });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  _openFilePicker = () => {
    this.dragBoxRef.current._openFilePicker();
  };

  _pause = async () => {
    if (this.state.paused) {
      this.setState({
        paused: false,
      });
      this.upload.start();
    } else {
      this.setState({
        paused: true,
      });
      await this.upload.abort();
    }
  };

  _getUploadArrayOrFail = (files) => {
    if (files.length === 0) return;
    if (files.length > 1 && !this.props.multiple) {
      this.props.addFlashMessage(
        'Please select just one file to upload.',
        'warning'
      );
      return false;
    }
    const arrayedFiles = [...files];
    for (let key in arrayedFiles) {
      const validFile = IsAcceptedFile(
        arrayedFiles[key].type,
        GetAcceptedFiles(this.props.mediaType)
      );
      if (!validFile) {
        this.props.addFlashMessage(
          `Please upload a valid ${this.props.mediaType} file.`,
          'warning'
        );
        return false;
      }
      if (
        this.props.maxFileSize &&
        arrayedFiles[key].size > this.props.maxFileSize
      ) {
        const size = formatFilesize(this.props.maxFileSize);
        this.props.addFlashMessage(
          this.props.multiple
            ? `One or more of your files exceeded the maximum size of ${size}.`
            : `Your ${this.props.mediaType} exceeded the maximum size of ${size}.`,
          'warning'
        );
        return false;
      }
    }
    
    return arrayedFiles;
  };

  _startUpload = (files) => {
    const arrayedFiles = this._getUploadArrayOrFail(files);
    if (!arrayedFiles) return;
    this.setState(
      {
        filesUploadedProgress: 0,
        files: arrayedFiles,
      },
      () => {
        this._upload(files[0]);
      }
    );
  };

  _addToQueue = (files) => {
    const arrayedFiles = this._getUploadArrayOrFail(files);
    if (!arrayedFiles) return;
    this.setState({
      files: [...this.state.files, ...arrayedFiles],
    });
  };

  render() {
    return (
      <>
        {this.props.needsOwnNavigationPrompt && (
          <NavigationPromptManual
            when={this.state.uploading}
            message="You are still uploading files, are you sure you want to leave?"
          />
        )}
        <UploadDragBox
          ref={this.dragBoxRef}
          startUpload={this._startUpload}
          cancelUpload={this._cancelUpload}
          uploading={this.state.uploading}
          paused={this.state.paused}
          progress={this.state.progress}
          completed={this.state.completed}
          totalBytes={this.state.totalBytes}
          pause={this._pause}
          files={this.state.files}
          filesUploadedProgress={this.state.filesUploadedProgress}
          addToQueue={this._addToQueue}
          {...this.props}
        />
      </>
    );
  }
}

UploadTUSHandler.defaultProps = {
  loaderSize: 'large',
  multiple: true,
  visible: true,
  round: false,
  chunkSize: 1024 * 1024 * 50,
  dragHereText: 'Drag & Drop Here to Upload',
  recommendationsText:
    'Images should be no larger than 1280px x 720px. Aspect ratio should be 16:9.',
  card: false,
  needsOwnNavigationPrompt: false,
};

UploadTUSHandler.propTypes = {
  loaderSize: PropTypes.string,
  onSuccess: PropTypes.func,
  onSending: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
  onCancel: PropTypes.func,
  dragHereText: PropTypes.string,
  recommendationsText: PropTypes.string,
  name: PropTypes.string.isRequired,
  mediaType: PropTypes.string,
  iconStyles: PropTypes.object,
  className: PropTypes.string,
  visible: PropTypes.bool,
  round: PropTypes.bool,
  onRemoveAsset: PropTypes.func,
  confirm: PropTypes.func,
  addFlashMessage: PropTypes.func.isRequired,
  audioSrc: PropTypes.string,
  videoSrc: PropTypes.string,
  imageSrc: PropTypes.string,
  progressWidth: PropTypes.string,
  progressHeight: PropTypes.string,
  maxFileSize: PropTypes.number,
  card: PropTypes.bool,
  multiple: PropTypes.bool,
  transcodingStatus: PropTypes.string,
  needsOwnNavigationPrompt: PropTypes.bool.isRequired,
  aspectRatio: PropTypes.string,
};

export default withTusEndpoint(
  withUser(WithConfirmationBox(withFlashMessaging(UploadTUSHandler)))
);
