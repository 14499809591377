import React from 'react';
import PropTypes from 'prop-types';
import { formatFilesize } from '../../utility/FileUtility';
import { formatDistanceStrict } from 'date-fns';
import styled from 'styled-components';

const StyledDots = styled.span`
  display: block;
  overflow: hidden;
  width: 1em;

  span {
    animation: waitingDots 1s infinite;
  }
  @keyframes waitingDots {
    0%,
    100% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
  }

  // Stagger the animation on each dot
  span:nth-child(3) {
    animation-delay: 0s;
  }
  span:nth-child(2) {
    animation-delay: -0.2s;
  }
  span:nth-child(1) {
    animation-delay: -0.4s;
  }
`;

const waitingDots = (
  <StyledDots>
    <span>.</span>
    <span>.</span>
    <span>.</span>
  </StyledDots>
);

const UploadDragBoxProgress = (props) => {
  const uploadProgress = () => {
    if (!props.totalBytes) return null;
    return `${formatFilesize(
      props.totalBytes * props.progress
    )} / ${formatFilesize(props.totalBytes)}`;
  };
  const [ticks, setTicks] = React.useState([]);
  const [throttled, setThrottled] = React.useState({
    remainingTime: null,
    uploadProgress: uploadProgress(),
    uploadRate: null,
  });
  const [uiUpdated, setUiUpdated] = React.useState(Date.now());

  const waiting = ticks.length < 2;
  const timeSinceFirstTick = waiting
    ? null
    : ticks[ticks.length - 1].time - ticks[0].time;
  const progressSinceFirstTick = waiting
    ? null
    : ticks[ticks.length - 1].progress - ticks[0].progress;

  const remainingTime = () => {
    if (waiting) return null;
    const timeRemaining =
      ((1 - ticks[0].progress) / progressSinceFirstTick) * timeSinceFirstTick;

    try {
      return formatDistanceStrict(
        new Date(),
        new Date(Date.now() + timeRemaining)
      );
    } catch (e) {
      console.error(e);
      return '';
    }
  };

  const uploadRate = () => {
    if (waiting) return null;
    const bytesPerSecond =
      (progressSinceFirstTick * props.totalBytes * 1000) / timeSinceFirstTick;
    return `${formatFilesize(bytesPerSecond)}/s`;
  };

  React.useEffect(() => {
    const tick = () => {
      // Skip if filesize not known yet
      if (!props.totalBytes) return;
      // Skip if the progress hasn't changed
      if (ticks.length && ticks[ticks.length - 1].progress === props.progress)
        return;
      // Store the last 4 ticks
      setTicks(
        [...ticks, { progress: props.progress, time: Date.now() }].slice(-4)
      );
    };
    if (props.paused) {
      setTicks([]);
      updateUiNow();
    } else {
      tick();
    }
    // `ticks` can't be a dependency because it will always be a new object
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paused, props.progress]);

  // Update the UI values only (roughly) once every two seconds
  const updateUiNow = () => {
    setUiUpdated(Date.now());
    setThrottled({
      remainingTime: remainingTime(),
      uploadProgress: uploadProgress(),
      uploadRate: uploadRate(),
    });
  };
  const updateUi = () => {
    if (Date.now() - uiUpdated < 2000) return;
    updateUiNow();
  };
  React.useEffect(() => {
    updateUi();
    const interval = setInterval(updateUi, 500);
    return () => clearInterval(interval);
  });

  return (
    <div className={props.className}>
      {throttled.uploadProgress ? (
        <p>
          {throttled.uploadProgress}{' '}
          {throttled.uploadRate && !waiting ? `(${throttled.uploadRate})` : ''}
        </p>
      ) : (
        waitingDots
      )}

      {throttled.remainingTime && !waiting ? (
        <p>{throttled.remainingTime} remaining</p>
      ) : (
        <>{props.paused ? <p>paused</p> : waitingDots}</>
      )}
    </div>
  );
};

UploadDragBoxProgress.propTypes = {
  progress: PropTypes.number.isRequired,
  paused: PropTypes.bool.isRequired,
  totalBytes: PropTypes.number.isRequired,
}

export default UploadDragBoxProgress;
