import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { sendSSGraphQLRequest } from '../services/PDService';
import * as Sentry from '@sentry/react';

// use manual queries for now to not rely on external apollo api (so this can more easily be a microservice)
// still relying on externally set localstorage token, though, so a proper SSO needs implementing

export const startAnalyticsSession = createAsyncThunk(
  'silverstripeAPI/startAnalyticsSession',
  async (payload, { getState, dispatch }) => {
    if (window.location.search.includes('sentryMsg=true')) {
      Sentry.captureMessage('sentry message test', 'error');
    }
    // debug
    let variables = {
      version: process.env.VERSION,
      vhId: getState()?.virtualHuman?.data?.ID,
      userID: getState()?.user?.ID,
      assessmentId: getState()?.chat?.assessmentId,
    };
    if (Object.values(variables).some((variable) => !variable)) {
      Sentry.captureMessage(
        'variables not set so probable AnalyticsSubmission breakage: ' +
          JSON.stringify(variables),
        'error'
      );
    }
    // end debug

    let unitIDStr = ``;
    if (getState().virtualHuman?.unitID) {
      unitIDStr = `UnitID: "${getState().virtualHuman.unitID}"`;
    }
    const urlParams = new URLSearchParams(window.location.search);
    const external_user_id = urlParams.get('external_user_id');
    let externalUserIdString = '';
    if (external_user_id) {
      externalUserIdString = `ExternalUserID: "${external_user_id}"`;
    }
    const response = await sendSSGraphQLRequest(
      payload,
      `mutation {
        createAnalyticsSubmission(input: {
          AppPlatform: "Web",
          AppVersion: "${process.env.VERSION}"
          VirtualHumanID: "${getState().virtualHuman.data.ID}",
          MemberID: "${getState().user.ID}",
          PDAssessmentID: "${getState().chat.assessmentId}",
          ${unitIDStr}
          ${externalUserIdString}
        }) {
          ID
        }
      }`
    );
    if (response.data?.errors?.length > 0) {
      const err = new Error(
        'Failed to run createAnalyticsSubmission:' + response.data.errors[0]
      );
      Sentry.captureException(err);
      throw err;
    }
    return response.data;
  }
);

export const endAnalyticsSession = createAsyncThunk(
  'silverstripeAPI/endAnalyticsSession',
  async (payload, { getState, dispatch }) => {
    if (getState().chat.isPreviewPlayer) {
      return false;
    }

    const chat = getState().chat;
    const vh = getState().virtualHuman;
    const scoresStr = `
      ${chat.diagnosisGuess ? 'Diagnosis: "' + chat.diagnosisGuess + '", ' : ''}
      ${
        typeof chat.finalScores.diagnosisScore !== 'undefined'
          ? 'PDDiagnosisScore: ' + chat.finalScores.diagnosisScore + ', '
          : ''
      }
      ${
        typeof chat.finalScores.conversationScore !== 'undefined'
          ? 'PDConversationScore: ' + chat.finalScores.conversationScore + ', '
          : ''
      }
      ${
        typeof chat.finalScores.timeSpent !== 'undefined'
          ? 'TimeSpent: ' + chat.finalScores.timeSpent + ', '
          : ''
      }
      ${typeof vh.unitID !== 'undefined' ? 'UnitID: ' + vh.unitID + ', ' : ''}
    `;
    const response = await sendSSGraphQLRequest(
      getState().silverstripeAPI.endpoint,
      `mutation {
        createAnalyticsEvent(input: {
          Type: "END_SIM"
          VirtualHumanID: ${getState().virtualHuman.data.ID},
          Finished: true,
          PDAssessmentID: "${getState().chat.assessmentId}",
          MemberID: ${getState().user.ID},
          AnalyticsSubmissionID: ${
            getState().silverstripeAPI.analyticsSubmissionID
          }
          ${scoresStr}
        }) {
          ID
        }
      }`
    );
    if (response.data?.errors?.length > 0) {
      const err = new Error(
        'Failed to run createAnalyticsSubmission:' + response.data.errors[0]
      );
      Sentry.captureException(err);
      throw err;
    }
    return response;
  }
);

export const hitObjectiveAnalyticsEvent = createAsyncThunk(
  'silverstripeAPI/hitObjectiveAnalyticsEvent',
  async (objectiveId, { getState, dispatch }) => {
    // don't send analyticsEvents for preview sessions or if objective has been hit already
    if (
      getState().chat.isPreviewPlayer ||
      getState().objectives.hitObjectiveIds.includes(objectiveId)
    ) {
      return false;
    }

    const response = await sendSSGraphQLRequest(
      getState().silverstripeAPI.endpoint,
      `mutation {
        createAnalyticsEvent(input: {
          Type: "HIT_OBJECTIVE"
          VirtualHumanID: ${getState().virtualHuman.data.ID},
          PDAssessmentID: "${getState().chat.assessmentId}",
          MemberID: ${getState().user.ID},
          AnalyticsSubmissionID: ${
            getState().silverstripeAPI.analyticsSubmissionID
          },
          PDObjectiveID: ${objectiveId}
        }) {
          ID
        }
      }`
    );
    return response.data;
  }
);

const SilverstripeAPISlice = createSlice({
  name: 'silverstripeAPI',
  initialState: {
    completeStatus: 'idle',
    startStatus: 'idle',
    endpoint: 'https://eevee.virti.com/graphql',
    analyticsSubmissionID: null,
  },
  reducers: {
    setSSEndpoint: (state, action) => {
      state.endpoint = action.payload;
    },
  },
  extraReducers: {
    [endAnalyticsSession.pending]: (state, action) => {
      state.completeStatus = 'pending';
    },
    [endAnalyticsSession.fulfilled]: (state, action) => {
      state.completeStatus = 'idle';
    },
    [endAnalyticsSession.rejected]: (state, action) => {
      state.completeStatus = 'error';
    },
    [startAnalyticsSession.pending]: (state, action) => {
      state.startStatus = 'pending';
    },
    [startAnalyticsSession.fulfilled]: (state, action) => {
      state.startStatus = 'idle';
      state.analyticsSubmissionID =
        action.payload?.data?.createAnalyticsSubmission?.ID;
    },
    [startAnalyticsSession.rejected]: (state, action) => {
      state.startStatus = 'error';
    },
  },
});

// Destructure and export the plain action creators
export const { setSSEndpoint } = SilverstripeAPISlice.actions;

export const analyticsSubmissionIDSelector = (state) =>
  state.silverstripeAPI.analyticsSubmissionID;
export const startAnalyticsSessionStatusSelector = (state) =>
  state.silverstripeAPI.startStatus;
export const completeAnalyticsSessionStatusSelector = (state) =>
  state.silverstripeAPI.completeStatus;

export default SilverstripeAPISlice;
