import { gql } from '@apollo/client';

export const REMOVE_MEMBER_FROM_ORG = gql`
  mutation removeMemberFromOrganisation($MemberID: ID!, $OrganisationID: ID!) {
    removeMemberFromOrganisation(
      MemberID: $MemberID
      OrganisationID: $OrganisationID
    ) {
      ID
    }
  }
`;

export const MEMBER_ORGS_FRAGMENT = gql`
  fragment memberOrgs on Member {
    ID
    Organisations {
      nodes {
        ID
        Name
      }
    }
  }
`;
